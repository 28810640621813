import { React, useEffect, useState } from "react";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";
import { BsCheck2, BsPencil, BsX } from "react-icons/bs";
import { useForm } from "react-hook-form";
import fetcher from "../../../../utils/fetcher";
import { useParams } from "react-router-dom";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import { config } from "../../../../config";
import useStorage from "../../../../context/StorageContext";
import { useNotifications } from "../../../../context/NotificationsContext";

const PutOnHoldButton = ({
  setShowModalOnHold,
  children,
  onHold,
  setValue,
}) => {
  const handleClick = () => {
    if (onHold !== null) {
      setValue("onHoldDescription", null, { shouldDirty: true }); //reset reason on hold as well
      setValue("onHold", false, { shouldDirty: true });
    } else if (onHold === null || onHold === false) {
      setValue("onHold", true);
    }
    setShowModalOnHold(true);
  };
  return (
    <Button onClick={handleClick} variant={onHold ? "warning" : "primary"}>
      {children}
    </Button>
  );
};

const PutOnHoldForm = ({
  customerId,
  refetch,
  onHold,
  onHoldDescription,
  onHoldBy,
  client,
  setError,
}) => {
  const defaultValues = {
    id: customerId,
    onHold: onHold,
    onHoldDescription: onHoldDescription,
  };

  const { handleSubmit, reset, register, setValue } = useForm({
    defaultValues,
  });

  const [dateTime, setDateTime] = useState(onHold);

  useEffect(() => {
    if (onHold && onHold.includes("T")) {
      const [datePart, timePart] = onHold.split("T");
      if (datePart && timePart) {
        const [year, month, day] = datePart.split("-");
        const [time] = timePart.split(".");
        const displayDateTime = `${time} ${day}-${month}-${year}`;
        setDateTime(displayDateTime);
      }
    }
  }, [onHold]);

  const onSubmit = (data) => {
    fetcher({
      url: `${config.api.phoenix}/customers/${customerId}/onhold?onHold=${
        data.onHold === true ? true : false
      }&onHoldDescription=${data.onHoldDescription}`,
      method: "PUT",
      payload: { ...data },
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        setShowModalOnHold(false);
        refetch();
      })
      .catch((err) => {
        setError(err);
        setShowModalOnHold(false);
      });
  };

  const [showModalOnHold, setShowModalOnHold] = useState(false);

  const handleOnCancel = () => {
    reset({ onHold: onHold, onHoldDescription: onHoldDescription });
    setShowModalOnHold(false);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!!onHold ? (
        <div className="mt-3">
          <Alert variant="warning">
            <Alert.Heading>On hold</Alert.Heading>
            <p>{`This customer was put on hold by ${onHoldBy}`} </p>
            <p>{`on ${dateTime}`}</p>
            <p>
              <strong>Reason:</strong>{" "}
              <span className="font-monospace">
                {onHoldDescription === "null"
                  ? "not specified"
                  : `${onHoldDescription}`}
              </span>
            </p>
            <PutOnHoldButton
              onHold={onHold}
              setShowModalOnHold={setShowModalOnHold}
              setValue={setValue}
            >
              Reactivate customer
            </PutOnHoldButton>
          </Alert>
        </div>
      ) : (
        <PutOnHoldButton
          onHold={onHold}
          setShowModalOnHold={setShowModalOnHold}
          setValue={setValue}
        >
          Put on hold
        </PutOnHoldButton>
      )}
      <Modal show={showModalOnHold} onHide={() => setShowModalOnHold(false)}>
        <Modal.Header>
          <Modal.Title>Reason on hold</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!onHold ? (
            <span>Are you sure you want to reactivate the customer?</span>
          ) : (
            <>
              <span>
                {`Please describe the reason for changing the customer on hold
                status (optional)`}
              </span>

              <Form.Control
                {...register("onHoldDescription")}
                placeholder="Reason on hold"
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOnCancel}>
            <BsX className="btn-icon me-1" /> Cancel
          </Button>
          <Button
            variant="success"
            type="submit"
            onClick={handleSubmit(onSubmit)}
          >
            <BsCheck2 className="btn-icon me-1" /> Save changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Form>
  );
};

const CustomerDetailsStatus = ({
  onHold,
  onHoldDescription,
  onHoldBy,
  refetch,
  dataUrl,
  customer,
}) => {
  const { customerId } = useParams();
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [error, setError] = useState(null);

  const [editable, setEditable] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({ defaultValues: customer });

  const onSubmit = (data) => {
    fetcher({
      url: dataUrl,
      method: "PUT",
      payload: { ...data },
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        refetch();
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to edit customer",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Card body className="mb-3 bg-dark bg-opacity-10">
      <Card.Title>Status</Card.Title>
      <Card.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Check
            type="switch"
            {...register("active")}
            disabled={!editable}
            label="Active"
          />
          <Form.Check
            type="switch"
            {...register("isTestCustomer")}
            disabled={!editable}
            label="Is test customer"
          />
          {editable ? (
            <div className="text-end">
              <Button
                variant="secondary"
                type="button"
                onClick={() => {
                  reset({ ...customer });
                  setEditable(false);
                }}
              >
                <BsX className="btn-icon me-1" /> Cancel
              </Button>
              <Button variant="success" type="submit" disabled={!isDirty}>
                <BsCheck2 className="btn-icon me-1" /> Save changes
              </Button>
            </div>
          ) : (
            <div className="text-end">
              <Button
                type="button"
                variant="success"
                onClick={() => setEditable(true)}
              >
                <BsPencil className="btn-icon me-1" /> Edit
              </Button>
            </div>
          )}
        </Form>
      </Card.Body>

      <PutOnHoldForm
        customerId={customerId}
        refetch={refetch}
        onHold={onHold}
        onHoldDescription={onHoldDescription}
        onHoldBy={onHoldBy}
        client={client}
        setError={setError}
      />
    </Card>
  );
};

export default CustomerDetailsStatus;
