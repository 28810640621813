import { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { BsCopy } from "react-icons/bs";
import useFetch from "../../../../hooks/useFetch";
import { config } from "../../../../config";
import PaginationButtons from "../../../ui/PaginationButtons";
import fetcher from "../../../../utils/fetcher";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import WithLoaderAndError from "../../../ui/WithLoaderAndError";
import { useNotifications } from "../../../../context/NotificationsContext";

const CopyVariant = ({
  variant,
  programId,
  treatmentId,
  variantId,
  mailMomentId,
  client,
  parameters,
  segmentId,
}) => {
  const { setToasts } = useNotifications();
  const PAGE_SIZE = 30;
  const [page, setPage] = useState(1);
  const [showOpen, setShowOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [error, setError] = useState(null);

  const { data: participants, error: participantsError } = useFetch({
    url: `${
      config.api.phoenix
    }/variants/${variantId}/copytargets?extraInfo=${true}`,
  });

  const handleCopy = () => {
    const payload = {
      name: variant?.name,
      description: variant?.description,
      customerId: selectedCustomer?.id,
      programId: programId,
      treatmentId: treatmentId,
      active: true,
      mailMomentId: mailMomentId,
      segmentId: segmentId || null,
    };

    fetcher({
      url: `${config.api.phoenix}/variants`,
      method: "POST",
      payload: payload,
      customerId: selectedCustomer?.id,
      clientId: client?.id,
    })
      .then((res) => {
        const parameterPayload = Object.values(parameters).map((param) => ({
          name: param.name,
          value: param.parameterValue,
        }));
        const newVariantId = res?.data?.id; //update parameters accordingly for new variant
        fetcher({
          url: `${config.api.phoenix}/variants/${newVariantId}/parameters`,
          method: "PUT",
          payload: parameterPayload,
          customerId: selectedCustomer?.id,
          clientId: client?.id,
        })
          .then(() => {
            setToasts((currToasts) => [
              ...currToasts,
              {
                id: Date.now(),
                variant: "success",
                text: "Variant has been copied",
              },
            ]);
          })
          .catch((err) => {
            setToasts((currToasts) => [
              ...currToasts,
              {
                id: Date.now(),
                variant: "danger",
                text: "Variant has been copied but unable to copy the parameters",
              },
            ]); //variant created but unable to copy paramaters
          });
      })
      .catch((err) => {
        setError(err);
      });
    setSelectedCustomer({});
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to create Variant",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <div>
      <Button size="sm" variant="success" onClick={() => setShowOpen(true)}>
        <BsCopy className="me-1" /> Copy variant
      </Button>
      <Modal
        size="lg"
        show={showOpen}
        onHide={() => {
          setShowOpen(false);
          setSelectedCustomer({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Copy variant</Modal.Title>
        </Modal.Header>
        <WithLoaderAndError isLoading={!participants} error={participantsError}>
          <Modal.Body>
            <div style={{ maxHeight: "450px", overflowY: "auto" }}>
              <Table striped hover>
                <thead>
                  <tr className="sticky-top">
                    <th>Customer name</th>
                    <th>Customer code</th>
                  </tr>
                </thead>
                <tbody>
                  {participants?.map((item) => (
                    <tr
                      key={`parti-${item.customer.id}`}
                      onClick={() => setSelectedCustomer(item.customer)}
                      style={{ cursor: "pointer" }}
                    >
                      <td
                        style={
                          selectedCustomer.id === item.customer.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.customer.name}
                      </td>
                      <td
                        style={
                          selectedCustomer.id === item.customer.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.customer.code}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowOpen(false);
                setSelectedCustomer({});
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleCopy();
                setShowOpen(false);
              }}
              disabled={Object.keys(selectedCustomer).length === 0}
              variant="success"
            >
              Copy
            </Button>

            <PaginationButtons
              pageSize={PAGE_SIZE}
              page={page}
              setPage={setPage}
              isLoading={!participants}
            />
          </Modal.Footer>
        </WithLoaderAndError>
      </Modal>
    </div>
  );
};

export default CopyVariant;
