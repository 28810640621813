import React from "react";
import { Button } from "react-bootstrap";
import fetcher from "../../utils/fetcher";
import { config } from "../../config";
import { BsStarFill } from "react-icons/bs";
import useStorage from "../../context/StorageContext";
import { useUserContext } from "../../context/UserContext";

const FavoriteButton = ({
  viewId,
  variant = "link",
  size = "",
  className = "",
}) => {
  const { userSettings, mutateUserSettings, isFavorite } = useUserContext();
  const { client } = useStorage();

  const callFavorite = ({ viewId, method = "post" }) =>
    fetcher({
      url: `${config.api.reporting}/usersettings/${viewId}/favoriteviews`,
      method: method,
      clientId: client?.id,
    })
      .then((res) => mutateUserSettings())
      .catch((err) => console.error(err));

  const addFavorite = (viewId) => callFavorite({ viewId: viewId });
  const removeFavorite = (viewId) =>
    callFavorite({ viewId: viewId, method: "delete" });

  return !!isFavorite({ viewId }) ? (
    <Button
      variant={variant}
      size={size}
      className={`p-0 ${className} link-warning`}
      onClick={() => removeFavorite(viewId)}
      title="Remove favorite"
      disabled={!userSettings}
    >
      <BsStarFill />
    </Button>
  ) : (
    <Button
      variant={variant}
      size={size}
      className={`p-0 ${className} link-secondary`}
      onClick={() => addFavorite(viewId)}
      title="Add favorite"
      disabled={!userSettings}
    >
      <BsStarFill />
    </Button>
  );
};

export default FavoriteButton;
