import React from "react";
import { Button, Container } from "react-bootstrap";
import { BsBoxArrowInLeft, BsBoxArrowRight } from "react-icons/bs";
import { useAuthContext } from "../../context/AuthContext";
import PhoenixLogo from "../icons/PhoenixLogo";
import PageHelmet from "../layout/PageHelmet";

const Login = () => {
  const { login, logout } = useAuthContext();

  return (
    <Container>
      <PageHelmet pageTitle="Login" />
      <div className="v-center">
        <div className="text-center">
          <div className="mb-3 p-3 fs-4 text-dark">
            <PhoenixLogo
              variant={null}
              layout="stacked"
              logoStyle="in-circle"
            />
          </div>{" "}
          <Button
            variant="px-dark"
            className="min-w-10 d-inline-flex justify-content-between align-items-center "
            onClick={sessionStorage?.loggedIn ? logout : login}
            data-testid="login-btn"
          >
            {sessionStorage?.loggedIn ? (
              <>
                <span
                  className="d-inline-block"
                  style={{ width: "1em" }}
                ></span>
                Logout <BsBoxArrowRight className="ms-1 opacity-75" />
              </>
            ) : (
              <>
                <span
                  className="d-inline-block"
                  style={{ width: "1em" }}
                ></span>
                Login <BsBoxArrowInLeft className="ms-1 opacity-75" />
              </>
            )}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Login;
