import { Button, Modal } from "react-bootstrap";
import useTreatments from "../../../hooks/useTreatments";
import ClientListGroup from "../Clients/ClientListGroup";

const CopyTreatmentToClient = ({ editable, treatment }) => {
  const {
    show,
    setShow,
    selectedClient,
    setSelectedClient,
    handleCopyToClient,
  } = useTreatments({
    heading: "Failed to copy treatment",
    treatment: treatment,
  });

  return (
    <>
      <Button
        variant="success"
        size="sm"
        disabled={editable}
        onClick={() => setShow(true)}
      >
        Copy to client
      </Button>
      <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Select a client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ClientListGroup
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button
            disabled={selectedClient === null}
            variant="success"
            onClick={handleCopyToClient}
          >
            Copy
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CopyTreatmentToClient;
