import { Button, Modal } from "react-bootstrap";
import useTreatments from "../../../hooks/useTreatments";

const CopyTreatment = ({ treatment, editable }) => {
  const { show, setShow, handleCopy } = useTreatments({
    heading: "Failed to copy treatment",
    treatment: treatment,
  });
  return (
    <>
      <Button
        size="sm"
        disabled={editable}
        onClick={() => setShow(true)}
        variant="success"
      >
        Copy treatment
      </Button>
      <Modal show={show} onHide={() => setShow(false)} size="lg" centered>
        <Modal.Header>
          <Modal.Title>Copy treatment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            <span>
              This action will make a copy of this treatment on the same client.
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleCopy}>
            Copy document
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CopyTreatment;
