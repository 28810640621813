import React from "react";
import { ListGroup } from "react-bootstrap";
import TreatmentParameterListItem from "../Treatments/TreatmentParameterListItem";

const mockParams = [
  {
    id: 274734,
    name: "own_headline",
    valueType: "string",
    sequence: 0,
    displayName: "own_headline",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "All the Links",
        isDefault: true,
        displayName: "All the Links",
      },
    ],
  },
  {
    id: 274735,
    name: "SenderEmail",
    valueType: "string",
    sequence: 0,
    displayName: "SenderEmail",
    description: "",
    editableByCustomer: false,
    inheritance: {
      id: 10069,
      name: "SenderEmail(Program)",
      description: null,
    },
    active: true,
    valueOptions: [],
  },
  {
    id: 274736,
    name: "SenderName",
    valueType: "string",
    sequence: 0,
    displayName: "SenderName",
    description: "",
    editableByCustomer: false,
    inheritance: {
      id: 10070,
      name: "SenderName(Program)",
      description: null,
    },
    active: true,
    valueOptions: [],
  },
  {
    id: 274737,
    name: "String01",
    valueType: "string",
    sequence: 0,
    displayName: "String01",
    description: "",
    editableByCustomer: false,
    inheritance: {
      id: 10019,
      name: "Customer_URL",
      description: null,
    },
    active: true,
    valueOptions: [],
  },
  {
    id: 274738,
    name: "String02",
    valueType: "string",
    sequence: 0,
    displayName: "String02",
    description: "",
    editableByCustomer: false,
    inheritance: {
      id: 10086,
      name: "twitter_url (Program)",
      description: null,
    },
    active: true,
    valueOptions: [],
  },
  {
    id: 274739,
    name: "String03",
    valueType: "string",
    sequence: 0,
    displayName: "String03",
    description: "",
    editableByCustomer: false,
    inheritance: {
      id: 10094,
      name: "Youtube_url (Program)",
      description: null,
    },
    active: true,
    valueOptions: [],
  },
  {
    id: 274740,
    name: "String04",
    valueType: "string",
    sequence: 0,
    displayName: "String04",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "https://google.com",
        isDefault: true,
        displayName: "https://google.com",
      },
    ],
  },
  {
    id: 274741,
    name: "String05",
    valueType: "string",
    sequence: 0,
    displayName: "String05",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "https://wikipedia.org",
        isDefault: true,
        displayName: "https://wikipedia.org",
      },
    ],
  },
  {
    id: 274742,
    name: "String06",
    valueType: "string",
    sequence: 0,
    displayName: "String06",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "https://loyaltylab.nl",
        isDefault: true,
        displayName: "https://loyaltylab.nl",
      },
    ],
  },
  {
    id: 274743,
    name: "String07",
    valueType: "xnip",
    sequence: 0,
    displayName: "String07",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "https://volvo.com",
        isDefault: true,
        displayName: "https://volvo.com",
      },
    ],
  },
  {
    id: 274744,
    name: "String08",
    valueType: "string",
    sequence: 0,
    displayName: "String08",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "https://github.com",
        isDefault: true,
        displayName: "https://github.com",
      },
    ],
  },
  {
    id: 274745,
    name: "String09",
    valueType: "string",
    sequence: 0,
    displayName: "String09",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "https://agenda.gay",
        isDefault: true,
        displayName: "https://agenda.gay",
      },
    ],
  },
  {
    id: 274746,
    name: "String10",
    valueType: "string",
    sequence: 0,
    displayName: "String10",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "https://amazon.nl",
        isDefault: true,
        displayName: "https://amazon.nl",
      },
    ],
  },
  {
    id: 274747,
    name: "String11",
    valueType: "string",
    sequence: 0,
    displayName: "String11",
    description: "",
    editableByCustomer: false,
    inheritance: {
      id: 10228,
      name: "Customer_Email(Program)",
      description: null,
    },
    active: true,
    valueOptions: [],
  },
  {
    id: 274748,
    name: "String12",
    valueType: "string",
    sequence: 0,
    displayName: "String12",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "wietse.krijne@loyaltylab.nl",
        isDefault: true,
        displayName: "wietse.krijne@loyaltylab.nl",
      },
    ],
  },
  {
    id: 274749,
    name: "String13",
    valueType: "string",
    sequence: 0,
    displayName: "String13",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "rutger.schaafsma@loyaltylab.nl",
        isDefault: true,
        displayName: "rutger.schaafsma@loyaltylab.nl",
      },
    ],
  },
  {
    id: 274750,
    name: "String14",
    valueType: "string",
    sequence: 0,
    displayName: "String14",
    description: "",
    editableByCustomer: false,
    inheritance: {
      id: 10047,
      name: "Customer_Telephone (Program)",
      description: null,
    },
    active: true,
    valueOptions: [],
  },
  {
    id: 274751,
    name: "String15",
    valueType: "string",
    sequence: 0,
    displayName: "String15",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "020-799 5046",
        isDefault: true,
        displayName: "020-799 5046",
      },
    ],
  },
  {
    id: 274752,
    name: "String16",
    valueType: "string",
    sequence: 0,
    displayName: "String16",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
    valueOptions: [
      {
        sequence: 0,
        value: "00000000",
        isDefault: true,
        displayName: "00000000",
      },
    ],
  },
  {
    id: 274753,
    name: "TestImage",
    valueType: "image",
    sequence: 0,
    displayName: "TestImage",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: true,
  },
  {
    id: 274754,
    name: "TestBool",
    valueType: "bit",
    sequence: 0,
    displayName: "TestBool",
    description: "",
    editableByCustomer: false,
    inheritance: null,
    active: false,
  },
  {
    id: 274755,
    name: "TestBool",
    valueType: "unknown",
    sequence: 0,
    displayName: "TestBool",
    description: "This is a test description",
    editableByCustomer: false,
    inheritance: null,
    active: true,
  },
];
const ExampleParameters = () => {
  return (
    <ListGroup>
      {mockParams.map((p) => (
        <TreatmentParameterListItem key={p.id} {...p} />
      ))}
    </ListGroup>
  );
};

export default ExampleParameters;
