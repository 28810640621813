import useTreatments from "../../../hooks/useTreatments";
import BtnWithDialog from "../../ui/BtnWithDialog";

const DeleteTreatment = ({ editable, treamtentName }) => {
  const { onDelete } = useTreatments({
    heading: "Failed to delete treatment",
    treatmentName: treamtentName,
  });

  return (
    <BtnWithDialog
      isDisabled={!editable}
      modalSize="md"
      title={""}
      text={
        <div>
          Delete treatment <strong>{`${treamtentName}`}</strong>?
        </div>
      }
      smallText={
        <div>{"This action will remove this treatment. Are you sure?"}</div>
      }
      onConfirm={onDelete}
    />
  );
};

export default DeleteTreatment;
