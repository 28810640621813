import React, { useEffect } from "react";
import { useLayout } from "../../../../context/LayoutContext";
import { config } from "../../../../config";
import OutletRow from "../../../layout/OutletRow";
import { Button, Card, Col, ListGroup, Row, Stack } from "react-bootstrap";
import WithLoaderAndError from "../../../ui/WithLoaderAndError";
import CountButton from "../../../ui/CountButton";
import { BsFunnelFill, BsX } from "react-icons/bs";
import SearchBox from "../../../ui/SearchBox";
import useListPage from "../../../../hooks/useListPage";
import useFetch from "../../../../hooks/useFetch";
import useTotals from "../../../../hooks/useTotals";
import NoResults from "../../../ui/NoResults";
import BundleListItem from "./BundleListItem";

const Bundles = () => {
  const [state, setValue] = useListPage();
  const { showSidebar, setShowSidebar } = useLayout(true);
  const itemLabel = "bundle";

  useEffect(() => {
    if (!state?.page) {
      setValue({
        page: 1,
        pageSize: 30,
        productionType: "",
        productionStatus: "",
        productionIds: [],
        name: "",
      });
    }
  }, [state?.page, setValue]);

  const { page, pageSize, name } = state;
  const setName = (val) => setValue({ name: val, page: 1 });

  const {
    data: items,
    headers,
    error,
  } = useFetch({
    url: `${config.api.phoenix}/productionbundles/search`,
    method: "GET",
    params: {
      ...state,
    },
  });

  const { total, isExact } = useTotals({
    linkStr: headers?.link,
    page,
    pageSize,
    itemsCount: items?.length,
  });

  return (
    <OutletRow filterCol={<Card>filters</Card>}>
      <Card className="border-o card-main">
        <Card.Header>
          <Stack direction="horizontal">
            <CountButton
              // count: number of active filter
              // onClear: function to unset active filters
              onClick={() => setShowSidebar((curr) => !curr)}
              active={showSidebar}
            >
              <BsFunnelFill />
            </CountButton>
            <div className="flex-grow-1"></div>
            <SearchBox
              query={state?.name ?? ""}
              setQuery={setName}
              size="sm"
              className="w-auto"
              placeholder="Search by name"
            />
          </Stack>
        </Card.Header>
        <Card.Body className="d-flex">
          <WithLoaderAndError
            isLoading={(!items && !error) || !page}
            error={error}
          >
            <ListGroup variant="flush" className="h-100 w-100">
              {items?.length ? (
                items?.map(({ id, name }) => (
                  <BundleListItem key={id} id={id} name={name} />
                ))
              ) : name ? (
                <NoResults>
                  <div className="text-muted mt-2">
                    <div className="mb-3">
                      No {itemLabel}s match{" "}
                      <span className="text-teal font-monospace">"{name}"</span>
                    </div>
                    {name ? (
                      <Button
                        variant="outline-primary"
                        onClick={() => setName("")}
                      >
                        <BsX /> Clear search
                      </Button>
                    ) : null}
                  </div>
                </NoResults>
              ) : (
                <NoResults>
                  <div className="text-muted mt-2">
                    <div className="mb-3">No {itemLabel}s found</div>
                  </div>
                </NoResults>
              )}
            </ListGroup>
          </WithLoaderAndError>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col className="text-end">
              {total ? (
                <div className="small">
                  {total}
                  {isExact ? "" : "+"} result{total % 10 === 1 ? "" : "s"}
                </div>
              ) : null}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </OutletRow>
  );
};

export default Bundles;
