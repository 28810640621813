import { Dropdown, Form } from "react-bootstrap";
import { BsCaretDown } from "react-icons/bs";

const TreatmentFilters = ({
  options,
  active,
  setActive,
  showDropdown,
  setShowDropdown,
  treatmentType,
  setTreatmentType,
}) => {
  return (
    <div className="w-100 mb-3">
      <div className="d-flex justify-content-between">
        <Form.Check
          type="checkbox"
          label="All"
          checked={active === ""}
          onChange={() => setActive("")}
        />
        <Form.Check
          type="checkbox"
          label="Active"
          checked={active === true}
          onChange={() => setActive(true)}
        />
        <Form.Check
          type="checkbox"
          label="Inactive"
          checked={active === false}
          onChange={() => setActive(false)}
        />
      </div>
      <hr />
      <div className="w-100 ">
        <div className="mb-1 d-flex justify-content-center">
          Treatment type:
        </div>
        <div className="d-flex justify-content-center">
          <Dropdown
            show={showDropdown}
            onToggle={() => setShowDropdown(!showDropdown)}
          >
            <Dropdown.Toggle
              className="d-flex align-items-center gap-2"
              variant="flush"
              style={{
                border: "solid lightgrey 1px",
                width: 200,
              }}
            >
              <div className="d-flex align-items-center justify-content-between w-100 gap-3">
                <div></div>
                {treatmentType === "" ? "No type selected" : treatmentType}{" "}
                <BsCaretDown />
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="w-100">
              <Dropdown.Item
                className="opacity-75"
                style={{ color: "black" }}
                onClick={() => setTreatmentType("")}
              >
                none
              </Dropdown.Item>
              {options.map((option, index) => (
                <Dropdown.Item
                  className="opacity-75"
                  style={{ color: "black" }}
                  key={`item-${index}`}
                  onClick={() => setTreatmentType(option)}
                >
                  {option}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
export default TreatmentFilters;
