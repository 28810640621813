import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../../../config";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { VALIDATIONPATTERNS } from "../../../../constants";
import fetcher from "../../../../utils/fetcher";
import { useForm } from "react-hook-form";
import {
  BsCaretDown,
  BsCheck2,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsPencil,
  BsPlus,
  BsTrash,
  BsTwitterX,
  BsX,
  BsYoutube,
} from "react-icons/bs";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import useStorage from "../../../../context/StorageContext";
import { useNotifications } from "../../../../context/NotificationsContext";

const CustomerMarketingProfileSocialMedia = ({
  socialmedia,
  mpId,
  refreshMp,
}) => {
  const { customerId } = useParams();
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [error, setError] = useState(null);

  const TOTAL = 9;
  const OTHER = "Other";

  const STATIC_LABELS = [
    "Facebook",
    "Twitter",
    "GooglePlus",
    "LinkedIn",
    "YouTube",
    "Hyves",
    "Instagram",
  ];

  const CUSTOM_TYPES = [
    ...STATIC_LABELS.filter(
      (item) => item !== "Hyves" && item !== "GooglePlus"
    ),
    OTHER,
  ];

  const staticLinksCount = STATIC_LABELS.length;
  const defaultNewItemName = "Facebook";

  const isValidUrl = (str) =>
    VALIDATIONPATTERNS.url.test(str) || str === "" || str === null;
  const getNameFromLabel = (label) => (label === "Twitter" ? "X" : label);

  const dataUrl = `${config.api.phoenix}/marketingprofiles/${mpId}/socialmedia`;

  const [socialLinks, setSocialLinks] = useState(
    socialmedia?.map((item, i) => ({
      ...item,
      name: STATIC_LABELS?.[i] || item.name,
    }))
  );

  const defaultValues = socialLinks ? Object.assign({}, socialLinks) : [];

  const { handleSubmit, formState, register, reset, setValue } = useForm({
    defaultValues,
  });

  const { dirtyFields, isDirty, errors } = formState;

  const [formOpen, setFormOpen] = useState(false);

  const handleNameChange = (value, i) => {
    setSocialLinks(
      socialLinks.map((item, index) =>
        index === i ? { ...item, name: value || defaultNewItemName } : item
      )
    );
    setValue(`${i}.name`, value, { shouldDirty: true });
  };

  const addRow = () => {
    const linksWithName = socialLinks?.filter((item) => !!item.name)?.length;
    if (!!formOpen && linksWithName < socialLinks?.length) {
      setSocialLinks(
        socialLinks.map((item, index) =>
          index === linksWithName
            ? { ...item, name: defaultNewItemName, url: "" }
            : item
        )
      );
      setValue(`${linksWithName}.name`, defaultNewItemName, {
        shouldDirty: true,
      });
      setValue(`${linksWithName}.url`, "", { shouldDirty: true });
      setValue(`${linksWithName}.active`, false);
    }
  };

  const prefillUrl = (name) =>
    STATIC_LABELS.indexOf(name) > -1
      ? `https://${name.toLowerCase()}.${
          name.toLowerCase().indexOf("hyves") > -1 ? "nl" : "com"
        }`
      : "https:/";

  const handleRemoveRow = (i) => {
    setSocialLinks(
      socialLinks.map((item, index) =>
        index === i ? { name: "", url: "", active: false } : item
      )
    );
    setValue(`${i}.name`, "", { shouldDirty: true });
    setValue(`${i}.url`, "", { shouldDirty: true });
    setValue(`${i}.active`, false, { shouldDirty: true });
  };

  const onSubmit = (data) => {
    const newPayload = Object.values(data);
    const payload = newPayload
      .filter((item) => !!item?.name)
      .map((item) => ({
        ...item,
        active: !!item.active,
        url: item.url || null,
      }));

    fetcher({
      url: dataUrl,
      method: "PUT",
      payload: payload,
      customerId: customerId,
      clientId: client?.id,
    })
      .then(() => {
        refreshMp();
        setFormOpen(false);
        reset({ ...data });
        setSocialLinks(
          newPayload?.map((item, i) => ({
            ...item,
            name: STATIC_LABELS?.[i] || item.name,
          }))
        );
      })
      .catch((err) => {
        setError(err);
        reset();
      });
  };

  const handleCancel = () => {
    const resetValues = socialmedia.map((item, i) => ({
      ...item,
      name: STATIC_LABELS?.[i] || item.name,
    }));

    setSocialLinks(resetValues);
    setFormOpen(false);
    reset();
  };

  const handleIconFromName = (name) => {
    if (name === "Facebook") return <BsFacebook />;
    else if (name === "Twitter") return <BsTwitterX />;
    else if (name === "Instagram") return <BsInstagram />;
    else if (name === "LinkedIn") return <BsLinkedin />;
    else if (name === "YouTube") return <BsYoutube />;
    else return null;
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to upload or submit image",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card className="mb-3 bg-dark bg-opacity-10">
        <Card.Header>Social</Card.Header>
        <Card.Body style={{ paddingLeft: "35px", paddingRight: "35px" }}>
          {socialLinks
            ?.filter((item) => !!item.name)
            .map((item, index) => (
              <div key={"social" + index}>
                {item.name !== "GooglePlus" && item.name !== "Hyves" && (
                  <Row className="d-flex">
                    {!formOpen ? (
                      <div className="w-100">
                        {item.active ? (
                          <div className="d-flex w-100">
                            <Col
                              className="mb-3 d-flex align-items-center"
                              style={{ maxWidth: "25%" }}
                            >
                              <BsCheck2
                                style={{ marginRight: "5px" }}
                                color="rgba(72, 213, 0, 1.0)"
                                fontSize="25px"
                              />
                              {"active"}
                            </Col>
                            <Col style={{ maxWidth: "25%" }}>
                              <h4
                                className="d-flex align-items-center w-100"
                                style={{ fontSize: "15px", marginTop: "3px" }}
                              >
                                <div style={{ marginRight: "5px" }}>
                                  {handleIconFromName(item.name)}
                                </div>
                                {getNameFromLabel(item.name)}
                              </h4>
                            </Col>
                            <Col className="d-flex">{item.url}</Col>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div className="d-flex align-items-center">
                        <Col
                          style={{
                            marginLeft: "7px",
                            maxWidth: "25%",
                          }}
                        >
                          <Form.Check
                            type="checkbox"
                            variant="success"
                            {...register(`${index}.active`)}
                            label="active"
                          />
                        </Col>
                        <Col
                          className="d-flex align-items-center"
                          style={{
                            maxWidth: "25%",
                          }}
                        >
                          {index < staticLinksCount ? (
                            <h4
                              className="d-flex align-items-center w-100"
                              style={{ fontSize: "15px", marginTop: "3px" }}
                            >
                              <div style={{ marginRight: "5px" }}>
                                {handleIconFromName(item.name)}
                              </div>
                              {getNameFromLabel(item.name)}
                            </h4>
                          ) : (
                            <Dropdown
                              onSelect={(eventKey) =>
                                handleNameChange(eventKey, index)
                              }
                            >
                              <h4
                                className="d-flex align-items-center w-100"
                                style={{ fontSize: "15px", marginTop: "3px" }}
                              >
                                <div style={{ marginRight: "5px" }}>
                                  {handleIconFromName(item.name)}
                                </div>
                                {getNameFromLabel(item.name)}
                                <Dropdown.Toggle
                                  variant="flush"
                                  style={{
                                    border: "none",
                                    marginTop: "3px",
                                  }}
                                >
                                  <BsCaretDown />
                                </Dropdown.Toggle>
                              </h4>

                              <Dropdown.Menu>
                                {CUSTOM_TYPES.map((option, i) => (
                                  <Dropdown.Item key={i} eventKey={option}>
                                    {option}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </Col>
                        <Col
                          className="mb-2 d-flex"
                          style={{ maxWidth: "45%" }}
                        >
                          <Form.Control
                            style={
                              dirtyFields[index]?.url
                                ? { backgroundColor: "rgba(0, 255, 0, 0.2)" }
                                : {}
                            }
                            type="text"
                            {...register(`${index}.url`, {
                              validate: (value) =>
                                isValidUrl(value) || "Invalid URL",
                            })}
                            placeholder={
                              !!formOpen ? prefillUrl(item.name) + "/..." : "-"
                            }
                            onFocus={(e) => {
                              if (!e.target.value) {
                                setValue(
                                  `${index}.url`,
                                  `${prefillUrl(item.name)}/`
                                );
                              }
                            }}
                            onBlur={(e) => {
                              if (
                                e.target.value === `${prefillUrl(item.name)}/`
                              ) {
                                setValue(`${index}.url`, null);
                              }
                            }}
                            isInvalid={!!errors?.[`${index}`]?.url}
                          />

                          {index >= staticLinksCount ? (
                            <Button
                              onClick={() => handleRemoveRow(index)}
                              variant="danger"
                              style={{ marginLeft: "5px" }}
                            >
                              <BsTrash />
                            </Button>
                          ) : null}
                        </Col>
                      </div>
                    )}
                  </Row>
                )}
              </div>
            ))}
          {formOpen &&
          socialLinks.filter((item) => !!item?.name)?.length < TOTAL ? (
            <div className="text-end" style={{ marginRight: "-15px" }}>
              <Button
                variant="link"
                onClick={addRow}
                disabled={
                  !formOpen ||
                  socialLinks.filter((item) => !!item?.name)?.length === TOTAL
                }
              >
                <BsPlus /> Add
              </Button>
            </div>
          ) : null}
        </Card.Body>
        <Card.Footer>
          {!formOpen ? (
            <div className="text-end">
              <Button variant="link" onClick={() => setFormOpen(true)}>
                <BsPencil className="btn-icon me-1" /> Edit
              </Button>
            </div>
          ) : (
            <div className="text-end">
              <Button variant="secondary" type="button" onClick={handleCancel}>
                <BsX className="btn-icon me-1" /> Cancel
              </Button>
              <Button variant="success" type="submit" disabled={!isDirty}>
                <BsCheck2 className="btn-icon me-1" /> Save changes
              </Button>
            </div>
          )}
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default CustomerMarketingProfileSocialMedia;
