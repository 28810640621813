import {
  BsBagHeartFill,
  BsBoxes,
  BsBoxSeam,
  BsEaselFill,
  BsFileEarmarkRichtextFill,
  BsSpeedometer,
  BsTable,
} from "react-icons/bs";
import { FaEnvelopesBulk, FaStore } from "react-icons/fa6";
import { TbChartAreaLineFilled } from "react-icons/tb";
import { GrDocumentTest } from "react-icons/gr";
import { ImCogs } from "react-icons/im";
import { IoMegaphone } from "react-icons/io5";
import CycleIcon from "../components/icons/CycleIcon";

export const listPageItems = [
  {
    path: "customers",
    title: "Customers",
    icon: FaStore,
    colorClass: "customer",
  },
  {
    path: "campaigns",
    title: "Campaigns",
    icon: IoMegaphone,
    colorClass: "campaign",
  },
  {
    path: "cycles",
    title: "Cycles",
    icon: CycleIcon,
    colorClass: "cycle",
  },
  {
    path: "orders",
    title: "Orders",
    icon: BsBagHeartFill,
    colorClass: "order",
  },
  {
    path: "variants",
    title: "Variants",
    icon: BsEaselFill,
    colorClass: "variant",
  },
  {
    id: "product",
    path: "products",
    title: "Products",
    icon: BsBoxSeam,
  },
  { id: "package", path: "packages", title: "Packages", icon: BsBoxes },
  {
    path: "actionlist",
    title: "Action lists",
    icon: BsTable,
    colorClass: "actionList",
  },
  {
    path: "quicksight",
    title: "Reports",
    icon: TbChartAreaLineFilled,
    colorClass: "quickSight",
  },
  {
    path: "productions",
    title: "Productions",
    icon: FaEnvelopesBulk,
    colorClass: "production",
  },
  {
    path: "documents",
    title: "Documents",
    icon: BsFileEarmarkRichtextFill,
    colorClass: "documents",
  },
  {
    path: "treatments",
    title: "Treatments",
    icon: GrDocumentTest,
    colorClass: "treatment",
  },
];

export const menuItems = [
  ...listPageItems,
  {
    path: "",
    title: "Dashboard",
    icon: BsSpeedometer,
    colorClass: "dashboard",
  },
  {
    path: "programs",
    title: "Programs",
    icon: ImCogs,
  },
];
