import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../../config";
import useStorage from "../../../context/StorageContext";
import fetcher from "../../../utils/fetcher";
import { useNotifications } from "../../../context/NotificationsContext";
import createDetailedErrorMessage from "../../../utils/detailMessageError";

const CopyToClientWrap = ({ component: Component }) => {
  const { documentId, documentType } = useParams();
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [selectedClient, setSelectedClient] = useState(null);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);

  const onCancel = () => {
    setShow(false);
    setSelectedClient(null);
  };

  const copyClient = () => {
    const dataUrl =
      documentType === "dm"
        ? `${config.api.dm}/documents/${documentId}/copytoclient/${selectedClient.id}`
        : documentType === "edm"
        ? `${config.api.edm}/documents/${documentId}/copytoclient/${selectedClient.id}`
        : "";

    fetcher({
      url: dataUrl,
      method: "POST",
      clientId: client?.id,
    })
      .then(() => {
        setShow(false);
        setSelectedClient(null);
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            text: (
              <span>{`Successfully copied document to ${selectedClient.name}`}</span>
            ),
          },
        ]);
      })
      .catch((err) => {
        setShow(false);
        setSelectedClient(null);
        setError(err);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to copy document to client",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Component
      show={show}
      setOpen={() => setShow(true)}
      onCancel={onCancel}
      copyClient={copyClient}
      selectedClient={selectedClient}
      setSelectedClient={setSelectedClient}
    />
  );
};
export default CopyToClientWrap;
