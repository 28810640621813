import React, { useState, useContext } from "react";

export const NotificationsContext = React.createContext();
export const useNotifications = () => useContext(NotificationsContext);

export const NotificationsProvider = ({ children }) => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [showSelectClient, setShowSelectClient] = useState(false);
  const [query, setQuery] = useState("");

  const handleShowOffCanvas = () => {
    setShowOffCanvas(true);
  };
  const handleCloseOffCanvas = () => setShowOffCanvas(false);
  const handleLookup = (q) => {
    setQuery(q);
    handleShowOffCanvas();
  };

  const [toasts, setToasts] = useState([]);

  return (
    <NotificationsContext.Provider
      value={{
        showOffCanvas,
        setShowOffCanvas,
        handleShowOffCanvas,
        handleCloseOffCanvas,
        toasts,
        setToasts,

        query,
        setQuery,
        showSelectClient,
        setShowSelectClient,

        handleLookup,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
