import { Button, Modal } from "react-bootstrap";
import CopyDocumentWrap from "./CopyDocumentWrap";

const CopyDocumentButton = ({ document, editable }) => {
  return (
    <CopyDocumentWrap
      document={document}
      component={({ setOpen, show, onCancel, handleCopy }) => {
        return (
          <div>
            <Button
              size="sm"
              disabled={editable}
              onClick={() => setOpen()}
              variant="success"
            >
              Copy document
            </Button>
            <Modal show={show} onHide={onCancel} size="lg" centered>
              <Modal.Header>
                <Modal.Title>Copy document</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex justify-content-center">
                  <span>
                    This action will make a copy of this document on the same
                    client.
                  </span>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button variant="success" onClick={handleCopy}>
                  Copy document
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      }}
    />
  );
};
export default CopyDocumentButton;
