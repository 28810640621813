import React, { useEffect, useRef, useState } from "react";
import ImgBox from "./ImgBox";
import FloatingInput from "./FloatingInput";
import { Form } from "react-bootstrap";
import Loader from "./Loader";
import { BsImageFill } from "react-icons/bs";

const ImgFileIdInput = ({
  id, //for using forms
  imgId, //for without using forms
  label = "File ID",
  width = 1000,
  height = width,
  padding,
  imgBg,
  resizeMode,
  formProps, //watch, reset and register have to be given to this object
  handleOnFileUpload,
  //make a custom File upload function. Use uploadFile in fetcherUploadFile.js,
  // give the correct parameters
  //and make a custom onSubmit function that will submit the img on in the
  // onSucces function of uploadFile
  fileName, //indicator of where to look for the filename
  imageContent, //content of the image for knowing when to refresh the image
  hasFloatingInput = true,
  onClick,
  hasWhitebox = true,
  className = null,
  icon = BsImageFill,
}) => {
  const { watch } = formProps;
  const [localFileId, setLocalFileId] = useState(watch(id) || imgId || "");
  const [loader, setLoader] = useState(false);
  const [editable, setEditable] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setLocalFileId(watch(id));
    setLoader(false);
    setEditable(false);
  }, [imageContent, watch, id]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleOnFileUpload(file);
      setLoader(true);
    }
    // Reset the input value to allow the same file to be uploaded again
    fileInputRef.current.value = null;
  };

  const handleImageClick = () => {
    document.getElementById(`${id}-fileUpload`).click();
  };

  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (parentRef.current) {
        setParentWidth(parentRef.current.clientWidth);
      }
    });

    if (parentRef.current) {
      observer.observe(parentRef.current);
    }

    return () => {
      if (parentRef) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div className="img-file-id-input">
      <div
        ref={parentRef}
        onClick={onClick || handleImageClick}
        style={{ cursor: "pointer" }}
        className="clickable-area mb-2 input-border rounded"
      >
        {loader ? (
          <div style={{ width: parentWidth, height: parentWidth }}>
            <Loader viewData />
          </div>
        ) : (
          <ImgBox
            imgId={localFileId} // Use localFileId which gets updated on apply
            width={width}
            height={height}
            padding={padding}
            imgBg={imgBg}
            resizeMode={resizeMode}
            icon={icon}
            // whiteBox={true}
            // whiteBoxWidth={hasWhitebox ? parentWidth : null}
            className={
              !!className
                ? className
                : `mb-2 border input-border rounded ${
                    editable ? "bg-white" : ""
                  }`
            }
          />
        )}
      </div>
      {hasFloatingInput && (
        <FloatingInput
          id={fileName}
          label={label}
          formProps={formProps}
          editable={editable}
        />
      )}
      <Form.Group controlId={`${id}-fileUpload`} style={{ display: "none" }}>
        <Form.Control
          type="file"
          accept=".png,.jpg,.jpeg,.psd,.eps"
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </Form.Group>
    </div>
  );
};

export default ImgFileIdInput;
