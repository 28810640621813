import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import useTreatments from "../../../hooks/useTreatments";

const AddTreatment = () => {
  const { show, setShow, onCreate, formProps } = useTreatments({
    heading: "Failed to create treatment",
    defaultValues: {
      name: "",
      code: "",
    },
  });
  return (
    <>
      <Button onClick={() => setShow(true)} size="sm" variant="success">
        <GoPlus className="btn-icon" /> New
      </Button>
      <Modal show={show} size="md" onHide={() => setShow(false)} centered>
        <Form onSubmit={formProps.handleSubmit(onCreate)}>
          <Modal.Header closeButton>Add new treatment</Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <FloatingLabel label="Treatment name*">
                <Form.Control
                  {...formProps.register("name", {
                    required: "Treatment name is required",
                  })}
                />
              </FloatingLabel>
            </div>
            <div style={{ width: "50%" }}>
              <FloatingLabel label="Treatment code*">
                <Form.Control
                  {...formProps.register("code", {
                    required: "Treatment code is required",
                  })}
                />
              </FloatingLabel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShow(false);
                formProps.reset();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              type="submit"
              disabled={!formProps.isDirty}
            >
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddTreatment;
