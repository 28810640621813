import { ListGroup, ListGroupItem } from "react-bootstrap";
import DocumentParameterListGroupItem from "./DocumentParameterListGroupItem";

const DocumentParameterListGroup = ({ parameters, zoomIn }) => {
  return (
    <ListGroup
      className="w-100"
      style={
        zoomIn
          ? {}
          : {
              maxHeight: 400,
              overflowY: "auto",
              scrollbar: "thin",
            }
      }
    >
      {!!parameters && parameters.length > 0 ? (
        parameters?.map((parameter) => (
          <DocumentParameterListGroupItem
            key={parameter.name}
            name={parameter.name}
            type={parameter.type}
          />
        ))
      ) : (
        <ListGroupItem className="d-flex justify-content-center">
          <span style={{ height: 200 }} className="d-flex align-items-center">
            No parameters added to this document
          </span>
        </ListGroupItem>
      )}
    </ListGroup>
  );
};
export default DocumentParameterListGroup;
