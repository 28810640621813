import React from "react";
import { Button, Container, Nav, Navbar, NavbarBrand } from "react-bootstrap";
import { FaEnvelopesBulk } from "react-icons/fa6";
import { PiSignOut } from "react-icons/pi";
import { useAuthContext } from "../../../context/AuthContext";
import { Link, NavLink } from "react-router-dom";
import PhoenixLogo from "../../icons/PhoenixLogo";

const ProductionsHeader = () => {
  const { logout } = useAuthContext();
  return (
    <Navbar
      bg="dark"
      variant="dark"
      fixed="top"
      data-bs-theme="dark"
      className="header-top"
    >
      <Container fluid>
        <NavbarBrand
          as={Link}
          to="/"
          className="d-inline-flex align-items-center py-0"
        >
          <PhoenixLogo className="navbar-brand-logo me-2" />
        </NavbarBrand>
        <NavbarBrand
          as={Link}
          to="/productions"
          className="d-inline-flex align-items-center py-0 me-3"
        >
          <FaEnvelopesBulk className="me-2 fs-5 text-module" />
          <span className="small text-muted hover-text-light">Productions</span>
        </NavbarBrand>

        <Nav
          className="ms-5 me-3"
          style={{ "--bs-navbar-nav-link-padding-x": "1rem" }}
        >
          <Nav.Link as={NavLink} to="/productions/productions" variant="link">
            Productions
          </Nav.Link>
          <Nav.Link as={NavLink} to="/productions/bundles" variant="link">
            Bundles
          </Nav.Link>
          <Nav.Link as={NavLink} to="/productions/new" variant="link">
            New production
          </Nav.Link>
        </Nav>
        <div className="flex-grow-1"></div>
        <Nav>
          <Nav.Link as={Button} variant="link" title="Logout" onClick={logout}>
            <PiSignOut className="fs-5 opacity-75" />
          </Nav.Link>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default ProductionsHeader;
