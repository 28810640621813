import React, { useState } from "react";
import { ListGroup, Offcanvas, Stack, Badge, Button } from "react-bootstrap";
import { FaBuildingUser, FaUser } from "react-icons/fa6";
import { config } from "../../config";
import { useAuthContext } from "../../context/AuthContext";
import PaginationFromHeader from "../ui/PaginationFromHeader";
import WithLoaderAndError from "../ui/WithLoaderAndError";
import { useNavigate } from "react-router-dom";
import { PiSignOut } from "react-icons/pi";
import useStorage from "../../context/StorageContext";
import useFetch from "../../hooks/useFetch";

const SelectClient = ({ show, setShow }) => {
  const [page, setPage] = useState(1);

  const { logout } = useAuthContext();
  const { client, setClient } = useStorage();

  const navigate = useNavigate();

  // data
  const PAGE_SIZE = 30;

  const { data: user } = useFetch({
    url: `${config.auth.bffUrl}/auth/userinfo`,
  });

  const {
    data: clients,
    headers,
    error: clientsError,
  } = useFetch({
    url: `${config.api.phoenix}/clients?pageSize=${PAGE_SIZE}&page=${page}`,
  });

  // set customer
  const handleSelect = (c) => {
    localStorage.setItem("client", JSON.stringify(c));
    setClient(c);
    setClient((prevClient) => ({
      ...prevClient,
      customer: null,
    }));
    setShow(false);
    setTimeout(() => {
      setPage(1);
    }, 200);
    navigate(`/`);
  };

  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      placement="end"
      data-bs-theme="dark"
      backdropClassName="backdrop-filter"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        className="bg-black bg-opacity-25 header-inner-height"
      >
        <h5 className="fw-light mb-0">
          <FaBuildingUser
            className="fs-3 opacity-50 align-text-bottom"
            style={{ marginRight: "1rem", marginLeft: ".7rem" }}
          />
          Client
        </h5>
      </Offcanvas.Header>

      <Offcanvas.Body className="p-0" style={{ scrollbarWidth: "thin" }}>
        <WithLoaderAndError
          isLoading={!clients && !clientsError}
          error={clientsError}
          minHeight="100%"
        >
          {clients && !!clients.length ? (
            <ListGroup
              variant="flush"
              className="w-100 border-bottom"
              style={{
                "--bs-list-group-active-bg": "rgba( var(--bs-px-hl-rgb), .6)",
                "--bs-list-group-active-border-color":
                  "rgba( var(--bs-px-hl-rgb), .4)",
              }}
            >
              {clients.map((c) => (
                <ListGroup.Item
                  key={c.id}
                  action
                  onClick={() => handleSelect(c)}
                  active={client?.id === c.id}
                  as={Stack}
                  direction="horizontal"
                  className="justify-content-between cursor-pointer hover-parent"
                >
                  <Badge
                    bg={client?.id === c.id ? "dark" : "px-hl"}
                    className={`${
                      client?.id === c.id ? "" : "hover-opacity-0"
                    } bg-opacity-50 text-light text-opacity-75 font-monospace me-2 ms-n1`}
                  >
                    {c.id}
                  </Badge>
                  <span className="flex-grow-1 ms-1 small">{c.name}</span>
                  <span
                    className={`${
                      client?.id === c.id ? "" : "hover-opacity-0"
                    } font-monospace small opacity-75`}
                  >
                    {c.code}
                  </span>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <div className="text-muted p-3">No clients available</div>
          )}
        </WithLoaderAndError>
      </Offcanvas.Body>

      {!!headers?.link ? (
        <Offcanvas.Header className="p-2 justify-content-center bg-black bg-opacity-10">
          <PaginationFromHeader
            current={page}
            linkStr={headers.link}
            setPage={setPage}
            variant="secondary"
          />
        </Offcanvas.Header>
      ) : null}

      <Offcanvas.Header
        className="bg-black bg-opacity-25 justify-content-end"
        style={{ height: "var(--footer-height)" }}
      >
        <Button
          variant="link"
          className="hover-parent link-light text-decoration-none"
          onClick={logout}
        >
          <span className="small me-3 font-monospace opacity-75">
            <FaUser className="me-1 " />
            {user?.name}
          </span>
          <span className="hover-opacity-0">Log out</span>
          <PiSignOut className="ms-2 fs-4 align-text-top" />
        </Button>
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default SelectClient;
