import React, { useState } from "react";
import { Col, Dropdown, FormControl, Row } from "react-bootstrap";
import { BsCaretDown } from "react-icons/bs";

const DropdownTextField = React.forwardRef(
  ({ options, value = "", onChange, placeholder, name, style }, ref) => {
    const [inputValue, setInputValue] = useState(value);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleSelect = (selectedValue) => {
      setInputValue(selectedValue);

      const event = {
        target: {
          name: name,
          value: selectedValue,
        },
      };
      onChange(event);
      setIsDropdownOpen(false);
    };

    const handleInputChange = (e) => {
      setInputValue(e.target.value);
      onChange(e);
    };

    return (
      <Dropdown
        show={isDropdownOpen}
        onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
        className="w-100"
      >
        <Row className="d-flex align-items-start">
          <Col>
            <FormControl
              ref={ref}
              value={inputValue}
              placeholder={placeholder}
              onChange={handleInputChange}
              name={name}
              style={style}
            />
          </Col>
          <Col style={{ maxWidth: "10%" }}>
            <Dropdown.Toggle
              variant="secondary"
              style={{ marginLeft: "-65px" }}
            >
              <BsCaretDown />
            </Dropdown.Toggle>
          </Col>
        </Row>
        <Dropdown.Menu style={{ width: "50px" }}>
          {options.map((option, index) => (
            <Dropdown.Item key={index} onClick={() => handleSelect(option)}>
              {option}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
);

export default DropdownTextField;

/*
Example of how this componen would be used in CustomerMarketingProfileSocialMedia

<DropdownTextField
    options={CUSTOM_TYPES}
    value={item.name}
    onChange={(value) => handleNameChange(value, index)}
    placeholder="Select or type..."
    style={
    dirtyFields[index]?.name
        ? {
            backgroundColor: "rgba(0, 255, 0, 0.2)",
        }
        : {}
    }
    {...register(`${index}.name`)}
/>
*/
