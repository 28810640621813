import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { useParams } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import { config } from "../../config";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";
import WithLoaderAndError from "../ui/WithLoaderAndError";
import Error from "../ui/Error";
import Loader from "../ui/Loader";
import FavoriteButton from "../custom/FavoriteButton";
import useStorage from "../../context/StorageContext";

const QSDetail = () => {
  const { reportId, viewId } = useParams();
  const { client } = useStorage();

  const [customerId, setCustomerId] = useState(null);
  const [error, setError] = useState(null);

  // Fetch customers in oder to pick up view
  const { data: customers } = useFetch({
    url: `${config.api.phoenix}/customers?pageSize=30&page=1&clientId=${client?.id}`,
    clientId: client?.id,
  });

  useEffect(() => {
    if (!!customers?.[0]?.id) {
      setCustomerId(customers?.[0]?.id);
    }
  }, [customers]);

  const {
    data: view,
    error: fetchError,
    isValidating: viewIsValidating,
  } = useFetch({
    url: `${config.api.reporting}/views/${reportId}/${viewId}`,
    clientId: client?.id,
    customerId: customerId,
    shouldFetch: () => !!customerId && !!viewId,
    revalidateOnReconnect: true,
  });

  const parentPage = {
    title: "QuickSight",
    url: `/reports/quicksight`,
  };
  const pageTitle = view?.name || `QuickSight report ${reportId}`;

  useEffect(() => {
    const container = document.querySelector("#qsContainer");
    setError(null);
    if (container) {
      container.innerHTML = null;
    }

    const navbarHeight =
      document.querySelector(".header-top")?.clientHeight || 60;
    const headerHeight =
      document.querySelector(".page-head")?.clientHeight || 142;
    const contentHeight =
      document.querySelector(".page-content")?.clientHeight || 0;
    const iframeHeight = document.documentElement.clientHeight - navbarHeight;
    const frameTopPosition = navbarHeight + headerHeight + contentHeight;

    if (
      !!view &&
      view?.chartType === "quickSight" &&
      view?.charts?.[0]?.embedUrl &&
      !!container &&
      !error
    ) {
      createEmbeddingContext().then((embeddingContext) => {
        embeddingContext
          .embedDashboard(
            {
              // frameOptions
              url: view?.charts?.[0]?.embedUrl,
              container: container,
              height: `${iframeHeight}px`,
              resizeHeightOnSizeChangedEvent: true,
              onChange: (changeEvent) => {
                if (changeEvent?.eventName === "FRAME_LOADED") {
                  setError(null);
                  window.scrollTo({
                    top: frameTopPosition,
                  });
                }
              },
            },
            {
              // contentOptions
              parameters: [
                {
                  Name: "CustomerUids",
                  Values: view?.charts?.[0]?.customerIds,
                },
              ],
              toolbarOptions: {
                export: true,
              },
            }
          )
          .catch((err) =>
            setError({
              ...err,
              title: "An error occurred",
              text: err?.message || "Could not load dashboard",
            })
          );
      });
    }
    return () => {
      if (container) {
        container.innerHTML = null;
      }
      setError(null);
    };
  }, [view, error]);

  return (
    <Layout
      pageTitle={pageTitle}
      headerItems={
        <FavoriteButton viewId={viewId} className="p-0 my-n1 me-2" />
      }
      breadcrumbsLabel={
        <span className="ms-1 font-monospace fs-75 text-body text-opacity-75">
          <span className="opacity-75">Report ID</span> {reportId}
        </span>
      }
    >
      <WithLoaderAndError
        isLoading={viewIsValidating}
        error={fetchError}
        fullpage
        errorActionNavigateTo={parentPage || null}
      ></WithLoaderAndError>

      {viewIsValidating ? (
        <Loader pageWithHeader />
      ) : error ? (
        <Error
          navigateTo={{
            url: "/reports/quicksight",
            title: "QuickSight",
          }}
          error={error}
          pageWithHeader
        />
      ) : null}

      <div
        className="border-top"
        id="qsContainer"
        style={{ display: viewIsValidating ? "none" : "block" }}
      ></div>
    </Layout>
  );
};

export default QSDetail;
/*
import DOMPurify from "dompurify";
<PageHeading subhead={view?.subtitle}>
          {view?.description ? (
            <div
              className="small"
              dangerouslySetInnerHTML={
                !!view &&
                !!view.description && {
                  __html: DOMPurify.sanitize(view.description, {
                    USE_PROFILES: { html: true },
                  }),
                }
              }
            />
          ) : null}
        </PageHeading>
*/
