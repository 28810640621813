import { Badge, Card, Form } from "react-bootstrap";
import Layout from "../../layout/Layout";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import TreatmentDetailWrap from "./TreatmentHelper/TreatmentDetailWrap";
import ImgFileIdInput from "../../ui/ImgFileIdInput";
import { BsJournalBookmarkFill, BsTrash } from "react-icons/bs";
import TreatmentGeneral from "./TreatmentGeneral";
import DeleteTreatment from "./DeleteTreatment";
import CopyTreatment from "./CopyTreatment";
import CopyTreatmentToClient from "./CopyTreatmentToClient";
import TreatmentParameterView from "./TreatmentParameterView";

const TreatmentDetail = () => {
  return (
    <TreatmentDetailWrap
      component={({
        treatment,
        editable,
        setEditable,
        fetchError,
        isValidating,
        hover,
        setHover,
        defaultValues,
        isBusy,
        formProps,
        errors,
        isSubmitted,
        onCancel,
        onSubmit,
        handleSubmit,
        handleOnFileUpload,
        isDirty,
        setShowRemoveModal,
        postalClassEnum,
        postalGroupEnum,
        postalClassError,
        postalGroupError,
        zoomIn,
        setZoomIn,
        refreshTreatment,
      }) => {
        return (
          <Layout pageTitle={treatment?.name || "..."}>
            <WithLoaderAndError
              isLoading={!treatment && !fetchError && isValidating}
              error={
                !treatment && fetchError && postalClassError && postalGroupError
              }
              fullpage
            >
              {!!treatment && (
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Card className="card-main border-0">
                    <Card.Header className="d-flex justify-content-end align-items-center gap-2">
                      <CopyTreatmentToClient
                        treatment={treatment}
                        editable={editable}
                      />
                      <CopyTreatment
                        treatment={treatment}
                        editable={editable}
                      />
                      <DeleteTreatment
                        editable={editable}
                        treamtentName={treatment?.name}
                      />
                    </Card.Header>

                    <Card.Body>
                      <div className="d-flex w-100">
                        <div>
                          <div
                            className="img-wrap position-relative"
                            style={{ width: 300, padding: 10 }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                          >
                            <ImgFileIdInput
                              className="fade-in delay img-box-aspect-ratio-65 mb-3"
                              id={"thumbnailFileId"}
                              formProps={formProps}
                              imageContent={treatment}
                              defaultValues={defaultValues}
                              resizeMode="Contain"
                              imgBg="white"
                              icon={BsJournalBookmarkFill}
                              hasWhitebox
                              padding={4}
                              handleOnFileUpload={(file) =>
                                handleOnFileUpload(file)
                              }
                            />
                            {!!treatment?.thumbnailFileId && hover && (
                              <Badge
                                pill
                                bg="danger-tint-10"
                                className="position-absolute top-0"
                                style={{
                                  paddingLeft: ".4em",
                                  paddingRight: ".4em",
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: 0,
                                }}
                                onClick={() => setShowRemoveModal(true)}
                              >
                                <BsTrash />
                              </Badge>
                            )}
                          </div>
                          <div className="d-flex justify-content-center">
                            <Form.Check
                              onChange={() =>
                                formProps?.setValue(
                                  "active",
                                  !formProps?.watch("active")
                                )
                              }
                              type="switch"
                              label="Active"
                              checked={formProps?.watch("active") === true}
                              readOnly={!editable}
                              disabled={!editable}
                            />
                          </div>
                        </div>
                        <TreatmentGeneral
                          treatment={treatment}
                          editable={editable}
                          setEditable={setEditable}
                          formProps={formProps}
                          errors={errors}
                          isSubmitted={isSubmitted}
                          isDirty={isDirty}
                          isBusy={isBusy}
                          onCancel={onCancel}
                          postalClassEnum={postalClassEnum}
                          postalGroupEnum={postalGroupEnum}
                        />
                      </div>
                    </Card.Body>
                  </Card>

                  <div className="d-flex justify-content-center">
                    <TreatmentParameterView
                      treatment={treatment}
                      parameters={treatment?.parameters}
                      zoomIn={zoomIn}
                      setZoomIn={setZoomIn}
                      refresh={refreshTreatment}
                    />
                  </div>
                </Form>
              )}
            </WithLoaderAndError>
          </Layout>
        );
      }}
    />
  );
};

export default TreatmentDetail;
