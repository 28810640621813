import DOMPurify from "dompurify";
import React from "react";
import { Dropdown, Form, Table } from "react-bootstrap";
import {
  BsArrowRight,
  BsCheck2,
  BsDashSquareFill,
  BsFunnelFill,
  BsX,
} from "react-icons/bs";
import formatDate from "../../../utils/formatDate";

const TableALDetail = ({
  view,
  viewData,
  selectedRows,
  setSelectedRows,
  actionOption,
  setSelectedFilters,
  selectedFilters,
}) => {
  const handleRowClick = (rowData) => {
    const rowIndex = selectedRows.findIndex((row) => row === rowData);
    if (rowIndex === -1) {
      if (selectedRows.filter((rowId) => rowId === rowData?.id)?.length > 0) {
        setSelectedRows((prev) =>
          prev.filter((rowId) => rowId !== rowData?.id)
        );
      } else {
        setSelectedRows([...selectedRows, rowData.id]);
      }
    } else {
      setSelectedRows([
        ...selectedRows.slice(0, rowIndex),
        ...selectedRows.slice(rowIndex + 1),
      ]);
    }
  };

  const isSelected = (rowId) => {
    return selectedRows?.includes(rowId);
  };

  const clearSelectedFilter = (colId) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.filter((item) => item.colId !== colId)
    );
  };

  const onFilterChanged = ({ colId, selectedItems }) => {
    setSelectedFilters((prevFilters) => {
      const filtersToAdd = Array.isArray(selectedItems)
        ? selectedItems
        : [selectedItems];

      return [
        ...prevFilters.filter((item) => item.colId !== colId),
        {
          colId: colId,
          filters: filtersToAdd,
        },
      ];
    });
  };

  return (
    <Table
      responsive
      hover
      striped
      className="mb-0"
      style={{ minHeight: "550px" }}
    >
      <thead>
        <tr>
          {!!view?.chart?.actions?.length ? (
            <th className="text-center" style={{ minWidth: "2.55em" }}>
              <BsDashSquareFill
                title={`Deselect all (${selectedRows?.length})`}
                className="cursor-pointer link-success"
                onClick={() => setSelectedRows([])}
                visibility={selectedRows?.length ? "visible" : "hidden"}
              />
            </th>
          ) : null}
          {view?.chart?.columns?.map((column, index) => (
            <th
              key={index}
              className="text-nowrap"
              style={
                !!column?.actionOptions
                  ? { backgroundColor: "rgba(0, 123, 170, 0.1)" }
                  : {}
              }
            >
              <span className="">
                {column?.name + " "}

                {!!column?.actionOptions ? (
                  <>
                    <BsArrowRight /> {actionOption?.label}
                  </>
                ) : (
                  ""
                )}

                {!!column?.filter ? (
                  <Dropdown as={"span"}>
                    <Dropdown.Toggle
                      className="p-0 ms-2"
                      variant="link"
                      id="dropdown-basic"
                    >
                      <BsFunnelFill />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        maxHeight: "350px",
                        overflowY: "scroll",
                      }}
                    >
                      <Dropdown.Item
                        key={"no-filters"}
                        onClick={() => clearSelectedFilter(column?.id)}
                        style={
                          !selectedFilters.some(
                            (obj) =>
                              obj.colId === column?.id && obj.filters.length > 0
                          )
                            ? {
                                backgroundColor: "rgba(0, 123, 170, 0.2)",
                              }
                            : {}
                        }
                      >
                        {"No filters"}
                      </Dropdown.Item>
                      {column?.filter.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => {
                            onFilterChanged({
                              colId: column?.id,
                              selectedItems: item,
                            });
                          }}
                          style={
                            selectedFilters.some(
                              (filterObj) =>
                                filterObj.colId === column?.id &&
                                filterObj.filters.includes(item)
                            )
                              ? {
                                  backgroundColor: "rgb(0, 123, 170, 0.2)",
                                }
                              : {}
                          }
                        >
                          {item}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
                {selectedFilters.filter(
                  (item) =>
                    item.colId === column?.id && item.selectedItems !== ""
                ).length > 0 ? (
                  <span
                    style={{
                      display: "inline-block",
                      width: "7px",
                      height: "7px",
                      backgroundColor: "#21a630",
                      borderRadius: "50%",
                      marginLeft: "5px",
                      verticalAlign: "middle",
                    }}
                  ></span>
                ) : null}
              </span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {viewData?.rows?.length === 0 ? ( // Check if viewData is empty
          <tr>
            <td
              colSpan={view?.chart?.columns?.length + 2}
              style={{
                textAlign: "center", // Horizontally center the text
                verticalAlign: "middle", // Vertically center the cell
              }}
            >
              No rows to display
            </td>
          </tr>
        ) : (
          viewData?.rows?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              onChange={() => handleRowClick(row)}
              className={isSelected(row.id) ? "table-success" : ""}
            >
              {!!view?.chart?.actions?.length ? (
                <td className="text-center">
                  <Form.Check
                    type="checkbox"
                    checked={isSelected(row.id)}
                    onChange={() => handleRowClick(row)}
                    disabled={selectedRows?.length >= 50 && !isSelected(row.id)}
                  />
                </td>
              ) : null}
              {row?.data.map((cell, cellIndex) => {
                const dataType = view?.chart?.columns[cellIndex]?.dataType;

                return (
                  <td key={cellIndex}>
                    {dataType === "date" ? (
                      !!cell ? (
                        <span>{formatDate(cell)}</span>
                      ) : (
                        ""
                      )
                    ) : dataType === "dateTime" ? (
                      !!cell ? (
                        <span>
                          {formatDate(
                            cell,
                            {},
                            { showTime: true, showSeconds: true }
                          )}
                        </span>
                      ) : (
                        ""
                      )
                    ) : dataType === "boolean" ? (
                      cell === 1 ? (
                        <>
                          <BsCheck2 className="text-success" /> Yes
                        </>
                      ) : (
                        <>
                          <BsX className="text-danger" /> No
                        </>
                      )
                    ) : typeof cell === "string" ? (
                      <div
                        className="hide-until-sized"
                        dangerouslySetInnerHTML={
                          !!cell
                            ? {
                                __html: DOMPurify.sanitize(cell, {
                                  USE_PROFILES: { html: true },
                                }),
                              }
                            : null
                        }
                      />
                    ) : (
                      cell
                    )}
                  </td>
                );
              })}
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );
};

export default TableALDetail;
