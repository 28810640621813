import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { config } from "../../../../config";
import { Col, Modal, Row } from "react-bootstrap";
import fetcher from "../../../../utils/fetcher";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import { BsPencil } from "react-icons/bs";
import FormComponent from "../../../ui/FormComponent";
import useStorage from "../../../../context/StorageContext";
import { useNotifications } from "../../../../context/NotificationsContext";

const CustomerDetailsAddressesForm = ({
  onSucces,
  addresses = [],
  index,
  formData,
}) => {
  const { client } = useStorage();
  const { setToasts } = useNotifications();

  const { customerId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const dataUrl = `${config.api.phoenix}/customers/${customerId}/addresses`;

  const options = [
    { value: "shop", label: "shop" },
    { value: "delivery", label: "delivery" },
    { value: "postal", label: "postal" },
    { value: "invoice", label: "invoice" },
  ];

  const onSubmit = (data) => {
    fetcher({
      url: `${dataUrl}`,
      method: "PUT",
      payload: [
        ...(!formData
          ? addresses.concat([{ ...data }])
          : addresses.map((item, i) => (index === i ? { ...data } : item))),
      ],
      customerId: config.auth.testCustomer,
      clientId: client?.id,
    })
      .then((res) => {
        setShowModal(false);
        onSucces();
      })
      .catch((err) => {
        setError(err);
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to add or edit address",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <FormComponent
      dataUrl={dataUrl}
      method="PUT"
      content={formData}
      customOnSubmit={onSubmit}
    >
      {!!formData ? (
        <FormComponent.EditModal
          setShowModal={setShowModal}
          variant="link"
          size="sm"
        >
          <BsPencil className="btn-icon me-1" /> Edit
        </FormComponent.EditModal>
      ) : (
        <FormComponent.EditModal setShowModal={setShowModal} variant="primary">
          Add address
        </FormComponent.EditModal>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header>
          <Modal.Title>
            {!!formData ? "Edit address" : "Add address"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-3 mb-3">
            <FormComponent.Field
              fieldName="type"
              fieldLabel="Type address"
              type="select"
              fieldOptions={options}
            />
          </Row>
          <Row className="g-3 mb-3">
            <FormComponent.Field fieldName="name" fieldLabel="Name" />
          </Row>
          <Row className="g-3 mb-3">
            <FormComponent.Field fieldName="street" fieldLabel="Street" />
          </Row>
          <Row className="g-3 mb-3">
            <Col>
              <FormComponent.Field
                fieldName="houseNumber"
                fieldLabel="House number"
                type="number"
              />
            </Col>
            <Col>
              <FormComponent.Field fieldName="suffix" fieldLabel="Suffix" />
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <FormComponent.Field
              fieldName="postalCode"
              fieldLabel="Postal code"
            />
          </Row>
          <Row className="g-3 mb-3">
            <FormComponent.Field fieldName="city" fieldLabel="City" />
          </Row>
          <Row className="g-3 mb-3">
            <FormComponent.Field fieldName="province" fieldLabel="Province" />
          </Row>
          <Row className="g-3 mb-3">
            <FormComponent.Field fieldName="country" fieldLabel="Country" />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <FormComponent.CancelModal
            variant="primary"
            setShowModal={setShowModal}
            formData={formData}
          />
          <FormComponent.SaveModal variant="success" />
        </Modal.Footer>
      </Modal>
    </FormComponent>
  );
};

export default CustomerDetailsAddressesForm;
