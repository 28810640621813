import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import fetcher from "../../../utils/fetcher";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import { GoPlus } from "react-icons/go";
import { config } from "../../../config";
import { useNavigate } from "react-router-dom";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import happy from "../../../assets/il-happy.svg";
import useStorage from "../../../context/StorageContext";
import { useNotifications } from "../../../context/NotificationsContext";

const AddNewCustomer = () => {
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const { register, reset, handleSubmit, formState } = useForm({
    defaultValues: {
      customerName: "",
      customerCode: "",
    },
  });

  const { isDirty } = formState;

  const onSubmit = (data) => {
    const payload = {
      active: true,
      name: data.customerName,
      code: data.customerCode,
    };

    fetcher({
      url: `${config.api.phoenix}/customers`,
      method: "POST",
      payload: payload,
      clientId: client?.id,
    })
      .then((res) => {
        navigate(`/customers/${res.data.id}`);
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            img: happy,
            heading: "Success!",
            text: (
              <div>
                {"Customer "} <strong>{res.data.name}</strong>{" "}
                {"is successfully created."}
              </div>
            ),
          },
        ]);
      })
      .catch((err) => {
        setError(err);
      });

    setShowModal(false);
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to create new Customer",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <>
      <Button onClick={() => setShowModal(true)} size="sm" variant="success">
        <GoPlus className="btn-icon" /> New
      </Button>
      <Modal
        show={showModal}
        size="md"
        onHide={() => setShowModal(false)}
        centered
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            <Modal.Title>Add new customer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-4">
              <FloatingLabel label="Customer name*">
                <Form.Control
                  {...register("customerName", {
                    required: "Customer name is required",
                  })}
                />
              </FloatingLabel>
            </div>
            <div style={{ width: "50%" }}>
              <FloatingLabel label="Customer code*">
                <Form.Control
                  {...register("customerCode", {
                    required: "Customer code is required",
                  })}
                />
              </FloatingLabel>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
                reset();
              }}
            >
              Cancel
            </Button>
            <Button variant="success" type="submit" disabled={!isDirty}>
              Add
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddNewCustomer;
