import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Dropdown,
  Modal,
  Table,
} from "react-bootstrap";
import { config } from "../../../config";
import SelectCustomerDrawer from "../../custom/SelectCustomerDrawer";
import fetcher from "../../../utils/fetcher";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import PaginationButtons from "../../ui/PaginationButtons";
import { GoPlus } from "react-icons/go";
import { BsArrowLeftRight } from "react-icons/bs";
import AddVariantManually from "./AddVariantManually";
import ImgBox from "../../ui/ImgBox";
import { FaStore } from "react-icons/fa6";
import useStorage from "../../../context/StorageContext";
import { useNotifications } from "../../../context/NotificationsContext";

const AddVariantByProfile = () => {
  const { client, setClient } = useStorage();
  const { setToasts } = useNotifications();
  const [showDrawer, setShowDrawer] = useState(false); //modal for choosing customer. Appears if no customer is filtered yet
  const [showMultiCustomerDrawer, setShowMultiCustomer] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false); //modal for selecting the profile

  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState({});
  const [currentCustomer, setCurrentCustomer] = useState(null);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;

  const handleProfiles = (customer) => {
    if (!!customer?.id || !!client?.customer?.id) {
      fetcher({
        url: `${config.api.phoenix}/profiles?customerId=${
          customer?.id || client?.customer?.id
        }&filter=AvailableForCustomer&page=${page}&pageSize=${PAGE_SIZE}&extraInfo=true`,
        method: "GET",
        customerId: customer?.id || client?.customer?.id,
        clientId: client?.id,
      })
        .then((res) => {
          if (res.data) {
            setProfiles(res.data);
            setLoading(false);
            setShowProfileModal(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(err);
        });
    } else {
      setShowProfileModal(false);
    }
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "An error occured",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  useEffect(() => {
    if (!client?.customer) return;
    setCurrentCustomer(client?.customer);
  }, [client?.customer]);

  //We first fetch the right programId and then for each profileVariant make a new variant
  const createVariantByProfile = () => {
    if (selectedProfile?.profileVariants?.length > 0) {
      fetcher({
        url: `${config.api.phoenix}/profiles/${selectedProfile.id}`,
        method: "GET",
        customerId: client?.customer?.id,
        clientId: client?.id,
      })
        .then((res) => {
          selectedProfile.profileVariants.forEach((variant, index) => {
            setTimeout(() => {
              //to prevent that Date.now generates the same id
              const payload = {
                name: variant.variantName,
                description: variant?.description,
                customerId: client?.customer?.id,
                programId: res.data.programId,
                treatmentId: variant.treatment.id,
                active: true,
                mailMomentId: variant.mailMoment.id,
                segmentId: variant.segment?.id || null,
              };

              fetcher({
                url: `${config.api.phoenix}/variants`,
                method: "POST",
                payload: payload,
                customerId: client?.customer?.id,
                clientId: client?.id,
              })
                .then(() => {
                  setToasts((currToasts) => [
                    ...currToasts,
                    {
                      id: Date.now(),
                      variant: "success",
                      heading: "Success!",
                      text: (
                        <div>
                          {"Variant "} <strong>{variant.variantName}</strong>{" "}
                          {"has been successfully created for Customer "}{" "}
                          <strong>{client?.customer?.name}</strong>
                        </div>
                      ),
                    },
                  ]);
                })
                .catch((err) => {
                  setToasts((currToasts) => [
                    ...currToasts,
                    {
                      id: Date.now(),
                      variant: "danger",
                      heading: (
                        <div>
                          {"An error occurred for variant "}
                          <strong>{variant.variantName}</strong>
                          {" for Customer "}
                          <strong>{client?.customer?.name}</strong>
                        </div>
                      ),
                      text: createDetailedErrorMessage(err),
                    },
                  ]);
                });
            }, index * 100);
          });
        })
        .catch((err) => {
          setError(err);
        });
      setSelectedProfile({});
    }
  };

  const ProfileVariantsCell = ({ profileVariants = [] }) => {
    const count = profileVariants.length;

    if (count === 0) {
      return <span className="text-muted">No variants</span>;
    }

    if (count === 1) {
      const fullName = profileVariants[0].variantName || "";
      const truncated =
        fullName.length > 15 ? `${fullName.slice(0, 15)}…` : fullName;
      return <span>{truncated}</span>;
    }

    return <span>{count} Variants</span>;
  };

  return (
    <>
      <Button
        as={ButtonGroup}
        variant="success"
        size="sm"
        className="d-flex align-items-center"
        onClick={() => {
          if (!!client?.customer?.id) {
            handleProfiles();
          } else {
            setShowDrawer(true);
          }
        }}
      >
        <GoPlus className="btn-icon" /> New
      </Button>

      {/* Modal to select a profile for creating a new variant*/}
      <Modal
        centered
        show={showProfileModal}
        onHide={() => {
          setShowProfileModal(false);
          setSelectedProfile({});
        }}
        style={{
          zIndex: showMultiCustomerDrawer || showDrawer ? 901 : 1051,
        }}
        backdrop={!showDrawer} // No backdrop when the drawer is open
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Select a profile to create a Variant`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!!client?.customer || (!!profiles && profiles.length > 0) ? (
            <WithLoaderAndError
              isLoading={loading}
              fullpage
              pageWithHeader
              className="w-100"
            >
              {!!client?.customer && (
                <div
                  className="d-flex"
                  style={{ marginLeft: 12, paddingBottom: 4 }}
                >
                  <div style={{ width: "48px" }}>
                    <ImgBox
                      className="d-flex align-items-center"
                      imgId={client?.customer?.logoFileId}
                      width={48}
                      icon={FaStore}
                      imgBg="secondary"
                      resizeMode="contain"
                      style={{ width: 48, height: "100%" }}
                    />
                  </div>
                  <div
                    className="d-flex justify-content-between w-100 align-items-center"
                    style={{ paddingLeft: 3, paddingRight: 3 }}
                  >
                    <div className="lh-sm">
                      <div className="fw-semibold">
                        {client?.customer?.name}
                      </div>
                      <small className="text-muted font-monospace">
                        <Badge
                          style={{
                            backgroundColor: "hsla(45, 100.00%, 40.00%, 0.85)",
                          }}
                        >
                          {client?.customer?.id}
                        </Badge>
                      </small>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <p>
                  <Button
                    variant="link"
                    onClick={() => {
                      setCurrentCustomer(null);
                      setShowDrawer(true);
                    }}
                  >
                    {"Switch Customer"} <BsArrowLeftRight />
                  </Button>
                </p>
              </div>

              <div></div>
              <div
                className={
                  !!profiles && profiles?.length > 0
                    ? ""
                    : "d-flex align-items-center justify-content-center"
                }
                style={{
                  maxHeight: "300px",
                  minHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {!!profiles && profiles.length > 0 ? (
                  <Table striped hover>
                    <thead className="sticky-top">
                      <tr>
                        <th style={{ width: "30%" }}>Profile name</th>
                        <th>Program name</th>
                        <th className="d-flex justify-content-center">
                          Profile Variants
                        </th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {profiles.map((item) => (
                        <tr
                          key={item.id}
                          onClick={() => setSelectedProfile(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <td
                            style={
                              selectedProfile.id === item.id
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            {item.name}
                          </td>
                          <td
                            style={
                              selectedProfile.id === item.id
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            {item.programName}
                          </td>
                          <td
                            style={
                              selectedProfile.id === item.id
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            <div className="d-flex justify-content-center">
                              <ProfileVariantsCell
                                profileVariants={item.profileVariants}
                              />
                            </div>
                          </td>

                          <td
                            style={
                              selectedProfile.id === item.id
                                ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                                : {}
                            }
                          >
                            <div className="d-flex">
                              {item.accessType === "public" && (
                                <Badge
                                  style={{ marginRight: 3 }}
                                  bg="primary"
                                  className="font-monospace floating-label-badge"
                                >
                                  public
                                </Badge>
                              )}

                              {item.default === true && (
                                <Badge
                                  style={{ marginRight: 3 }}
                                  bg="secondary"
                                  className="font-monospace floating-label-badge"
                                >
                                  default
                                </Badge>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div>{"No profiles for this Customer"}</div>
                )}
              </div>
            </WithLoaderAndError>
          ) : (
            <div
              style={{ minHeight: 325 }}
              className="d-flex align-items-center justify-content-center h-100"
            >
              {"Select a customer before you can create a Variant"}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          className="d-flex justify-content-center"
          style={{
            paddingLeft: 0,
            paddingRight: 0,
          }}
        >
          <div style={{ width: "30%" }}>
            <Dropdown
              as={ButtonGroup}
              style={{ position: "relative", right: 35 }}
            >
              <Dropdown.Toggle variant="link" id="dropdown-basic">
                Other options
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  maxWidth: 200,
                  backgroundColor: "#00ac56",
                }}
              >
                <Dropdown.Item className="dropdown-item">
                  <AddVariantManually
                    setShowProfileModal={setShowProfileModal}
                    showDrawer={showDrawer}
                    setShowDrawer={setShowDrawer}
                    currentCustomer={currentCustomer}
                    setCurrentCustomer={setCurrentCustomer}
                    setShowMultiCustomer={setShowMultiCustomer}
                    showMultiCustomerDrawer={showMultiCustomerDrawer}
                    linkToOrder={true}
                  />
                </Dropdown.Item>
                <Dropdown.Item>
                  <AddVariantManually
                    setShowProfileModal={setShowProfileModal}
                    showDrawer={showDrawer}
                    setShowDrawer={setShowDrawer}
                    currentCustomer={currentCustomer}
                    setCurrentCustomer={setCurrentCustomer}
                    setShowMultiCustomer={setShowMultiCustomer}
                    showMultiCustomerDrawer={showMultiCustomerDrawer}
                    linkToOrder={false}
                  />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div>
            <PaginationButtons
              pageSize={PAGE_SIZE}
              page={page}
              setPage={setPage}
              isLoading={loading}
            />
          </div>
          <div
            style={{ width: "30%", marginRight: -20 }}
            className="d-flex justify-content-end"
          >
            <Button
              variant="secondary"
              onClick={() => {
                setSelectedProfile({});
                setShowProfileModal(false);
              }}
              style={{ marginRight: 3 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                createVariantByProfile();
                setShowProfileModal(false);
              }}
              disabled={Object.keys(selectedProfile).length === 0}
              variant="success"
            >
              Create from profile
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <SelectCustomerDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        onSelect={setClient}
        afterSelect={handleProfiles}
        closeButton
      />
    </>
  );
};

export default AddVariantByProfile;
