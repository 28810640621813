import { Button, ListGroup, Modal } from "react-bootstrap";
import useParameters from "../../../hooks/useParameters";
import SearchBox from "../../ui/SearchBox";
import DocumentParameterListGroupItem from "./DocumentParameterListGroupItem";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import PaginationFromHeader from "../../ui/PaginationFromHeader";

const AddDocumentParameter = ({ document, documentType, refresh }) => {
  const {
    handleClick,
    parameterDefinitions,
    fetchError,
    isValidating,
    setQuery,
    query,
    page,
    setPage,
    PAGE_SIZE,
    show,
    setShow,
    selectedParameters,
    headers,
    onCancel,
    updateDocument,
  } = useParameters({
    document: document,
    documentType: documentType,
    refresh: refresh,
  });
  return (
    <>
      <Button variant="success" onClick={() => setShow(true)}>
        Add parameter
      </Button>
      <Modal show={show} size="lg" centered onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Add parameter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchBox
            className="w-100"
            placeholder="Search parameters"
            query={query}
            setQuery={setQuery}
            maxWidth={600}
            size="md"
            clearSearchStyle={{
              position: "absolute",
              zIndex: "999",
              top: "7px",
              left: "575px",
              color: "red",
            }}
          />
          <WithLoaderAndError
            isLoading={!parameterDefinitions && !fetchError && isValidating}
            error={fetchError}
          >
            <ListGroup
              className="scrollbar-thin"
              style={{
                "--bs-list-group-active-bg": "rgba(0, 153, 77, 0.8)",
                "--bs-list-group-active-border-color": "rgba(0, 153, 77, 0.8)",
                maxHeight: 350,
                marginTop: 10,
                overflowY: "auto",
                paddingTop: 10,
                cursor: "pointer",
                minHeight: 350,
              }}
            >
              {!!parameterDefinitions && parameterDefinitions.length > 0 ? (
                parameterDefinitions.map((item) => {
                  const isSelected =
                    !!selectedParameters &&
                    selectedParameters.length > 0 &&
                    selectedParameters.some((p) => p.name === item.name);
                  return (
                    <DocumentParameterListGroupItem
                      key={`key-${item.name}`}
                      name={item.name}
                      type={item.type}
                      active={isSelected}
                      onClick={() => handleClick(item)}
                    />
                  );
                })
              ) : (
                <span
                  className="d-flex align-items-center justify-content-center"
                  style={{ minHeight: 300 }}
                >
                  No parameter found
                </span>
              )}
            </ListGroup>
            {!!headers?.link ? (
              <div
                className="d-flex justify-content-center"
                style={{ paddingTop: 10 }}
              >
                <PaginationFromHeader
                  current={page}
                  linkStr={headers.link}
                  setPage={setPage}
                  showButtons
                  pageSize={PAGE_SIZE}
                />
              </div>
            ) : null}
          </WithLoaderAndError>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="success"
            disabled={selectedParameters?.length < 1}
            onClick={updateDocument}
          >
            Use selected
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AddDocumentParameter;
