import { ListGroup, ListGroupItem } from "react-bootstrap";
import TreatmentParameterListItem from "./TreatmentParameterListItem";

const TreatmentParameterListGroup = ({ parameters, zoomIn }) => {
  return (
    <ListGroup
      className="w-100"
      style={
        zoomIn
          ? {}
          : {
              maxHeight: 400,
              overflowY: "auto",
              scrollbar: "thin",
            }
      }
    >
      {!!parameters && parameters.length > 0 ? (
        parameters?.map((parameter) => (
          <TreatmentParameterListItem
            key={parameter.id}
            id={parameter.id}
            valueType={parameter.valueType}
            valueOptions={parameter.valueOptions}
            name={parameter.name}
            inheritance={parameter.inheritance}
            editableByCustomer={parameter.editableByCustomer}
            sequence={parameter.sequence}
            description={parameter.description}
            active={parameter.active}
            displayName={parameter.displayName}
          />
        ))
      ) : (
        <ListGroupItem className="d-flex justify-content-center">
          <span style={{ height: 200 }} className="d-flex align-items-center">
            No parameters added to this treatment
          </span>
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

export default TreatmentParameterListGroup;
