import { useEffect, useState } from "react";
import { Button, Card, Col, Form, ListGroupItem, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  BsCalendarCheckFill,
  BsCheck2,
  BsPersonCircle,
  BsX,
} from "react-icons/bs";
import fetcher from "../../../../utils/fetcher";
import { config } from "../../../../config";
import { useParams } from "react-router-dom";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import useStorage from "../../../../context/StorageContext";
import { useNotifications } from "../../../../context/NotificationsContext";
import { formatDateTime } from "../../../../utils/formatDate";

const VariantApproval = ({
  approval,
  customerApproval,
  refreshVariants,
  refreshHistory,
}) => {
  const { variantId, customerId } = useParams();
  const { client } = useStorage();
  const { setToasts } = useNotifications();

  const [showModal, setShowModal] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [error, setError] = useState(null);

  const { register, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      approved: approval?.approved,
      description: approval?.description,
    },
  });

  const handleOnCancel = () => {
    reset({ approved: approval?.approved, description: approval?.description });
    setShowModal(false);
  };

  const handleModal = () => {
    setShowModal(false);
    if (customerApproval?.approved === true && approval?.approved === false) {
      setShowQuestion(true);
      setValue("approved", true);
    } else {
      setValue("approved", !approval?.approved);
      onSubmit({
        approved: watch("approved"),
        description: watch("description"),
      });
    }
  };

  const resetCustomerApproval = () => {
    onSubmit({
      approved: watch("approved"),
      description: watch("description"),
      resetCustomerApproval: true,
    });
  };

  const onSubmit = (data) => {
    fetcher({
      url: `${config.api.phoenix}/variants/${variantId}/approval`,
      method: "PUT",
      payload: data,
      customerId: customerId,
      clientId: client?.id,
    })
      .then(() => {
        refreshVariants();
        refreshHistory();
        reset();
      })
      .catch((err) => {
        setError(err);
        reset();
      });

    setShowQuestion(false);
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to approve or cancel variant",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Card style={{ width: "80%" }} className="h-100 fade-in-grow-up">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Header className="d-flex justify-content-center">
          <Card.Title>Approval</Card.Title>
        </Card.Header>

        <Card.Body className="w-100 d-flex">
          <Col className=" w-100">
            <div className="d-flex w-100 ">
              <Col className="mb-3 d-flex">
                <ListGroupItem className="hover-parent match-floating-input">
                  <Col className="inner flex-grow-1 flex-shrink-0">
                    {approval.timestamp && approval?.timestamp !== null ? (
                      <>
                        <BsCalendarCheckFill className="text-success-tint-25 me-1" />
                        <span>{formatDateTime(approval?.timestamp)}</span>
                      </>
                    ) : null}
                  </Col>
                </ListGroupItem>
              </Col>
              <Col className="mb-3 d-flex">
                <ListGroupItem className="hover-parent match-floating-input">
                  <Col className="inner flex-grow-1 flex-shrink-0">
                    {approval.userName ? (
                      <>
                        <BsPersonCircle /> {approval?.userName}
                      </>
                    ) : null}
                  </Col>
                </ListGroupItem>
              </Col>
            </div>
            <div className="d-flex">
              <ListGroupItem className="hover-parent match-floating-input">
                <Col className="inner flex-grow-1 flex-shrink-0">
                  {approval.description ? approval?.description : null}
                </Col>
              </ListGroupItem>
            </div>
          </Col>
        </Card.Body>
        <Card.Footer>
          <div className="text-end">
            <Button
              variant="secondary"
              onClick={() => {
                setValue("description", "");
                setShowModal(true);
              }}
            >
              {approval?.approved === true ? "Cancel Approve" : "Approve"}
            </Button>
          </div>
        </Card.Footer>
        <Modal size="lg" show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            {approval?.approved === false ? (
              <Modal.Title>Approving parameters</Modal.Title>
            ) : (
              <Modal.Title>Cancelling approval</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            {approval?.approved === false ? (
              <span>
                Are you sure you want to approve current parameters of this
                Variant?
              </span>
            ) : (
              <span>Are you sure you want to cancel approval?</span>
            )}

            <br />
            <br />

            <Form.Control
              placeholder="Provide reason (optional)"
              {...register("description")}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleOnCancel}>
              <BsX className="btn-icon me-1" /> Cancel
            </Button>
            <Button variant="success" onClick={handleModal}>
              <BsCheck2 className="btn-icon me-1" /> Save changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={showQuestion}
          onHide={() => setShowQuestion(false)}
        >
          <Modal.Header>
            <Modal.Title>Remove Customer approval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Do you want to remove Customer approval as well?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={resetCustomerApproval}>
              Yes
            </Button>
            <Button
              variant="primary"
              type="submit"
              onClick={handleSubmit(onSubmit)}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </Form>
    </Card>
  );
};
//DM - 2021 - Klant werft Klant
//eDM - RAY-BAN - EVENT-Copy
export default VariantApproval;
