import { BsCaretDown, BsFileEarmarkTextFill } from "react-icons/bs";
import { config } from "../../config";
import useListPage from "../../hooks/useListPage";
import ListPage from "../ui/ListPage";
import { Card, Dropdown } from "react-bootstrap";
import ImgBox from "../ui/ImgBox";
import { Link } from "react-router-dom";
import AddDocumentButton from "./Documents/AddDocumentButton";

const TypeSelect = ({ type, setType }) => {
  return (
    <Dropdown bsPrefix="dropdown">
      <Dropdown.Toggle variant="outline-secondary" size="sm" title="Type">
        {type} <BsCaretDown />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {["DM", "EDM"].map((option) => (
          <Dropdown.Item
            as="span"
            className="cursor-pointer"
            onClick={() => setType(option)}
            active={type === option}
            key={option}
          >
            {option}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const GridItem = ({ item, itemLink }) => {
  return (
    <Link className="text-decoration-none hover-parent" to={itemLink}>
      <Card className="border h-100 justify-content-between documents-hover-border  fade-in-grow-up delay">
        <Card.Body className="p-0">
          <div className="position-relative">
            <ImgBox
              imgId={item?.thumbnailFileId}
              icon={BsFileEarmarkTextFill}
              className="w-100 hover-zoom"
              params={{
                Format: "jpg",
                ResizeMode: "Contain",
              }}
            />
          </div>
        </Card.Body>
        <Card.Footer
          className="p-2 border-top-0 bg-px-dark-tint-85"
          style={{ flexBasis: "100%" }}
        >
          <Card.Title
            as="h6"
            className="mb-n1 pb-1 text-truncate fs-90 text-center"
          >
            {item?.name}
          </Card.Title>
          <span className="text-secondary opacity-75 fs-75 d-flex justify-content-center">
            {item?.code}
          </span>
        </Card.Footer>
      </Card>
    </Link>
  );
};

const Documents = () => {
  const [state, setValue] = useListPage("documents");
  const { type = "DM" } = state;

  const setType = (val) => setValue({ type: val, page: 1 });

  const getItemLink = (item) => {
    return type === "DM"
      ? `/documents/dm/${item.id}`
      : type === "EDM"
      ? `/documents/edm/${item.id}`
      : "";
  };

  return (
    <ListPage
      baseUrl={
        type === "DM"
          ? `${config.api.dm}/documents`
          : type === "EDM"
          ? `${config.api.edm}/documents`
          : ""
      }
      itemLabel="documents"
      itemPath="documents"
      orderOptions={["id", "name"]}
      defaultLayout="grid"
      headerItems={<TypeSelect type={type} setType={setType} />}
      searchProps={{ placeholder: "ID, name, code, des..." }}
      newItemBtn={<AddDocumentButton initialType={type} />}
      menuType="documents"
      colorClass="documents"
      storageKey="documents"
      gridItem={GridItem}
      getItemLink={getItemLink}
      type={type}
      tableItems={{
        thumbnailFileId: {
          cellType: "thumbnail",
          cellPadding: "0px",
          cellWidth: 56,
          component: ({ value }) => (
            <ImgBox
              imgId={value}
              width={54}
              height={32}
              ratio={false}
              padding={2}
              className="img-box-aspect-ratio-65"
              icon={BsFileEarmarkTextFill}
              imgBg="rgba(255,255,255,.7)"
              imgColor="rgba(var(--bs-px-dark-rgb), .2)"
              resizeMode="contain"
            />
          ),
        },
        id: {},
        code: {
          component: ({ value }) => {
            if (!value) return null;

            const truncated =
              value.length > 15 ? value.slice(0, 15) + "…" : value;

            return (
              <div className="text-center font-monospace text-secondary-tint-25 text-nowrap fade-in delay">
                {truncated}
              </div>
            );
          },
        },
        name: { cellWidth: 50 },
        description: { cellWidth: 150 },
      }}
    />
  );
};

export default Documents;
