import React from "react";
import { BsQuestionDiamond } from "react-icons/bs";
import {
  RiCheckboxIndeterminateLine,
  RiCodeBoxLine,
  RiImageLine,
  RiTextBlock,
} from "react-icons/ri";

export const typeIcons = {
  string: RiTextBlock,
  bit: RiCheckboxIndeterminateLine,
  image: RiImageLine,
  xnip: RiCodeBoxLine,
};

const TreatmentParameterTypeIcon = ({ type, ...rest }) => {
  const Icon = typeIcons?.[type] || BsQuestionDiamond;
  return (
    <Icon
      val={type}
      title={type}
      className={!typeIcons?.[type] && "opacity-50"}
      {...rest}
    />
  );
};

export default TreatmentParameterTypeIcon;
