import { useEffect, useState } from "react";
import { useNotifications } from "../../../context/NotificationsContext";
import useStorage from "../../../context/StorageContext";
import { useNavigate, useParams } from "react-router-dom";
import { config } from "../../../config";
import fetcher from "../../../utils/fetcher";
import createDetailedErrorMessage from "../../../utils/detailMessageError";

const CopyDocumentWrap = ({ document, component: Component }) => {
  const { documentType } = useParams();
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [error, setError] = useState(null);

  const [show, setShow] = useState(false);

  const onCancel = () => {
    setShow(false);
  };

  const navigate = useNavigate();

  const handleCopy = () => {
    const dataUrl =
      documentType === "dm"
        ? `${config.api.dm}/documents`
        : documentType === "edm"
        ? `${config.api.edm}/documents`
        : "";

    fetcher({
      url: dataUrl,
      method: "POST",
      payload:
        documentType === "dm"
          ? {
              name: `${document.name}-Copy`,
              code: document.code,
              description: document.description,
              active: document.active,
              thumbnailFileId: document.thumbnailFileId,
              parameters: document.parameters,
              externalDocumentId: document.externalDocumentId,
            }
          : documentType === "edm"
          ? {
              name: `${document.name}-Copy`,
              code: document.code,
              description: document.description,
              active: document.active,
              thumbnailFileId: document.thumbnailFileId,
              parameters: document.parameters,
              htmlBody: document.htmlBody,
              textBody: document.textBody,
              documentType: document.documentType,
            }
          : {},
      clientId: client?.id,
    })
      .then((res) => {
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            text: <span>{`Successfully copied Document`}</span>,
          },
        ]);
        navigate(`../documents/${res.data.id}`);
        setShow(false);
      })
      .catch((err) => {
        setError(err);
        setShow(false);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to copy Document",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Component
      setOpen={() => setShow(true)}
      show={show}
      onCancel={onCancel}
      handleCopy={handleCopy}
    />
  );
};

export default CopyDocumentWrap;
