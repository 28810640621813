import React from "react";
import {
  BsEnvelopeAtFill,
  BsEnvelopePaperHeartFill,
  BsPhoneVibrateFill,
} from "react-icons/bs";
import { FaEnvelopesBulk } from "react-icons/fa6";

export const typeIcons = {
  dm: BsEnvelopePaperHeartFill,
  edm: BsEnvelopeAtFill,
  sms: BsPhoneVibrateFill,
};

const ProductionTypeIcon = ({ type, ...rest }) => {
  const Icon = typeIcons?.[type] || FaEnvelopesBulk;
  return <Icon val={type} {...rest} />;
};

export default ProductionTypeIcon;
