import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
import BundleWrap from "./ProductionHelper/BundleWrap";

const CreateBundle = ({
  selectedProductionIds,
  setSelectedProductionId,
  setSelectedProductionIds,
  selectMode,
  setSelectMode,
}) => {
  return (
    <BundleWrap
      component={({ handleCreateBundle, show, setShow, name, setName }) => {
        return (
          <>
            {selectMode ? (
              <>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    setSelectMode(false);
                    setSelectedProductionIds([]);
                  }}
                >
                  Cancel
                </Button>
                <div>
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => setShow(true)}
                    disabled={selectedProductionIds?.length < 1}
                  >
                    Use selection
                  </Button>
                </div>
              </>
            ) : (
              <Button
                size="sm"
                variant="success"
                onClick={() => {
                  setSelectedProductionId(null);
                  setSelectMode(true);
                }}
              >
                Make selection
              </Button>
            )}
            <Modal show={show} onHide={() => setShow(false)} centered size="lg">
              <Modal.Header>
                <Modal.Title>Create bundle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3 d-flex justify-content-center">
                  <span>Please name the new bundle</span>
                </div>
                <FloatingLabel controlId="name" label="Name" className="mb-3">
                  <Form.Control
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </FloatingLabel>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShow(false);
                    setSelectMode(false);
                    setSelectedProductionIds([]);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={name === ""}
                  variant="success"
                  onClick={() =>
                    handleCreateBundle(name, selectedProductionIds)
                  }
                >
                  Create bundle
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      }}
    />
  );
};

export default CreateBundle;
