import DocumentWrap from "../Wraps/DocumentWrap";
import Layout from "../../layout/Layout";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import {
  Badge,
  Button,
  Card,
  FloatingLabel,
  Form,
  Modal,
} from "react-bootstrap";
import {
  BsCheck2,
  BsFileEarmarkText,
  BsPencil,
  BsTrash,
  BsX,
} from "react-icons/bs";
import ImgFileIdInput from "../../ui/ImgFileIdInput";
import CopyToClientButton from "./CopyToClientButton";
import CopyDocumentButton from "./CopyDocumentButton";
import DeleteDocumentButton from "./DeleteDocumentButton";
import DocumentParameterView from "./DocumentParameterView";
import { formatDateTime } from "../../../utils/formatDate";

const DocumentDetailPage = () => {
  return (
    <DocumentWrap
      component={({
        document,
        fetchError,
        isValidating,
        editable,
        handleSubmit,
        onSubmit,
        errors,
        isSubmitted,
        setEditable,
        onCancel,
        isDirty,
        isBusy,
        documentType,
        removeImage,
        handleOnFileUpload,
        formProps,
        defaultValues,
        hover,
        setHover,
        showRemoveModal,
        setShowRemoveModal,
        zoomIn,
        setZoomIn,
        refreshDM,
      }) => {
        const createdTime = document?.created?.timeStamp
          ? formatDateTime(document.created.timeStamp)
          : "";
        const createdBy = document?.created?.userName || "";
        const updatedTime = document?.updated?.timeStamp
          ? formatDateTime(document.updated.timeStamp)
          : "";
        const updatedBy = document?.updated?.userName || "";

        return (
          <Layout pageTitle={document?.name || "..."}>
            <WithLoaderAndError
              isLoading={!document && !fetchError && isValidating}
              error={fetchError}
              fullpage
            >
              {!!document && (
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Card className="card-main border-0">
                    <Card.Header className="d-flex justify-content-end align-items-center gap-2">
                      <CopyToClientButton editable={editable} />
                      <CopyDocumentButton
                        document={document}
                        editable={editable}
                      />
                      <DeleteDocumentButton editable={editable} />
                    </Card.Header>
                    <Card.Body>
                      <div className="d-flex w-100">
                        <div>
                          <div
                            className="img-wrap position-relative"
                            style={{ width: 300, padding: 10 }}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                          >
                            <ImgFileIdInput
                              className="fade-in delay img-box-aspect-ratio-65 mb-3"
                              id={"thumbnailFileId"}
                              formProps={formProps}
                              imageContent={document}
                              defaultValues={defaultValues}
                              resizeMode="contain"
                              imgBg="white"
                              icon={BsFileEarmarkText}
                              hasWhitebox
                              padding={4}
                              handleOnFileUpload={(file) =>
                                handleOnFileUpload(file)
                              }
                            />
                            {!!document?.thumbnailFileId && hover && (
                              <Badge
                                pill
                                bg="danger-tint-10"
                                className="position-absolute top-0 "
                                style={{
                                  paddingLeft: ".4em",
                                  paddingRight: ".4em",
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: 0,
                                }}
                                onClick={() => setShowRemoveModal(true)}
                              >
                                <BsTrash />
                              </Badge>
                            )}
                          </div>
                          <div className="d-flex justify-content-center">
                            <Form.Check
                              type="switch"
                              label="Active"
                              onChange={() =>
                                formProps?.setValue(
                                  "active",
                                  !formProps?.watch("active")
                                )
                              }
                              checked={formProps?.watch("active") === true}
                              readOnly={!editable}
                              disabled={!editable}
                            />
                          </div>
                        </div>
                        <Card className="w-100 mb-3 bg-dark bg-opacity-10">
                          <Card.Body>
                            <Form.Group className="mb-3">
                              <div>
                                <FloatingLabel
                                  controlId="name"
                                  label="Name*"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    placeholder=" *"
                                    {...formProps?.register("name", {
                                      required: true,
                                    })}
                                    isInvalid={errors?.name}
                                    isValid={isSubmitted && errors?.name}
                                    readOnly={!editable}
                                    disabled={!editable}
                                  />
                                </FloatingLabel>
                              </div>
                              <div>
                                <FloatingLabel
                                  controlId="code"
                                  label="Code*"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    placeholder=" *"
                                    {...formProps?.register("code", {
                                      required: true,
                                    })}
                                    isInvalid={errors?.name}
                                    isValid={isSubmitted && errors?.name}
                                    readOnly={!editable}
                                    disabled={!editable}
                                  />
                                </FloatingLabel>
                              </div>
                              <div>
                                <FloatingLabel
                                  controlId="documentType"
                                  label="Document type"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    {...formProps?.register("documentType")}
                                    readOnly
                                    disabled
                                    isInvalid={errors?.name}
                                    isValid={isSubmitted && errors?.name}
                                  />
                                </FloatingLabel>
                              </div>

                              <div>
                                {/* Created info */}
                                <div className="d-flex justify-content-between mb-3">
                                  <FloatingLabel
                                    controlId="createdTimestamp"
                                    label="Created On"
                                    className="me-3 flex-fill"
                                  >
                                    <Form.Control
                                      placeholder=""
                                      value={createdTime}
                                      readOnly
                                      disabled
                                    />
                                  </FloatingLabel>

                                  <FloatingLabel
                                    controlId="createdBy"
                                    label="Created By"
                                    className="flex-fill"
                                  >
                                    <Form.Control
                                      placeholder=""
                                      value={createdBy}
                                      readOnly
                                      disabled
                                    />
                                  </FloatingLabel>
                                </div>

                                {/* Updated info */}
                                <div className="d-flex justify-content-between mb-3">
                                  <FloatingLabel
                                    controlId="updatedTimestamp"
                                    label="Updated On"
                                    className="me-3 flex-fill"
                                  >
                                    <Form.Control
                                      placeholder=""
                                      value={updatedTime}
                                      readOnly
                                      disabled
                                    />
                                  </FloatingLabel>

                                  <FloatingLabel
                                    controlId="updatedBy"
                                    label="Updated By"
                                    className="flex-fill"
                                  >
                                    <Form.Control
                                      placeholder=""
                                      value={updatedBy}
                                      readOnly
                                      disabled
                                    />
                                  </FloatingLabel>
                                </div>
                              </div>
                            </Form.Group>
                          </Card.Body>
                          <div style={{ padding: 10 }}>
                            <FloatingLabel
                              label="Description"
                              controlId="description"
                            >
                              <Form.Control
                                placeholder="Description"
                                as="textarea"
                                style={{ height: "207px" }}
                                {...formProps?.register("description")}
                                readOnly={!editable}
                                disabled={!editable}
                              />
                            </FloatingLabel>
                          </div>
                          <Card.Footer className="sticky-bottom d-flex justify-content-end align-items-center">
                            {editable ? (
                              <>
                                <Button
                                  variant="secondary"
                                  disabled={isBusy}
                                  onClick={onCancel}
                                  className="me-2 min-w-8"
                                >
                                  <BsX className="btn-icon me-1" />
                                  Cancel
                                </Button>
                                <Button
                                  variant="success"
                                  disabled={isBusy || !isDirty}
                                  type="submit"
                                  className="min-w-8"
                                >
                                  <BsCheck2 className="btn-icon me-1" /> Save
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  variant="success"
                                  disabled={isBusy}
                                  onClick={() => setEditable(true)}
                                  className="min-w-8"
                                >
                                  <BsPencil className="btn-icon me-1" /> Edit
                                </Button>
                              </>
                            )}
                          </Card.Footer>
                        </Card>
                      </div>
                    </Card.Body>
                  </Card>
                  <div className="d-flex justify-content-center">
                    <DocumentParameterView
                      parameters={document?.parameters}
                      document={document}
                      documentType={documentType}
                      zoomIn={zoomIn}
                      setZoomIn={setZoomIn}
                      refresh={refreshDM}
                    />
                  </div>
                </Form>
              )}
              <Modal
                centered
                size="lg"
                show={showRemoveModal}
                onHide={() => setShowRemoveModal(false)}
              >
                <Modal.Header>
                  <h4>{"Remove image"}</h4>
                </Modal.Header>
                <Modal.Body className="d-flex justify-content-center">{`Are you sure you want to remove this thumbnail?`}</Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="outline-secondary"
                    type="button"
                    onClick={() => setShowRemoveModal(false)}
                  >
                    <BsX className="btn-icon me-1" /> No, cancel
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => {
                      removeImage();
                      setShowRemoveModal(false);
                    }}
                  >
                    Yes, delete
                  </Button>
                </Modal.Footer>
              </Modal>
            </WithLoaderAndError>
          </Layout>
        );
      }}
    />
  );
};

export default DocumentDetailPage;
