import React, { useEffect, useState } from "react";
import OutletRow from "../../../layout/OutletRow";
import { Button, Card, Col, ListGroup, Row, Stack } from "react-bootstrap";
import WithLoaderAndError from "../../../ui/WithLoaderAndError";
import useFetch from "../../../../hooks/useFetch";
import useTotals from "../../../../hooks/useTotals";
import useListPage from "../../../../hooks/useListPage";
import { config } from "../../../../config";
import CountButton from "../../../ui/CountButton";
import { BsFunnelFill, BsX } from "react-icons/bs";
import { useLayout } from "../../../../context/LayoutContext";
import SearchBox from "../../../ui/SearchBox";
import NoResults from "../../../ui/NoResults";
import ProductionsListItem from "./ProductionsListItem";
import CreateBundle from "../CreateBundle";
// import useProductions from "../../../../hooks/useProductions";

const Productions = () => {
  const [state, setValue] = useListPage();
  const { showSidebar, setShowSidebar } = useLayout(true);
  const itemLabel = "production";

  const [selectMode, setSelectMode] = useState(false);

  const [selectedProductionId, setSelectedProductionId] = useState(null);
  const [selectedProductionIds, setSelectedProductionIds] = useState([]);

  const handleSelectProduction = (productionId) => {
    if (!!productionId && selectedProductionId === productionId) {
      setSelectedProductionId(null);
    } else {
      setSelectedProductionId(productionId);
    }
  };

  const handleSelectProductions = (productionId) => {
    if (
      !!productionId &&
      selectedProductionIds.some((id) => id === productionId)
    ) {
      setSelectedProductionIds(
        selectedProductionIds.filter((id) => id !== productionId)
      );
    } else {
      setSelectedProductionIds([...selectedProductionIds, productionId]);
    }
  };

  // Initialize state if doesn't exist in context
  useEffect(() => {
    if (!state?.page) {
      setValue({
        page: 1,
        pageSize: 30,
        productionType: "",
        productionStatus: "",
        productionIds: [],
        name: "", // = query
        // extrainfo: false, // add if present
      });
    }
  }, [state?.page, setValue]);

  const { page, pageSize, name } = state;

  //   const setPage = (val) => setValue({ page: val });
  //   const setPageSize = (val) => setValue({ pageSize: val, page: 1 });
  const setName = (val) => setValue({ name: val, page: 1 });

  const {
    data: items,
    headers,
    error,
  } = useFetch({
    baseURL: `${config.api.phoenix}/productions/search`,
    params: { ...state },
  });

  const { total, isExact } = useTotals({
    linkStr: headers?.link,
    page,
    pageSize,
    itemsCount: items?.length,
  });

  // Import logic from hook
  // const {
  //   showSidebar,
  //   setShowSidebar,
  //   page,
  //   items,
  //   error,
  //   total,
  //   isExact,
  //   name,
  //   setName,
  //   selectMode,
  //   setSelectMode,
  //   selectedProductionIds,
  //   setSelectedProductionIds,
  //   handleSelectProduction,
  // } = useProductions();

  return (
    <OutletRow filterCol={<Card>filters</Card>}>
      <Card className="border-0 card-main">
        <Card.Header className="px-2 bg-gray-200 toolbar-top">
          <Stack direction="horizontal" gap={2}>
            <CountButton
              // count: number of active filter
              // onClear: function to unset active filters
              onClick={() => setShowSidebar((curr) => !curr)}
              active={showSidebar}
            >
              <BsFunnelFill />
            </CountButton>
            <div className="flex-grow-1"></div>
            <SearchBox
              query={state?.name ?? ""}
              setQuery={setName}
              size="sm"
              className="w-auto"
              placeholder="Search by name"
            />
            <CreateBundle
              selectMode={selectMode}
              setSelectMode={setSelectMode}
              setSelectedProductionId={setSelectedProductionId}
              selectedProductionIds={selectedProductionIds}
              setSelectedProductionIds={setSelectedProductionIds}
            />
          </Stack>
        </Card.Header>
        <Card.Body className="d-flex align-items-center h-100 p-0 scrollbar-thin">
          <WithLoaderAndError
            isLoading={(!items && !error) || !page}
            error={error}
          >
            <ListGroup variant="flush" className="h-100 w-100">
              {items?.length ? (
                items?.map(
                  ({
                    id,
                    name,
                    productionType,
                    status,
                    productionItemCount,
                    validatedProductionItemCount,
                  }) => (
                    <ProductionsListItem
                      key={id}
                      id={id}
                      name={name}
                      productionType={productionType}
                      status={status}
                      productionItemCount={productionItemCount}
                      validatedProductionItemCount={
                        validatedProductionItemCount
                      }
                      selectMode={selectMode}
                      selectedProductionId={selectedProductionId}
                      selectedProductionIds={selectedProductionIds}
                      handleSelectProductions={handleSelectProductions}
                      handleSelectProduction={handleSelectProduction}
                    />
                  )
                )
              ) : name ? (
                <NoResults>
                  <div className="text-muted mt-2">
                    <div className="mb-3">
                      No {itemLabel}s match{" "}
                      <span className="text-teal font-monospace">"{name}"</span>
                    </div>
                    {name ? (
                      <Button
                        variant="outline-primary"
                        onClick={() => setName("")}
                      >
                        <BsX /> Clear search
                      </Button>
                    ) : null}
                  </div>
                </NoResults>
              ) : (
                <NoResults>
                  <div className="text-muted mt-2">
                    <div className="mb-3">No {itemLabel}s found</div>
                  </div>
                </NoResults>
              )}
            </ListGroup>
          </WithLoaderAndError>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col>
              {selectMode && selectedProductionIds.length > 0
                ? `${selectedProductionIds.length} selected`
                : null}
            </Col>
            <Col className="text-center">{/* Pagination */}</Col>
            <Col className="text-end">
              {total ? (
                <div className="small">
                  {total}
                  {isExact ? "" : "+"} result{total % 10 === 1 ? "" : "s"}
                </div>
              ) : null}
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </OutletRow>
  );
};

export default Productions;
