import React from "react";
import PropTypes from "prop-types";
import CountBadge from "../../custom/CountBadge";
import { RiListCheck } from "react-icons/ri";
import {
  Col,
  ListGroupItem,
  Row,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { MdOutlineEditNote } from "react-icons/md";
import { BsDiagram3 } from "react-icons/bs";
import TreatmentParameterTypeIcon from "./TreatmentParameterTypeIcon";

const TreatmentParameterListItem = ({
  id,
  valueType,
  valueOptions,
  name,
  inheritance,
  editableByCustomer,
  sequence,
  description,
  active,
  displayName,
}) => {
  return (
    <ListGroupItem
      variant={active ? null : "danger"}
      title={!active ? "Inactive" : null}
      className="flex-nowrap list-group-item-action"
      style={{ cursor: "pointer" }}
    >
      <Row className="flex-nowrap">
        <Col xs="auto" style={{ flex: "1 1 10px" }}>
          <span className="font-monospace text-muted">{sequence}</span>
        </Col>
        <Col
          xs="auto"
          className="overflow-hidden text-truncate"
          style={{ flex: "1 1 200px", minWidth: "150px" }}
        >
          <TreatmentParameterTypeIcon type={valueType} />{" "}
          <span className="font-monospace small text-muted text-truncate">
            {name}
          </span>
        </Col>
        <Col
          xs="auto"
          className="overflow-hidden text-truncate d-none d-lg-block"
          style={{ flex: "1 1 150px", minWidth: "150px" }}
        >
          {displayName !== name && (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{displayName}</Tooltip>}
            >
              <div className="d-inline-block w-100 text-truncate">
                {displayName}
              </div>
            </OverlayTrigger>
          )}
        </Col>
        <Col
          xs="auto"
          className="overflow-hidden text-truncate d-none d-lg-block"
          style={{ flex: "1 1 150px", minWidth: "150px" }}
        >
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip>{description}</Tooltip>}
          >
            <span className="fs-85 text-muted text-truncate">
              {description}
            </span>
          </OverlayTrigger>
        </Col>

        <Col
          xs="auto"
          className="min-w-3 justify-content-center d-flex"
          style={{ flex: "1 1 100px", minWidth: "80px" }}
        >
          {valueOptions?.length ? (
            <span className="position-relative" title="Value options">
              <RiListCheck />
              <CountBadge
                count={valueOptions?.length}
                successBg="secondary-tint-25"
                className="position-absolute top-0 end-0 fs-55"
              />
            </span>
          ) : null}
        </Col>

        <Col
          xs="auto"
          className="min-w-3 d-flex justify-content-center"
          style={{ flex: "1 1 100px", minWidth: "80px" }}
        >
          {!editableByCustomer ? (
            <MdOutlineEditNote title="editableByCustomer" />
          ) : null}
        </Col>
        <Col
          xs="auto"
          className="overflow-hidden text-truncate d-none d-md-block"
          style={{ flex: "1 1 100px", minWidth: "80px" }}
        >
          {inheritance?.id ? (
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{inheritance.name}</Tooltip>}
            >
              <span>
                <BsDiagram3 title="Inheritance" />{" "}
                <span className="font-monospace fs-75 text-muted">
                  {inheritance.name}
                </span>
              </span>
            </OverlayTrigger>
          ) : null}
        </Col>
      </Row>
    </ListGroupItem>
  );
};

TreatmentParameterListItem.propTypes = {
  id: PropTypes.number.isRequired,
  valueType: PropTypes.string.isRequired,
  valueOptions: PropTypes.array,
  name: PropTypes.string.isRequired,
  inheritance: PropTypes.object,
  editableByCustomer: PropTypes.bool,
};

export default TreatmentParameterListItem;
