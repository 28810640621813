import {
  BsBagHeart,
  BsBoxes,
  BsBoxSeam,
  BsBriefcaseFill,
  BsBuilding,
  BsEasel,
  BsFileEarmarkRichtextFill,
  BsFunnel,
  BsListStars,
  BsPersonLinesFill,
  BsPostcard,
  BsWatch,
} from "react-icons/bs";
import { FaBuildingUser, FaStore, FaUsersViewfinder } from "react-icons/fa6";
import CycleIcon from "../components/icons/CycleIcon";
import { TbChartAreaLineFilled } from "react-icons/tb";
import { LiaFileInvoiceDollarSolid, LiaMailBulkSolid } from "react-icons/lia";
import { GrDocumentTest } from "react-icons/gr";
import { IoMegaphone } from "react-icons/io5";
import { TfiEnvelope, TfiPieChart } from "react-icons/tfi";
import { VscTable } from "react-icons/vsc";
import { ImCogs } from "react-icons/im";
import { PiUserCircle } from "react-icons/pi";
import { SlCalender } from "react-icons/sl";
import {
  SupplierTypeIconAdvertisingAgency,
  SupplierTypeIconBuyingGroup,
  SupplierTypeIconDistributor,
  SupplierTypeIconPrinter,
  SupplierTypeIconWapper,
} from "../components/icons/SupplierTypeIcon";

export const entities = {
  client: {
    icon: FaBuildingUser,
    description: "The brand or company we are working for (Essilor, OMP, etc.)",
    colorRgb: "143, 184, 11",
  },
  customer: {
    icon: FaStore,
    description:
      "A retailer, selling the **Client**'s products (optician, dealer, etc.)",
    colorRgb: "227, 149, 0",
  },
  consumer: {
    icon: BsPersonLinesFill,
    description:
      "A person or company buying products from the **Customer**, who will receive mail from us",
  },
  document: {
    icon: BsFileEarmarkRichtextFill,
    description:
      "A template (html or indesign) that is fully customizable, so has a lot of parameters to change the look and feel, content, etc.",
    colorRgb: "136, 49, 112",
  },
  treatment: {
    icon: GrDocumentTest,
    description:
      "A **Document** combined with a standard set of parameter values that determine the general look and feel of it, like background color, standard text blocks etc. In the **Treatment**, you can also specify that certain parameter values should be inherited from the **Client**, **Customer** or **Consumer** it is used for. This inheritance is key to Phoenix and enables you to use them for any **Customers** without changing values manually",
    colorRgb: "215, 73, 86",
  },
  program: {
    icon: ImCogs,
    description:
      "A **Campaign** or **Cycle**, that consists of one or more **Mailmoments**",
  },
  campaign: {
    icon: IoMegaphone,
    description:
      "A 'one-off' type of **Program**. Can have multiple **Mailmoments** if the campaign is recurring",
    colorRgb: "2, 155, 155", // reorder
  },
  cycle: {
    icon: CycleIcon,
    description:
      "A 'cycle' type of **Program**. Has multiple **Mailmoments** which represent points in time relative to (for instance) a last purchase",
    colorRgb: "0, 145, 84", // reorder
  },
  mailmoment: {
    icon: BsWatch,
    description:
      "A moment when a certain mailing is sent. A **Campaign** usually (but not by definition) has only one **Mailmoment**, a **Cycle** is likely to have more than one **Mailmoment**",
  },
  profile: {
    icon: BsListStars,
    description:
      "Defines default **Treatments** per **Mailmoment** for a certain **Program**. Can be applied to **Customers** to automatically create **Variants**",
  },
  segment: {
    icon: FaUsersViewfinder,
    description:
      "Enables you to send different versions of mailings at one **Mailmoment**. For instance based on age, gender or the type of car you're driving",
  },
  filter: {
    icon: BsFunnel,
    description: "",
  },
  shot: {
    icon: SlCalender,
    description: "",
  },
  variant: {
    icon: BsEasel,
    description:
      "Everything you need to create a **Mailpiece**, except the **Consumer** data. Unique set of settings for a **Treatment**, per **Customer**, per **Mailmoment**, per (optional) **Segment**. The combination of a **Customer**, **Mailmoment** and optional **Segment**, should always uniquely identify the **Variant**",
    colorRgb: "234, 116, 81",
  },
  product: {
    icon: BsBoxSeam,
    description: "",
  },
  package: {
    icon: BsBoxes,
    description: "",
  },
  order: {
    icon: BsBagHeart,
    description: "",
    colorRgb: "229, 104, 116", // reorder
  },
  invoice: {
    icon: LiaFileInvoiceDollarSolid,
    description: "",
  },
  production: {
    icon: LiaMailBulkSolid,
    description:
      "A list of **Consumers**, usually created by the data team, containing a selection for a specific **Program**, that should receive a mailing (DM or EDM) soon. Every **Consumer** in the list should point to a specific **Variant**. EDM-**Productions** are scheduled and sent by PhoenixEmail. DM-**Productions** are identified by a unique ProductionId and processed into PDFs in XMPie",
    colorRgb: "72, 112, 189",
  },
  mailpack: {
    icon: TfiEnvelope,
    description:
      "The actual printed result of a **Variant** being applied to a **Consumer**-**Batch**. This final merge is being done by XMPie",
  },
  mailpiece: {
    icon: BsPostcard,
    description:
      "Part of a **Mailpack**, on the level of a **Document**. Every **Mailpack** contains one or more **Mailpieces**",
  },
  report: {
    icon: TfiPieChart,
    description: "",
    colorRgb: "44, 150, 168",
  },
  actionList: {
    icon: VscTable,
    description: "",
    colorRgb: "26, 169, 161",
  },
  qsReport: {
    icon: TbChartAreaLineFilled,
    description: "",
    colorRgb: "17, 180, 212",
  },
  user: {
    icon: PiUserCircle,
  },
  fulfilmentPartner: {
    icon: BsBriefcaseFill,
  },
  supplier: {
    icon: BsBuilding,
  },
  advertisingAgency: {
    icon: SupplierTypeIconAdvertisingAgency,
  },
  buyingGroup: {
    icon: SupplierTypeIconBuyingGroup,
  },
  distributor: {
    icon: SupplierTypeIconDistributor,
  },
  printer: {
    icon: SupplierTypeIconPrinter,
  },
  wapper: {
    icon: SupplierTypeIconWapper,
  },
};

export const modules = {
  general: {
    colorRgb: "",
    children: ["client", "user"],
  },
  customer: {
    colorRgb: entities.customer.colorRgb,
    children: ["customer", "fulfilmentPartner", "supplier"],
  },
  programs: {
    colorRgb: "",
    children: [
      "program",
      "campaign",
      "cycle",
      "mailmoment",
      "profile",
      "filter",
      "shot",
      "package",
      "product",
    ],
  },
  creative: {
    colorRgb: entities.treatment.colorRgb,
    children: ["document", "treatment", "variant", "mailpack", "mailpiece"],
  },
  orders: {
    colorRgb: entities.order.colorRgb,
    children: ["order", "invoice"],
  },
  productions: {
    colorRgb: entities.production.colorRgb,
    children: ["productionBunle", "production", "productionItem", "consumer"],
  },
  reports: {
    colorRgb: entities.report.colorRgb,
    children: ["actionList", "qsReport"],
  },
};
