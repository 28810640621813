import React from "react";
import { entities, modules } from "../../../content/entities";
import { Col, Container, Row } from "react-bootstrap";
import camelCaseToText from "../../../utils/camelCaseToText";
import ColCard from "../../layout/ColCard";
import Markdown from "markdown-to-jsx";

const TypeIcon = ({ icon: Icon, ...rest }) => <Icon {...rest} />;

const Entities = () => {
  const keys = Object.entries(entities);
  console.log("entities", entities);
  console.log("modules", modules);
  console.log("keys", keys);
  return (
    <Container fluid>
      <h1>Legend</h1>
      <h2>All items</h2>
      <Row className="g-3" sm={4}>
        {keys.map(([key, { icon: Icon, description, colorRgb }]) => (
          <ColCard
            body
            key={key}
            className="bg-module text-light"
            style={
              colorRgb
                ? {
                    "--module-color": `rgb(${colorRgb})`,
                  }
                : {}
            }
          >
            {Icon ? <Icon className="fs-1 mb-2" /> : null}
            <h4>{camelCaseToText(key)}</h4>
            <Markdown>{description}</Markdown>
          </ColCard>
        ))}
      </Row>

      <h2 className="mt-5">Structured</h2>
      {Object.entries(modules).map(([key, { colorRgb, children: items }]) => (
        <div
          key={`sth${key}`}
          style={
            colorRgb
              ? {
                  "--module-color": `rgb(${colorRgb})`,
                }
              : {}
          }
        >
          <h3 className="bg-module text-light p-3">
            {entities?.[key]?.icon ? (
              <TypeIcon icon={entities?.[key]?.icon} className="fs-2 me-2" />
            ) : null}
            {camelCaseToText(key)}
          </h3>
          <Row className="g-3 mb-4" sm={4}>
            {items.map((item) =>
              entities?.[item] ? (
                <ColCard
                  className="bg-module text-light"
                  body
                  key={`st${item}`}
                  //   style={
                  //     entities?.[item]?.colorRgb
                  //       ? {
                  //           "--module-color": `rgb(${entities?.[item]?.colorRgb})`,
                  //         }
                  //       : {}
                  //   }
                >
                  {entities?.[item]?.icon ? (
                    <TypeIcon
                      icon={entities?.[item]?.icon}
                      className="fs-1 mb-2"
                    />
                  ) : null}
                  <h4>{camelCaseToText(item)}</h4>
                  <Markdown>{entities?.[item]?.description}</Markdown>
                </ColCard>
              ) : (
                <Col>{item}</Col>
              )
            )}
          </Row>
        </div>
      ))}
    </Container>
  );
};

export default Entities;
