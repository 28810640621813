import React from "react";
import { Badge } from "react-bootstrap";

const StatusBadgeProductions = ({
  status,
  effectClass = "fade-in delay",
  className,
  ...props
}) => {
  const statusColors = {
    created: "bg-warning-shade-10",
    validated: "bg-px-hl",
    scheduled: "bg-success-tint-25",
    sent: "bg-teal-tint-10",
    finished: "bg-primary-tint-25",
    aborted: "bg-danger-tint-25",
  };

  const getColorClass = (val) => statusColors?.[val] || "bg-secondary-tint-25";

  return (
    <Badge
      className={`font-monospace min-w-5 text-center ${getColorClass(
        status
      )} ${effectClass} ${className}`}
      pill
      {...props}
    >
      {status}
    </Badge>
  );
};

export default StatusBadgeProductions;
