import { Button, Modal } from "react-bootstrap";
import CopyToClientWrap from "./CopyToClientWrap";
import ClientListGroup from "../Clients/ClientListGroup";

const CopyToClientButton = ({ editable }) => {
  return (
    <CopyToClientWrap
      component={({
        show,
        setOpen,
        onCancel,
        copyClient,
        selectedClient,
        setSelectedClient,
      }) => {
        return (
          <div>
            <Button
              disabled={editable}
              onClick={() => setOpen()}
              variant="success"
              size="sm"
            >
              Copy to client
            </Button>
            <Modal show={show} onHide={onCancel} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>Select a client</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ClientListGroup
                  selectedClient={selectedClient}
                  setSelectedClient={setSelectedClient}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button
                  disabled={selectedClient === null}
                  variant="success"
                  onClick={copyClient}
                >
                  Copy
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      }}
    />
  );
};

export default CopyToClientButton;
