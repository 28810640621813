import React, { useEffect } from "react";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import Layout from "../layout/Layout";
import useFetch from "../../hooks/useFetch";
import { config } from "../../config";
import WithLoaderAndError from "../ui/WithLoaderAndError";
import { Link } from "react-router-dom";
import {
  BsChevronRight,
  BsMegaphoneFill,
  BsStarFill,
  BsTable,
} from "react-icons/bs";
import ItemCard from "../ui/ItemCard";
import CycleIcon from "../icons/CycleIcon";
import shouldCenterClass from "../../utils/shouldCenterClass";
import ReportItemCard from "../ui/ReportItemCard";
import QSIcon from "../icons/QSIcon";
import { ACTIONLIST, QUICKSIGHT } from "../../constants";
import spaceDino from "../../assets/il-space.svg";
import useStorage from "../../context/StorageContext";
import { useUserContext } from "../../context/UserContext";

// Item size for full width grid
// xs sm="6" lg="4" xl="6"

const DashboardFavCard = ({
  headerText,
  headerIcon: HIcon = BsStarFill,
  footerText,
  footerLink,
  bgClass = "bg-light",
  isLoading,
  error,
  noItemsText = "",
  hasItems = false,
  delayEffect,
  children,
}) => {
  return (
    <Card className={`border-0 ${bgClass} fade-in-grow-up delay`}>
      <Card.Header className="border-0 bg-transparent px-4 py-3">
        <h2>
          <HIcon
            className="h-auto opacity-50 me-2"
            style={{ width: "1em", padding: ".1em" }}
          />
          {headerText}
        </h2>
      </Card.Header>
      <Card.Body className="px-4 py-0">
        <WithLoaderAndError isLoading={isLoading} error={error}>
          {hasItems ? (
            children
          ) : (
            <div>
              <div
                className="fade-in-grow delay mh-50"
                style={{ height: "200px" }}
              >
                <Image
                  src={spaceDino}
                  alt="No items"
                  className={`mx-auto my-3 mw-50 d-block fade-in-grow float ${
                    delayEffect && `delay-1s`
                  }`}
                  style={{ width: "200px" }}
                />
              </div>
              <p className="text-center mw-75 mb-0 mt-2 fade-in-up delay-3">
                {noItemsText ? (
                  <span className="opacity-50">{noItemsText}</span>
                ) : null}
              </p>
            </div>
          )}
        </WithLoaderAndError>
      </Card.Body>
      <Card.Footer className="border-0 text-end bg-transparent">
        <Button
          as={Link}
          to={footerLink}
          variant="link"
          className="text-primary-shade-50"
        >
          {footerText}
          <BsChevronRight className="icon-inline m-left" />
        </Button>
      </Card.Footer>
    </Card>
  );
};

const Dashboard = () => {
  const { setUserSettings } = useUserContext();
  const { client } = useStorage();

  // Favorite views
  const { data: userSettings, error: userSettingsError } = useFetch({
    url: `${config.api.reporting}/usersettings`,
    clientId: client?.id,
    // shouldFetch: () => !currUserSettings,
  });

  useEffect(() => {
    if (userSettings) {
      setUserSettings(userSettings);
    }
  }, [userSettings, setUserSettings]);

  const baseUrlPrograms = `${config.api.phoenix}/programs`;

  const { data: campaigns, error: campaignsError } = useFetch({
    url:
      baseUrlPrograms +
      "?pageSize=16&type=campaign&orderBy=id&direction=descending",
    clientId: client?.id,
  });

  const { data: cycles, error: cyclesError } = useFetch({
    url:
      baseUrlPrograms +
      "?pageSize=16&type=cycle&orderBy=id&direction=descending",
    clientId: client?.id,
  });

  const favQS =
    !!userSettings && !!userSettings?.favoriteViews?.length
      ? userSettings?.favoriteViews.filter(
          (item) => item.chartType === QUICKSIGHT
        )
      : [];
  const favAL =
    !!userSettings && !!userSettings?.favoriteViews?.length
      ? userSettings?.favoriteViews.filter(
          (item) => item.chartType === ACTIONLIST
        )
      : [];

  // const { data: products, error: productsError } = useFetch({
  //   url: `${config.api.order}/products?&pageSize=6&orderBy=id&direction=descending`,
  //   clientId: client?.id,
  // });

  // const { data: packages, error: packagesError } = useFetch({
  //   url: `${config.api.order}/packages?&pageSize=6&orderBy=id&direction=descending`,
  //   clientId: client?.id,
  // });

  // const { data: customers, error: customersError } = useFetch({
  //   url: `${config.api.phoenix}/customers?&pageSize=8`,
  //   clientId: client?.id,
  // });

  // const { data: orders, error: ordersError } = useFetch({
  //   url: `${config.api.order}/orders?&pageSize=5&orderBy=id&direction=descending`,
  //   clientId: client?.id,
  // });

  return (
    <Layout colorClass="dashboard">
      <Card className="card-main border-0">
        <Card.Body className="dashboard-wrap">
          <Row className="g-4 delay-parent">
            <Col lg={6}>
              <DashboardFavCard
                headerText="My QuickSight reports"
                headerIcon={QSIcon}
                footerText="All QuickSight reports"
                footerLink="/reports/quicksight"
                bgClass={"bg-info-tint-25"}
                isLoading={!userSettings && !userSettingsError}
                error={userSettingsError}
                hasItems={favQS?.length}
                noItemsText="No starred Quicksight reports for this client"
              >
                <Row className="g-2">
                  {favQS.map((item) => (
                    <Col key={item.id} xs={12} md={6} lg={12} xl={12} xxl={6}>
                      <ReportItemCard
                        {...item}
                        itemLink={`/reports/${item?.chartType?.toLowerCase()}/${
                          item?.reportId
                        }/${item?.id}`}
                      />
                    </Col>
                  ))}
                </Row>
              </DashboardFavCard>
            </Col>

            <Col lg={6}>
              <DashboardFavCard
                headerText="My action lists"
                headerIcon={BsTable}
                footerText="All action lists"
                footerLink="/reports/actionlist"
                bgClass={"bg-teal-tint-25"}
                isLoading={!userSettings && !userSettingsError}
                error={userSettingsError}
                hasItems={favAL.length}
                noItemsText="No starred action lists for this client"
                delayEffect
              >
                <Row className="g-2">
                  {favAL.map((item) => (
                    <Col key={item.id} xs={12} md={6} lg={12} xl={12} xxl={6}>
                      <ReportItemCard
                        {...item}
                        itemLink={`/reports/${item?.chartType?.toLowerCase()}/${
                          item?.reportId
                        }/${item?.id}`}
                      />
                    </Col>
                  ))}
                </Row>
              </DashboardFavCard>
            </Col>

            <Col md={6}>
              <Card className="border-0 bg-light h-100 fade-in-grow-up delay">
                <Card.Header className="border-0 bg-gray-200">
                  <h2>Cycles</h2>
                </Card.Header>
                <Card.Body
                  className={
                    "p-2 " +
                    shouldCenterClass(
                      (!cycles && !cyclesError) || !!cyclesError
                    )
                  }
                >
                  <WithLoaderAndError
                    isLoading={!cycles && !cyclesError}
                    error={cyclesError}
                  >
                    {cycles?.length ? (
                      <Row
                        // xs={1} lg={2} xl={3} xxl={4}
                        className="g-2"
                      >
                        {cycles.map((item) => (
                          <Col key={item.id} className="min-w-10">
                            <ItemCard
                              {...item}
                              itemPath="programs/cycle"
                              thumbIcon={CycleIcon}
                              imgBoxProps={{
                                className: "text-px-dark text-opacity-75",
                              }}
                            />
                          </Col>
                        ))}
                      </Row>
                    ) : null}
                  </WithLoaderAndError>
                </Card.Body>
                <Card.Footer className="border-0 text-end bg-gray-200">
                  <Button as={Link} to="/programs/cycles" variant="link">
                    All cycles
                    <BsChevronRight className="icon-inline m-left" />
                  </Button>
                </Card.Footer>
              </Card>
            </Col>

            <Col md={6}>
              <Card className="border-0 bg-light h-100 fade-in-grow-up delay">
                <Card.Header className="border-0 bg-gray-200">
                  <h2>Campaigns</h2>
                </Card.Header>
                <Card.Body className="p-2">
                  <WithLoaderAndError
                    isLoading={!campaigns && !campaignsError}
                    error={campaignsError}
                  >
                    {campaigns?.length ? (
                      <Row
                        // xs={1} lg={2} xl={3} xxl={4}
                        className="g-2"
                      >
                        {campaigns.map((item) => (
                          <Col key={item.id} className="min-w-10">
                            <ItemCard
                              {...item}
                              itemPath="programs/campaign"
                              thumbIcon={BsMegaphoneFill}
                            />
                          </Col>
                        ))}
                      </Row>
                    ) : null}
                  </WithLoaderAndError>
                </Card.Body>
                <Card.Footer className="border-0 text-end bg-gray-200">
                  <Button as={Link} to="/programs/campaigns" variant="link">
                    All campaigns
                    <BsChevronRight className="icon-inline m-left" />
                  </Button>
                </Card.Footer>
              </Card>
            </Col>

            {/* <Col>
            <Card className="h-100 fade-in-grow-up delay">
              <Card.Header>
                <h2>Products</h2>
              </Card.Header>
              <Card.Body className="p-2">
                <WithLoaderAndError
                  isLoading={!products && !productsError}
                  error={productsError}
                >
                  {products?.length ? (
                    <Row className="g-2">
                      {products.map((item) => (
                        <Col key={item.id} className="min-w-10">
                          <ItemCard {...item} itemPath="products" />
                        </Col>
                      ))}
                    </Row>
                  ) : null}
                </WithLoaderAndError>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                <Button as={Link} to="/products" variant="link">
                  All products
                  <BsChevronRight className="icon-inline m-left" />
                </Button>
              </Card.Footer>
            </Card>
          </Col>

          <Col>
            <Card className="h-100 fade-in-grow-up delay">
              <Card.Header>
                <h2>Packages</h2>
              </Card.Header>
              <Card.Body className="p-2">
                <WithLoaderAndError
                  isLoading={!packages && !packagesError}
                  error={packagesError}
                >
                  {packages?.length ? (
                    <Row className="g-2">
                      {packages.map((item) => (
                        <Col key={item.id} className="min-w-10">
                          <ItemCard
                            {...item}
                            itemPath="packages"
                            itemBadge="products"
                            className="max-w-300"
                          />
                        </Col>
                      ))}
                    </Row>
                  ) : null}
                </WithLoaderAndError>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                <Button as={Link} to="/packages" variant="link">
                  All packages
                  <BsChevronRight className="icon-inline m-left" />
                </Button>
              </Card.Footer>
            </Card>
          </Col> */}
            {/* <div className="w-100 mb-n2"></div> */}

            {/* <Col>
            <Card className="h-100 fade-in-grow-up delay">
              <Card.Header>
                <h2>Customers</h2>
              </Card.Header>
              <Card.Body
                className={
                  "py-2 " +
                  shouldCenterClass(
                    (!customers && !customersError) || !!customersError
                  )
                }
              >
                <WithLoaderAndError
                  isLoading={!customers && !customersError}
                  error={customersError}
                >
                  {customers?.length ? (
                    <Row className="g-2 pt-1">
                      {customers.map((item) => (
                        <Col key={item.id} xs={6}>
                          <Link
                            className="text-decoration-none hover-parent d-flex align-items-center"
                            to={`/customers/${item.id}`}
                          >
                            <ImgBox
                              imgId={item.logoFileId}
                              className="rounded-circle border hover-border-primary fade-in-grow"
                              noStretch
                              icon={BsPersonFill}
                            />

                            <div
                              className="ms-2 flex-grow-1 lh-sm fade-in"
                              style={{ maxWidth: "calc(100% - 45px)" }}
                            >
                              <div className="small text-dark text-truncate hover-primary">
                                {item.name}
                              </div>
                              <div className="font-monospace text-secondary-tint-25 text-truncate">
                                {item.code}
                              </div>
                            </div>
                          </Link>
                        </Col>
                      ))}
                    </Row>
                  ) : null}
                </WithLoaderAndError>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                <Button as={Link} to="/customers" variant="link">
                  All customers
                  <BsChevronRight className="icon-inline m-left" />
                </Button>
              </Card.Footer>
            </Card>
          </Col> */}
            {/* <Col>
            <Card className="h-100 fade-in-grow-up delay">
              <Card.Header>
                <h2>Orders</h2>
              </Card.Header>
              <Card.Body className="p-0">
                <WithLoaderAndError
                  isLoading={!orders && !ordersError}
                  error={ordersError}
                >
                  {orders?.length ? (
                    <ListGroup variant="flush">
                      {orders.map((item) => (
                        <ListGroup.Item
                          key={item.id}
                          action
                          as={Link}
                          to={`/order/${item.id}`}
                          className="ps-0 pe-2 text-nowrap d-flex justify-content-between align-items-center"
                        >
                          <ImgBox
                            imgId={item?.program?.thumbnail}
                            width={41}
                            noStretch
                            className="my-n2 me-2"
                            icon={BsMegaphoneFill}
                          />
                          <h6 className="text-truncate small mb-0 flex-grow-1">
                            {item?.program?.name || item?.programName || (
                              <span className="font-monospace text-muted me-2">
                                Order {item.id}
                              </span>
                            )}
                          </h6>
                          <StatusBadge value={item?.state} className="ms-2" />
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : null}
                </WithLoaderAndError>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                <Button as={Link} to="/orders" variant="link">
                  All orders
                  <BsChevronRight className="icon-inline m-left" />
                </Button>
              </Card.Footer>
            </Card>
          </Col> */}
            {/* <Col>
            <Card className="h-100 fade-in-grow-up delay">
              <Card.Header>
                <h2>QuickSight reports</h2>
              </Card.Header>
              <Card.Body className="p-0">
                <WithLoaderAndError
                  isLoading={!reports && !reportsError}
                  error={reportsError}
                >
                  {reports?.length ? (
                    <ListGroup variant="flush">
                      {reports
                        .filter((r, i) => i < 5)
                        .map((item) => (
                          <ListGroup.Item
                            key={item.id}
                            action
                            as={Link}
                            to={`/reports/quicksight/${item.reportId}/${item.id}`}
                            className="ps-0 text-truncate"
                          >
                            <ImgBox
                              width={41}
                              noStretch
                              className="my-n2 float-start bg-transparent text-primary-tint-25"
                              style={{ color: "#5dbbdb" }}
                              icon={
                                item?.chartType === "quickSight"
                                  ? QSIcon
                                  : FaTasks
                              }
                            />
                            <small>{item?.name}</small>
                          </ListGroup.Item>
                        ))}
                    </ListGroup>
                  ) : null}
                </WithLoaderAndError>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                <Button as={Link} to="/reports/quicksight" variant="link">
                  All QS reports
                  <BsChevronRight className="icon-inline m-left" />
                </Button>
              </Card.Footer>
            </Card>
          </Col> */}
            {/* <Col>
            <Card className="h-100 fade-in-grow-up delay">
              <Card.Header>
                <h2>All UI elements</h2>
              </Card.Header>
              <Card.Body className="d-flex align-items-center justify-content-center">
                <div className="text-center text-muted">
                  Buttons, badges, etc...
                </div>
              </Card.Body>
              <Card.Footer style={{ textAlign: "right" }}>
                <Button as={Link} to="/default" variant="link">
                  All UI elements
                  <BsChevronRight className="icon-inline m-left" />
                </Button>
              </Card.Footer>
            </Card>
          </Col> */}
          </Row>
        </Card.Body>
      </Card>
    </Layout>
  );
};

export default Dashboard;
