import React, { useEffect, useState } from "react";
import useFetch from "../../hooks/useFetch";
import { Button, Card, Stack, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsCaretDown, BsX } from "react-icons/bs";
import shouldCenterClass from "../../utils/shouldCenterClass";
import WithLoaderAndError from "./WithLoaderAndError";
import NoResults from "./NoResults";

const MenuOnDetail = ({
  baseUrl = " ",
  clientId,
  customerId,
  useActiveClient,
  searchParam = "query",
  urlParams,
  itemLabel = "",
  itemPath = itemLabel,
  getItemLink = (item) => `/${itemPath}/${item.id}`,
  tableItems = {},
  isViewExpanded,
  setIsViewExpanded,
  currentView,
}) => {
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 100;
  const [query, setQuery] = useState("");

  const dataUrl =
    baseUrl +
    `?page=${page}&pageSize=${PAGE_SIZE}&${
      !!searchParam ? `${searchParam}=${query}` : ""
    }` +
    urlParams;

  const { data: items, error } = useFetch({
    url: dataUrl,
    clientId,
    customerId,
    useActiveClient,
  });

  const itemsSource = tableItems ? tableItems : items ? items?.[0] : [];

  const fields = itemsSource
    ? Object.keys(itemsSource).filter(
        (key) => itemsSource?.[key]?.hidden !== true
      )
    : [];

  useEffect(() => {
    setPage(1);
  }, [PAGE_SIZE, query, urlParams]);

  const TableCell = ({
    itemKey: key,
    value,
    cellClass = "",
    cellWidth = 300,
    itemLink,
  }) => {
    const isTextType = ["name"].includes(key.toLowerCase());

    return isTextType ? (
      <td
        className={cellClass}
        style={cellWidth ? { maxWidth: cellWidth } : {}}
      >
        <Link to={itemLink} className="table-cell-link">
          <div className="text-truncate fade-in delay">{value}</div>
        </Link>
      </td>
    ) : null;
  };

  const TableRow = ({ item, currentView }) => {
    const isHighlighted = item?.name === currentView;
    return (
      <tr key={item.id} className={isHighlighted ? "table-success" : ""}>
        {fields.flatMap((key) => [
          <TableCell
            key={`${item.id}-${key}`}
            itemKey={key}
            itemLink={getItemLink(item)}
            value={
              key.indexOf(".") > -1
                ? item?.[key.split(".")?.[0]]?.[key.split(".")?.[1]]
                : item?.[key]
            }
          />,
        ])}
      </tr>
    );
  };

  return (
    <>
      <Card
        className="border-0"
        style={{
          minWidth: "25%",
          padding: "5px",
        }}
      >
        <Card.Header
          className="sticky-top px-2 bg-gray-200"
          style={{ top: "var(--header-height)" }}
        >
          <Stack
            direction="horizontal"
            gap={2}
            className="justify-content-between flex-wrap"
          >
            <Stack
              direction="horizontal"
              gap={2}
              className="justify-content-end flex-wrap"
            >
              {isViewExpanded ? (
                <Button
                  title="Close sidemenu"
                  className={"position-relative"}
                  onClick={() => setIsViewExpanded(!isViewExpanded)}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    padding: "0",
                  }}
                >
                  <BsCaretDown
                    style={{
                      color: "rgba(0, 0, 0, 0.6)",
                    }}
                  />
                </Button>
              ) : null}
            </Stack>
          </Stack>
        </Card.Header>
        <Card.Body
          className={
            "p-0 bg-gray-400" + shouldCenterClass((!items && !error) || !!error)
          }
          style={{
            overflowY: "auto",
            fontSize: "0.85rem",
          }}
        >
          <WithLoaderAndError isLoading={!items && !error} error={error}>
            {items && !!items.length ? (
              <Table responsive striped hover className="mb-0">
                <tbody className="short-delay-parent">
                  {items.map((item, i) => (
                    <React.Fragment key={`fragment-${item.id}`}>
                      <TableRow
                        item={item}
                        key={`tli${item.id}`}
                        currentView={currentView}
                      />
                    </React.Fragment>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="d-flex justify-content-center align-items-center no-results-wrap">
                <NoResults
                  img="drunk"
                  children={
                    <div className="text-muted mt-2">
                      <div className="mb-3">
                        No {itemLabel}s match{" "}
                        <span className="text-teal font-monospace">
                          "{query}"
                        </span>
                      </div>
                      {query ? (
                        <Button
                          variant="outline-primary"
                          onClick={() => setQuery("")}
                        >
                          <BsX /> Clear search
                        </Button>
                      ) : null}
                    </div>
                  }
                />
              </div>
            )}
          </WithLoaderAndError>
        </Card.Body>
      </Card>
    </>
  );
};

export default MenuOnDetail;
