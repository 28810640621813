import { useEffect, useState } from "react";
import useStorage from "../context/StorageContext";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import fetcher from "../utils/fetcher";
import { config } from "../config";
import createDetailedErrorMessage from "../utils/detailMessageError";
import { useNotifications } from "../context/NotificationsContext";
import happy from "../assets/il-happy.svg";

const useTreatments = ({heading, defaultValues, treatmentName, treatment}) => {
    const [show, setShow] = useState(false);
    const {client} = useStorage();
    const {setToasts} = useNotifications();
    const [error, setError] = useState(null);
    const { treatmentId } = useParams();
    const [selectedClient, setSelectedClient] = useState(null);
    const dataUrl = `${config.api.phoenix}/treatments`;

    const navigate = useNavigate();

    const { register, reset, handleSubmit, formState } = useForm({defaultValues})

    const { isDirty } = formState;

    const formProps ={
        register: register,
        reset: reset,
        handleSubmit: handleSubmit,
        isDirty: isDirty,
    }

    //for creating treatment
    const onCreate = (data) => {
      const payload = {
        name: data.name,
        code: data.code,
        active: true,
        type: "standard",
        pruningPolicyType: "pruneIfNeeded",
        parameters: [],
        documentReferences: []

      }
      fetcher({
        url: dataUrl,
        method: "POST",
        payload: payload,
        clientId: client?.id
      }).then((res) => {
        navigate(`/treatments/${res.data.id}`);
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            heading: "Success!",
            text: (
              <div>
                {"Treatment "} <strong>{res.data.name}</strong>{" "}
                {"is successfully created."}
              </div>
            ),
          },
        ]);
      })
      .catch((err) => {
        setError(err);
      });
      setShow(false);
    }

    //for deleting treatment
    const onDelete = () => {
      fetcher({
        url: `${config.api.phoenix}/treatments/${treatmentId}`,
        method: "DELETE",
        payload: {},
        clientId: client?.id,
      }).then(() => {
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            type: "success-delete",
            variant: "success",
            img: happy,
            delay: 7500,
            titleText: "Treatment Deleted",
            text: (
              <div>
                {"Treatment "} <strong>{treatmentName}</strong>{" "}
                {
                  " is deleted. It will no longer appear in search results and can not be viewed or edited"
                }
              </div>
            ),
          },
        ]);
        navigate("/treatments");

      }).catch((err) => {
        setError(err)
      })
    }

    //copying treatment to another client
    const handleCopyToClient = () => {
      fetcher({
        url: `${config.api.phoenix}/treatments/${treatmentId}/copytoclient/${selectedClient?.id}`,
        method: "POST",
        payload: {
          name: treatment.name,
          code: treatment.code,
          description: treatment.description,
          mailPackNumber: treatment.mailPackNumber,
          mailpackCode: treatment.mailpackCode,
          pruningPolicyType: treatment.pruningPolicyType,
          postalClass: treatment.postalClass,
          postalGroup: treatment.postalGroup,
          validterm: treatment.validterm,
          languageCode: treatment.languageCode,
          active: treatment.active,
          thumbnailFileId: treatment.thumbnailFileId,
          type: treatment.type,
          documentReferences: treatment.documents,
          parameters: (treatment.parameters || []).map(p => ({
            name: p.name ?? "",
            valueType: p.valueType ?? "string",
            sequence: p.sequence ?? 0,
            displayName: p.displayName ?? "",
            description: p.description ?? "",
            editableByCustomer: p.editableByCustomer ?? false,
            inheritance: typeof p.inheritance === "string" ? p.inheritance : "",
            active: p.active ?? true,
            valueOptions: Array.isArray(p.valueOptions) ? p.valueOptions : []
          }))
        },
        clientId: client?.id
      }).then(() => {
        setShow(false);
        setSelectedClient(null);
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            text: (
              <span>{`Successfully copied treatment to ${selectedClient.name}`}</span>
            ),
          },
        ]);
      })
      .catch((err) => {
        setShow(false);
        setSelectedClient(null);
        setError(err);
      });
    }

    //copy treatment to same client
    const handleCopy = () => {
      fetcher({
        url: dataUrl,
        method: "POST",
        payload: {
          name: `${treatment.name}-Copy`,
          code: treatment.code,
          description: treatment.description,
          mailPackNumber: treatment.mailPackNumber,
          mailpackCode: treatment.mailpackCode,
          pruningPolicyType: treatment.pruningPolicyType,
          postalClass: treatment.postalClass,
          postalGroup: treatment.postalGroup,
          validterm: treatment.validterm,
          languageCode: treatment.languageCode,
          active: treatment.active,
          thumbnailFileId: treatment.thumbnailFileId,
          type: treatment.type,
          documentReferences: treatment.documents,
          parameters: (treatment.parameters || []).map(p => ({
            name: p.name ?? "",
            valueType: p.valueType ?? "string",
            sequence: p.sequence ?? 0,
            displayName: p.displayName ?? "",
            description: p.description ?? "",
            editableByCustomer: p.editableByCustomer ?? false,
            inheritance: typeof p.inheritance === "string" ? p.inheritance : "",
            active: p.active ?? true,
            valueOptions: Array.isArray(p.valueOptions) ? p.valueOptions : []
          }))
        },
        clientId: client?.id
      })
      .then((res) => {
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            text: <span>{`Successfully copied Treatment`}</span>,
          },
        ]);
        setShow(false);
        navigate(`../treatments/${res.data.id}`)
      })
      .catch((err) => {
        setError(err);
        setShow(false);
      });
    }

    useEffect(() => {
        if (error !== null && error !== undefined) {
          setToasts((currToasts) => [
            ...currToasts,
            {
              id: Date.now(),
              variant: "danger",
              heading: heading || "Something went wrong",
              text: createDetailedErrorMessage(error),
            },
          ]);
        }
      }, [error, setToasts, heading]);

    return {
      show, 
      setShow, 
      onCreate, 
      formProps, 
      onDelete, 
      handleCopy, 
      selectedClient,
      setSelectedClient,
      handleCopyToClient
    }

}
export default useTreatments;