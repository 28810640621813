import useFetch from "../../../hooks/useFetch";
import { config } from "../../../config";

const FulfilmentPartnerWrap = ({ id, customerId, component: Component }) => {
  const { api } = config;
  const { data: fp } = useFetch({
    url: `${api.phoenix}/fulfilmentpartners/${id}`,
    customerId,
    shouldFetch: () => id && customerId,
  });
  const { name, logo, email } = fp || {};
  return <Component name={name} logo={logo} email={email} />;
};

export default FulfilmentPartnerWrap;
