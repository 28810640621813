import React, { useEffect, useState } from "react";
import axios from "axios";
import Markdown from "markdown-to-jsx";
import md from "../../../content/entities.md";

const EntitiesMd = ({ path = md }) => {
  const [mdContent, setMdContent] = useState("");

  useEffect(() => {
    axios.get(path).then((res) => setMdContent(res.data));
  }, [path]);

  return (
    <Markdown
      options={{
        overrides: {
          p: ({ children }) => <p className="small ps-4">{children}</p>,
          strong: ({ children }) => (
            <strong className="fw-semibold">{children}</strong>
          ),
        },
      }}
    >
      {mdContent}
    </Markdown>
  );
};

export default EntitiesMd;
