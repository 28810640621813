import { useEffect, useState } from "react";
import { config } from "../../../../config";
import fetcher from "../../../../utils/fetcher";
import { useNotifications } from "../../../../context/NotificationsContext";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import useStorage from "../../../../context/StorageContext";
import { useNavigate } from "react-router-dom";

const BundleWrap = ({ component: Component }) => {
  const dataUrl = `${config.api.phoenix}/productionbundles`;
  const { setToasts } = useNotifications();
  const { client } = useStorage();
  const [show, setShow] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState("");

  const navigate = useNavigate();

  const handleCreateBundle = (name, selectedProductionIds) => {
    fetcher({
      url: dataUrl,
      method: "POST",
      payload: {
        productionIds: selectedProductionIds,
        name: name,
      },
      clientId: client?.id,
    })
      .then(() => {
        setShow(false);
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            heading: "Success!",
            text: "New bundle created",
          },
        ]);
        navigate("../bundles");
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Component
      handleCreateBundle={handleCreateBundle}
      show={show}
      setShow={setShow}
      name={name}
      setName={setName}
    />
  );
};
export default BundleWrap;
