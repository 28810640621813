import React from "react";
import QSIcon from "../icons/QSIcon";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CHART_TYPE } from "../../constants";
import {
  BsArrowRight,
  BsFillQuestionCircleFill,
  BsTable,
} from "react-icons/bs";
import ImgBox from "./ImgBox";
import TextBlock from "./TextBlock";

const ReportItemCard = ({
  id,
  reports = false,
  itemPath,
  itemLink = `/${itemPath}/${id}`,
  thumbnailSize = 56,
  ...item
}) => {
  const { QUICKSIGHT, ACTIONLIST } = CHART_TYPE;
  const itemColor =
    item?.chartType === QUICKSIGHT
      ? "info"
      : item?.chartType === ACTIONLIST
      ? "teal"
      : "secondary";

  const ItemIcon =
    item?.chartType === QUICKSIGHT
      ? QSIcon
      : item?.chartType === ACTIONLIST
      ? BsTable
      : BsFillQuestionCircleFill;

  return (
    <Link to={itemLink} className="text-decoration-none hover-parent">
      <Card
        className={`h-100 fade-in-grow-up delay text-body bg-light border-0`}
      >
        <Card.Body
          className="pb-2"
          style={
            reports && item?.chartType === ACTIONLIST
              ? { minHeight: "150px", maxHeight: "150px" }
              : {}
          }
        >
          <ImgBox
            icon={ItemIcon}
            width={56}
            className={`bg-${itemColor} text-light float-start rounded-circle hover-bg-primary ${
              item?.chartType === ACTIONLIST ? "actionList" : "quickSight"
            }`}
            style={{ width: thumbnailSize }}
          />
          <div style={{ paddingLeft: `calc(${thumbnailSize}px + .75rem)` }}>
            <div
              className="mb-2 d-flex align-items-center"
              style={
                reports && item?.chartType === ACTIONLIST
                  ? {}
                  : { minHeight: thumbnailSize }
              }
            >
              <div>
                <Card.Title
                  className="mb-1 hover-primary"
                  style={reports ? { fontSize: "15px" } : {}}
                >
                  {item?.name}
                </Card.Title>
                {item?.subtitle ? (
                  <Card.Subtitle className="opacity-75 hover-primary">
                    {item?.subtitle}
                  </Card.Subtitle>
                ) : null}
              </div>
            </div>
            {item?.description ? (
              <TextBlock className="small" text={item?.description} />
            ) : null}
          </div>
        </Card.Body>
        <Card.Footer
          className="bg-transparent pb-2 pt-0 text-end border-top-0"
          style={{ paddingLeft: `calc(${thumbnailSize}px + 1.5rem)` }}
        >
          <Button size="lg" variant="link" title="View report">
            <BsArrowRight />
          </Button>
        </Card.Footer>
      </Card>
    </Link>
  );
};

export default ReportItemCard;
