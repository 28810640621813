import { useEffect, useState } from "react";
import { config } from "../../../config";
import useFetch from "../../../hooks/useFetch";

const MailMomentsWrap = ({
  mailMoment,
  setMailMoment,
  programId,
  component: Component,
}) => {
  const { api } = config;
  const [mailMoments, setMailMoments] = useState([]);

  const { data: program } = useFetch({
    url: `${api.phoenix}/programs/${programId}`,
    shouldFetch: () => !!programId,
  });

  const handleClick = (selectedMailMoment) => {
    if (selectedMailMoment.id === mailMoment?.id) {
      setMailMoment(null);
    } else {
      setMailMoment(selectedMailMoment);
    }
  };

  useEffect(() => {
    if (!!program && !!program?.mailMoments && program.mailMoments.length > 0) {
      setMailMoments(program.mailMoments);
    }
  }, [program]);

  //for editing mailmoment in variant detail page
  const [show, setShow] = useState(false);
  const [selectedMailMoment, setSelectedMailMoment] = useState(null);

  const handleCancel = () => {
    setShow(false);
    setSelectedMailMoment(null);
  };

  return (
    <Component
      mailMoments={mailMoments}
      mailMoment={mailMoment}
      handleClick={handleClick}
      show={show}
      handleOpen={() => setShow(true)}
      handleCancel={handleCancel}
      selectedMailMoment={selectedMailMoment}
      setSelectedMailMoment={setSelectedMailMoment}
    />
  );
};

export default MailMomentsWrap;
