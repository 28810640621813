import { useEffect, useState } from "react";
import { config } from "../../../config";
import useFetch from "../../../hooks/useFetch";

const CustomersWrap = ({
  customers = [],
  setCustomers,
  component: Component,
}) => {
  const { api } = config;
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const PAGE_SIZE = 30;

  const dataUrl = `${api.phoenix}/customers`;
  const params = {
    page,
    pageSize: PAGE_SIZE,
    query: searchQuery,
  };

  const { data: allCustomers, headers } = useFetch({
    url: dataUrl,
    params,
  });

  const handleClick = (customer) => {
    if (!!customers && customers?.some((c) => c.id === customer.id)) {
      setCustomers(customers.filter((c) => c.id !== customer.id));
    } else {
      setCustomers([...customers, customer]);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  return (
    <Component
      allCustomers={allCustomers}
      handleClick={handleClick}
      page={page}
      setPage={setPage}
      PAGE_SIZE={PAGE_SIZE}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      headers={headers}
    />
  );
};

export default CustomersWrap;
