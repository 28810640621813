import React from "react";
import { Badge, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import AddressListItem from "./AddressListItem";
import ContactDetailsListItem from "./ContactDetailsListItem";
import SupplierTypeIcon from "../../icons/SupplierTypeIcon";

const SupplierListItem = ({
  id,
  supplierType,
  name,
  description,
  addresses,
  contactDetails,
  className,
  multiline = false,
  to, // as Link
  onClick, // as Button
  ...rest
}) => {
  const linkProps = to ? { to } : {};
  const btnProps = onClick ? { onClick } : {};

  return (
    <ListGroupItem
      action={to || onClick}
      className={`hover-parent py-3 d-flex justify-content-start align-items-center${
        className ? ` ${className}` : ""
      }`}
      {...linkProps}
      {...btnProps}
      {...rest}
    >
      <SupplierTypeIcon
        type={supplierType}
        className={`h2 mb-0 text-secondary-tint-25 flex-shrink-0${
          multiline ? " ms-1 me-3 my-2 align-self-start" : " me-2 ms-n1"
        } align-self-start`}
      />
      <div className="mb-0 flex-grow-1">
        {name ? (
          <span className={`h3 me-2${multiline ? " d-block" : ""}`}>
            {name}
          </span>
        ) : null}
        <Badge bg="px-dark-tint-50" className="me-2 align-text-top">
          {supplierType}
        </Badge>
        <span className={`${multiline ? "d-block" : ""} small`}>
          {description}
        </span>
        <div>
          <div className="mt-2">
            {/* <h5 className="mb-2 fw-normal">
              Addresses
              <Badge
                pill
                bg={addresses?.length ? "success-tint-10" : "secondary-tint-50"}
                as="sup"
                className="ms-2 fs-45 align-baseline"
              >
                {addresses?.length}
              </Badge>
            </h5> */}
            <ListGroup
              variant="flush"
              style={{
                "--bs-list-group-item-padding-y": ".25rem",
                "--bs-list-group-border-width": 0,
              }}
            >
              {addresses.map((address, i) => (
                <AddressListItem
                  key={`${id}address${i}`}
                  item={address}
                  className="ps-1"
                />
              ))}
            </ListGroup>
          </div>
          <div className="mt-2">
            {/* <h5 className="mb-2 fw-normal">
              Contact details
              <Badge
                pill
                bg={
                  contactDetails?.length
                    ? "success-tint-10"
                    : "secondary-tint-50"
                }
                as="sup"
                className="ms-2 fs-45 align-baseline"
              >
                {contactDetails?.length}
              </Badge>
            </h5> */}
            <ListGroup
              variant="flush"
              style={{
                "--bs-list-group-item-padding-y": ".25rem",
                "--bs-list-group-border-color": "transparent",
              }}
            >
              {contactDetails.map(({ id, type, value, notes }) => (
                <ContactDetailsListItem
                  key={id}
                  id={id}
                  type={type}
                  value={value}
                  notes={notes}
                  className="ps-1"
                />
              ))}
            </ListGroup>
          </div>
        </div>
      </div>

      {to ? (
        <Button
          as="span"
          variant="link"
          className={`hover-opacity ${
            multiline ? " align-self-end me-n1" : " my-n2 me-n3"
          }`}
          title="Edit"
        >
          <BsPencil />
        </Button>
      ) : null}
    </ListGroupItem>
  );
};

export default SupplierListItem;
