import React from "react";
import { Badge, Card } from "react-bootstrap";
import ImgBox from "../ui/ImgBox";
import { Link } from "react-router-dom";
import formatPrice from "../../utils/formatPrice";

const ItemCard = ({
  id,
  itemPath,
  itemLink = `/${itemPath}/${id}`,
  itemBadge = null,
  thumbIcon,
  thumbnailProp = "thumbnailFileId",
  imgBoxProps = {},
  theme,
  price,
  ...item
}) => {
  return (
    <Link to={itemLink} className="text-decoration-none hover-parent">
      <Card className="border h-100 justify-content-between hover-border-primary thumbnail-card fade-in-grow-up delay">
        <Card.Body className="p-0">
          <div className="position-relative">
            <ImgBox
              imgId={item?.[thumbnailProp]}
              icon={thumbIcon}
              {...imgBoxProps}
              className="w-100 hover-zoom"
              params={{
                Format: "jpg",
                ResizeMode: "Contain",
              }}
            />
            {!!itemBadge ? (
              <Badge
                pill
                className={`position-absolute end-0 top-0 m-2 ${
                  !!item?.[itemBadge]?.length ? "" : "bg-opacity-25"
                }`}
                bg={item?.[itemBadge]?.length ? "primary-tint-15" : "secondary"}
              >
                {item?.[itemBadge]
                  ? typeof item?.[itemBadge] === "object"
                    ? item?.[itemBadge]?.length
                    : item?.[itemBadge]
                  : 0}
              </Badge>
            ) : null}
          </div>
        </Card.Body>
        <Card.Footer
          className="p-2 border-top-0 bg-px-dark-tint-85"
          style={{ flexBasis: "100%" }}
        >
          <Card.Title as="h6" className="mb-n1 pb-1 text-truncate fs-90">
            {item?.marketingName || item?.name || item?.title}
          </Card.Title>
          <div style={{ height: "20px" }}>
            {theme ? (
              <span className="d-inline-block text-primary-tint-25 small hover-light">
                {theme}
              </span>
            ) : null}
            {price ? (
              <span className="d-inline-block text-success-shade-10 font-monospace hover-light">
                {formatPrice(price)}
              </span>
            ) : null}
          </div>
        </Card.Footer>
      </Card>
    </Link>
  );
};

export default ItemCard;
