import { useEffect, useState } from "react";
import { config } from "../config";
import useFetch from "./useFetch";
import useStorage from "../context/StorageContext";

const useClients = ({selectedClient, setSelectedClient}) => {
    const dataUrl = `${config.api.phoenix}/clients`;
    const { client } = useStorage();
     const [clients, setClients] = useState(null);
     const [page, setPage] = useState(1);
     const PAGE_SIZE = 30;

    const params = {
        page,
        pageSize: PAGE_SIZE,
      };

    const {
        data: unFilteredClients,
        headers,
        fetchError: clientsError,
        isValidating: clientsIsValidating,
      } = useFetch({
        url: dataUrl,
        method: "GET",
        params,

      });

    useEffect(() => {
        if (unFilteredClients) {
            setClients(unFilteredClients.filter((c) => c.id !== client?.id));
        }
    }, [unFilteredClients, client]);
    
    const handleClick = (client) => {
        if (!!selectedClient && selectedClient.id === client.id) {
        setSelectedClient(null);
        } else {
        setSelectedClient(client);
        }
    };

    return { handleClick, clients, clientsError, headers, clientsIsValidating, page, setPage, PAGE_SIZE}
    
}

export default useClients;