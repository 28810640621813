import { useEffect, useState } from "react";
import { config } from "../../../config";
import useFetch from "../../../hooks/useFetch";
import { useLayout } from "../../../context/LayoutContext";
import useStorage from "../../../context/StorageContext";

//This component is for fetching all fulfilment partners.
// Check FulfilmentPartnerWrap for fetching a specific fulfilment partner

const FulfilmentPartnersWrap = ({
  fulfilmentPartner,
  setFulfilmentPartner,
  fulfilmentPartners,
  setFulfilmentPartners,
  fulfilmentPartnerFilter,
  setFulfilmentPartnerFilter,
  component: Component,
}) => {
  const { api } = config;
  const PAGE_SIZE = 30;
  const [page, setPage] = useState(1);
  const { client } = useStorage();
  const { showSidebar } = useLayout();

  const [isExpanded, setIsExpanded] = useState(false);
  const [advancedOptions, setAdvancedOptions] = useState(false);
  const [hoverBadge, setHoverBadge] = useState(false);

  const params = {
    pageSize: PAGE_SIZE,
    page,
  };

  const dataUrl = `${api.phoenix}/fulfilmentpartners`;

  const { data: fps, headers } = useFetch({
    url: dataUrl,
    params,
    clientId: client?.id,
  });

  useEffect(() => {
    if (showSidebar === false) {
      setIsExpanded(false);
    }
  }, [showSidebar]);

  const toggleAdvancedOptions = () => {
    if (advancedOptions === false) {
      if (!!fulfilmentPartner) {
        setFulfilmentPartners([fulfilmentPartner]);
      }
      setFulfilmentPartner(null);
    } else if (advancedOptions === true) {
      setFulfilmentPartnerFilter("");
      if (!!fulfilmentPartners && fulfilmentPartners?.length > 0) {
        setFulfilmentPartner(fulfilmentPartners[0]);
      }
      setFulfilmentPartners([]);
    }
    setAdvancedOptions(!advancedOptions);
  };

  const toggleExpandedView = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClick = (fp) => {
    if (advancedOptions === false) {
      if (fp.id === fulfilmentPartner?.id) {
        setFulfilmentPartner(null);
      } else {
        setFulfilmentPartner(fp);
      }
    } else if (advancedOptions === true) {
      if (fulfilmentPartners?.some((f) => f.id === fp.id)) {
        setFulfilmentPartners(fulfilmentPartners.filter((f) => f.id !== fp.id));
      } else {
        setFulfilmentPartners([...fulfilmentPartners, fp]);
      }
    }
  };

  const handleWithCheck = () => {
    if (fulfilmentPartnerFilter === "with") {
      setFulfilmentPartnerFilter("");
    } else {
      setFulfilmentPartnerFilter("with");
      setFulfilmentPartner(null);
      setFulfilmentPartners([]);
    }
  };

  const handleWithoutCheck = () => {
    if (fulfilmentPartnerFilter === "without") {
      setFulfilmentPartnerFilter("");
    } else {
      setFulfilmentPartnerFilter("without");
      setFulfilmentPartner(null);
      setFulfilmentPartners([]);
    }
  };

  const clearFulfillmentPartners = () => {
    setFulfilmentPartner(null);
    setFulfilmentPartners([]);
    setFulfilmentPartnerFilter("");
  };

  const count =
    (fulfilmentPartnerFilter ? 1 : 0) +
    (fulfilmentPartner ? 1 : 0) +
    (fulfilmentPartners.length > 0 ? fulfilmentPartners.length : 0);

  return (
    <Component
      fps={fps}
      headers={headers}
      page={page}
      pageSize={PAGE_SIZE}
      advancedOptions={advancedOptions}
      isExpanded={isExpanded}
      toggleExpandedView={toggleExpandedView}
      toggleAdvancedOptions={toggleAdvancedOptions}
      handleClick={handleClick}
      setPage={setPage}
      handleWithCheck={handleWithCheck}
      handleWithoutCheck={handleWithoutCheck}
      clearFulfillmentPartners={clearFulfillmentPartners}
      count={count}
      hoverBadge={hoverBadge}
      setHoverBadge={setHoverBadge}
    />
  );
};

export default FulfilmentPartnersWrap;
