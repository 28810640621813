import { useEffect, useState } from "react";
import { config } from "../config";
import useFetch from "./useFetch";
import fetcher from "../utils/fetcher";
import { useNotifications } from "../context/NotificationsContext";
import useStorage from "../context/StorageContext";
import createDetailedErrorMessage from "../utils/detailMessageError";

const useParameters = ({document, treatment, documentType, refresh = () => null}) => {
    const [page, setPage] = useState(1);
    const { setToasts } = useNotifications();
    const { client } = useStorage();
    const PAGE_SIZE = 30;
    const [query, setQuery] = useState("");
    const [show, setShow] = useState(false);
    const [selectedParameters, setSelectedParameters] = useState([]);
    const [error, setError] = useState(null);
   
    const params = {
        page,
        pageSize: PAGE_SIZE,
        search: query,
        orderBy: "nameAscending"
    }

    const {
        data: parameterDefinitions,
        fetchError,
        isValidating,
        headers
    } = useFetch({
        url: `${config.api.phoenix}/enumerations/parameterdefinitions`,
        params,
        method: "GET",
    });

    const handleClick = (parameter) => {
        if(!!selectedParameters && selectedParameters?.some((p) => p.name === parameter.name)){
            setSelectedParameters(selectedParameters.filter((p) => p.name !== parameter.name))
        }
        else{
            setSelectedParameters([...selectedParameters, parameter])
        }
    }

    useEffect(() => {
        setPage(1);
    }, [query]);

    const onCancel = () => {
        setShow(false);
        setSelectedParameters([]);
    }

    const updateDocument = () => {
        const dataUrl =
        documentType === "dm"
          ? `${config.api.dm}/documents/${document?.id}`
          : documentType === "edm"
          ? `${config.api.edm}/documents/${document?.id}`
          : "";
        fetcher({
            url: dataUrl,
            method:"PUT",
            payload: {
                active: document.active,
                name: document.name,
                code: document.code,
                documentType: documentType === "dm"
                  ? "XMPie document"
                  : documentType === "edm"
                  ? document?.documentType
                  : "",
                  description: document.description,
                  thumbnailFileId: document.thumbnailFileId || "",
                  parameters: [
                    ...(document.parameters || []),
                    ...(selectedParameters || [])
                  ].filter((param, index, self) =>
                    index === self.findIndex(p => p.name === param.name)
                  )
                  
            },
            clientId: client?.id
        }).then(() => {
            setShow(false);
            refresh();
            setSelectedParameters([]);
        }).catch((err) => {
            setShow(false);
            setSelectedParameters([]);
            setError(err);
        });
    };

  const updateTreatment = () => {
    const dataUrl = `${config.api.phoenix}/treatments/${treatment?.id}`;

    const mergedParameters = [
      ...(treatment.parameters || []),
      ...(selectedParameters || [])
    ];
    
    const deduplicated = mergedParameters.filter(
      (param, index, self) =>
        index === self.findIndex(p => p.name === param.name)
    );
    
    const formattedParameters = deduplicated.map(p => ({
      name: p.name ?? "",
      valueType: p.valueType ?? "string",
      sequence: p.sequence ?? 0,
      displayName: p.displayName ?? "",
      description: p.description ?? "",
      editableByCustomer: p.editableByCustomer ?? false,
      inheritance: typeof p.inheritance === "string" ? p.inheritance : "",
      active: p.active ?? true,
      valueOptions: Array.isArray(p.valueOptions) ? p.valueOptions : []
    }));
    fetcher({
      url: dataUrl,
      method: "PUT",
      payload: {
        name: treatment.name,
          code: treatment.code,
          description: treatment.description,
          mailPackNumber: treatment.mailPackNumber,
          mailpackCode: treatment.mailpackCode,
          pruningPolicyType: treatment.pruningPolicyType,
          postalClass: treatment.postalClass,
          postalGroup: treatment.postalGroup,
          validterm: treatment.validterm,
          languageCode: treatment.languageCode,
          active: treatment.active,
          thumbnailFileId: treatment.thumbnailFileId,
          type: treatment.type,
          documentReferences: treatment.documents,
          parameters: formattedParameters,
      },
      clientId: client?.id,
    }).then(() => {
        setShow(false);
        refresh();
        setSelectedParameters([]);
      }).catch((err) => {
        setShow(false);
        setSelectedParameters([]);
        setError(err);
      });
    }

    useEffect(() => {
        if (error !== null && error !== undefined) {
          setToasts((currToasts) => [
            ...currToasts,
            {
              id: Date.now(),
              variant: "danger",
              text: createDetailedErrorMessage(error),
            },
          ]);
        }
      }, [error, setToasts]);

    return {
        handleClick, 
        parameterDefinitions, 
        fetchError, 
        isValidating, 
        setQuery, 
        query, 
        page, 
        setPage, 
        PAGE_SIZE, 
        show, 
        setShow,
        headers,
        onCancel,
        updateDocument,
        updateTreatment,
        selectedParameters
    };
};

export default useParameters;