import { Button, Dropdown, FloatingLabel, Form, Modal } from "react-bootstrap";
import AddDocumentWrap from "./AddDocumentWrap";
import { GoPlus } from "react-icons/go";
import { BsCaretDown } from "react-icons/bs";

const AddDocumentButton = ({ initialType }) => {
  return (
    <AddDocumentWrap
      initialType={initialType}
      component={({
        show,
        setClose,
        setOpen,
        type,
        setType,
        register,
        handleSubmit,
        isDirty,
        onSubmit,
        showDropdown,
        setShowDropdown,
      }) => {
        return (
          <div>
            <Button onClick={setOpen} size="sm" variant="success">
              <GoPlus className="btn-icon" /> New
            </Button>
            <Modal show={show} size="lg" onHide={setClose} centered>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header>
                  <Modal.Title>Add new Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="mb-3 d-flex justify-content-start">
                    <Dropdown
                      show={showDropdown}
                      onToggle={() => setShowDropdown(!showDropdown)}
                    >
                      <Dropdown.Toggle
                        className="d-flex align-items-center gap-2"
                        variant={"outline-secondary"}
                      >
                        {type === "dm"
                          ? "DM"
                          : type === "edm"
                          ? "EDM"
                          : "Select type of Document"}
                        <BsCaretDown />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => setType("dm")}>
                          DM
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => setType("edm")}>
                          EDM
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="d-flex w-100 gap-3 mb-3">
                    <FloatingLabel label="Name*" className="w-100">
                      <Form.Control
                        disabled={type === null}
                        {...register("name", {
                          required: "Document name is required",
                        })}
                      />
                    </FloatingLabel>

                    <FloatingLabel label="Code*">
                      <Form.Control
                        disabled={type === null}
                        {...register("code", {
                          required: "Document code is required",
                        })}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="">
                    <FloatingLabel label="Description">
                      <Form.Control
                        disabled={type === null}
                        {...register("description")}
                        as="textarea"
                      />
                    </FloatingLabel>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={setClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="success"
                    type="submit"
                    disabled={!isDirty && !!type}
                  >
                    Add
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        );
      }}
    />
  );
};

export default AddDocumentButton;
