import { Button, Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SelectClient from "../custom/SelectClient";
import { FaBuildingUser } from "react-icons/fa6";
import Breadcrumbs from "./Breadcrumbs";
import { useNotifications } from "../../context/NotificationsContext";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import PhoenixIcon from "../icons/PhoenixIcon";
import { BsList } from "react-icons/bs";
import FilterButtons from "../custom/FilterButtons";
import { useContext } from "react";
import LayoutContext from "../../context/LayoutContext";
import { CgMoreVerticalAlt } from "react-icons/cg";
import useStorage from "../../context/StorageContext";

const Header = ({ headerItems, pageTitle, path, breadcrumbsLabel }) => {
  const { client } = useStorage();
  const { showSelectClient, setShowSelectClient } = useNotifications();
  const { showMenu, setShowMenu } = useContext(LayoutContext);

  const { currentPage, activeModule } = useBreadcrumbs({
    path,
    pageTitle,
  });

  const Icon = activeModule?.icon;

  return (
    <Navbar
      bg="dark"
      variant="dark"
      fixed="top"
      data-bs-theme="dark"
      className={`header-top ${showMenu ? "menuOpen" : ""}`}
    >
      <Container fluid>
        <Nav className="align-items-center">
          <Button
            variant="link"
            onClick={() => setShowMenu((curr) => !curr)}
            className="menu-toggle-btn hover-icon d-sm-none"
          >
            <span className="icon-wrap">
              {Icon ? (
                <Icon className="main-icon module-icon" />
              ) : (
                <PhoenixIcon className="main-icon module-icon" />
              )}
              <BsList className="hovered-icon module-icon" />
            </span>
          </Button>

          {Icon ? (
            <Icon className="module-icon fade-in-grow d-none d-sm-inline" />
          ) : null}

          <div>
            <Breadcrumbs
              pageTitle={pageTitle}
              path={path}
              breadcrumbsLabel={breadcrumbsLabel}
              className="d-none d-sm-flex"
            />
            <h1 className="h3 mb-0">{currentPage?.title}</h1>
          </div>
        </Nav>
        <div className="flex-grow-1"></div>
        <FilterButtons />
        <Nav>
          {headerItems}

          <Nav.Link
            as={Button}
            onClick={() => setShowSelectClient(true)}
            title="Client"
            variant="link"
          >
            <FaBuildingUser className="fs-5 opacity-75" />
            <span className="link-text ps-2">
              <span className="font-monospace">
                {client?.code || "No client"}
              </span>
            </span>
            <CgMoreVerticalAlt className="ms-1 opacity-75 d-none d-xl-inline-block me-n2" />
          </Nav.Link>
        </Nav>
        <SelectClient show={showSelectClient} setShow={setShowSelectClient} />
      </Container>
    </Navbar>
  );
};

export default Header;
