import React, { useContext, useState } from "react";
import { config } from "../config";

const StorageContext = React.createContext();
export const useStorage = () => useContext(StorageContext);

// use useStateWithStorage to duplicate in localStorage

export const StorageProvider = ({ children }) => {
  const [customer, setCustomer] = useState(null);

  const customersStore = useState({});

  const variantsStore = useState({});

  const documentsStore = useState({});

  const [client, setClient] = useState(
    JSON.parse(localStorage.getItem("client")) ?? {
      id: config.auth.testClient,
      code: "TEST",
      customer: null,
    }
  );

  return (
    <StorageContext.Provider
      value={{
        customer,
        setCustomer,
        client,
        setClient,

        // list pages
        customersStore,
        variantsStore,
        documentsStore,
      }}
    >
      {children}
    </StorageContext.Provider>
  );
};

export default useStorage;
