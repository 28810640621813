import { Link } from "react-router-dom";
import useListPage from "../../../hooks/useListPage";
import { Card } from "react-bootstrap";
import ImgBox from "../../ui/ImgBox";
import { BsFileEarmarkTextFill } from "react-icons/bs";
import ListPage from "../../ui/ListPage";
import { config } from "../../../config";
import { useState } from "react";
import TreatmentFilters from "./TreatmentFilters";
import AddTreatment from "./AddTreatment";

const Treatments = () => {
  const [state, setValue] = useListPage("treatments");

  const { active = "", treatmentType = "" } = state;

  const setActive = (val) => setValue({ active: val, page: 1 });
  const setTreatmentType = (val) => setValue({ treatmentType: val, page: 1 });

  const [showDropdown, setShowDropdown] = useState(false);

  const options = [
    "standard",
    "uploadNotification",
    "quotation",
    "downloadToken",
    "customerApproval",
  ];

  const onClear = () => {
    setActive("");
    setTreatmentType("");
  };

  const filterCount =
    (active || active === false ? 1 : 0) + (treatmentType ? 1 : 0);

  const GridItem = ({ item, itemLink }) => {
    return (
      <Link className="text-decoration-none hover-parent" to={itemLink}>
        <Card className="border h-100 justify-content-between hover-border-primary thumbnail-card fade-in-grow-up delay">
          <Card.Body className="p-0">
            <div className="position-relative">
              <ImgBox
                imgId={item?.thumbnailFileId}
                icon={BsFileEarmarkTextFill}
                className="w-100 hover-zoom"
                params={{
                  Format: "jpg",
                  ResizeMode: "Contain",
                }}
              />
            </div>
          </Card.Body>
          <Card.Footer
            className="p-2 border-top-0 bg-px-dark-tint-85"
            style={{ flexBasis: "100%" }}
          >
            <Card.Title
              as="h6"
              className="mb-n1 pb-1 text-truncate fs-90 text-center"
            >
              {item?.name}
            </Card.Title>
            <span className="text-secondary opacity-75 fs-75 d-flex justify-content-center">
              {item?.mailpackCode}
            </span>
          </Card.Footer>
        </Card>
      </Link>
    );
  };

  return (
    <ListPage
      baseUrl={`${config.api.phoenix}/treatments`}
      urlParams={`&active=${active}&treatmentType=${treatmentType}`}
      itemLabel="treatments"
      itemPath="treatments"
      orderOptions={["id", "name", "code", "mailPackCode"]}
      defaultLayout="table"
      menuType="treatments"
      colorClass="treatments"
      storageKey="treatments"
      newItemBtn={<AddTreatment />}
      gridItem={GridItem}
      tableItems={{
        id: {},
        name: { cellWidth: 150 },
        description: {},
        code: {},
        mailpackCode: { cellWidth: 150, displayHeading: "Mailpack code" },
      }}
      searchProps={{ placeholder: "ID, name, code, mailpackCode, code" }}
      filters={
        <TreatmentFilters
          options={options}
          active={active}
          setActive={setActive}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
          treatmentType={treatmentType}
          setTreatmentType={setTreatmentType}
        />
      }
      onClear={onClear}
      filterCount={filterCount}
    />
  );
};
export default Treatments;
