import { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { config } from "../../../../config";
import { Button, Modal, Table } from "react-bootstrap";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import fetcher from "../../../../utils/fetcher";
import useStorage from "../../../../context/StorageContext";
import { useNotifications } from "../../../../context/NotificationsContext";
import { formatDateTime } from "../../../../utils/formatDate";

const LinkToOrder = ({ variant, customerId, programId, treatmentId }) => {
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [error, setError] = useState(null);
  const [showOpen, setShowOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [orderViewModels, setOrderViewModels] = useState([]);

  const { data: orders } = useFetch({
    //first fetch the orders based on the right program and customer
    url: `${config.api.order}/orders?customerId=${customerId}&programId=${programId}&pageSize=50`,
  });

  const { data: products } = useFetch({
    url: `${config.api.order}/products?programId=${programId}&treatmentId=${treatmentId}&pageSize=50`,
  });

  //this useEffect creates a list of models that contain all data of orders needed for display or to be submitted to endpoint
  useEffect(() => {
    if (!orders || orders.length === 0 || !products || products.length === 0)
      return;

    const models = [];

    orders.forEach((order) => {
      const productItems = [
        ...(order.productItems || []),
        ...(order.packageItems || []).flatMap(
          (packageItem) => packageItem.products || []
        ),
      ];

      const product = products[0]; //Within productItems we need to search for the first product fetched in products

      const productSet = new Set(); //can have multiple products with the same id in 1 order but still need the one within the right packageItem ord productItem set

      productItems.forEach((item) => {
        if (product.id === item.productId) {
          if (item.isCustom || product.treatmentId === treatmentId) {
            //we need the one with the right treatmentId of one that has isCustom = true
            productSet.add(product);
          }
        }
      });

      const newProduct = Array.from(productSet);

      const matchedProduct = newProduct[0]; //undo array

      if (!matchedProduct) return; //no product found

      models.push({
        id: order.id,
        programId: order.program.id,
        programName: order.program.name,
        participationDate: order.participationDate,
        orderDate: order.orderDate,
        customerCode: order.customer.code,
        customerName: order.customer.name,
        customerId: order.customer.id,
        modified: order.modified,
        productName: matchedProduct.name,
        productId: matchedProduct.id,
        productCode: matchedProduct.code,
      });
    });
    setOrderViewModels(models);
  }, [orders, products, treatmentId]);

  const handleLinkToOrder = () => {
    fetcher({
      url: `${config.api.order}/orders/${selectedOrder.id}`,
      method: "GET",
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        const etag = res.headers.etag;
        const matchingOrder = orderViewModels.find(
          (order) => order.id === selectedOrder.id
        );

        const payload = {
          orderItemId: matchingOrder.id,
          productId: matchingOrder.productId,
          productName: matchingOrder.productName,
          productCode: matchingOrder.productCode,
        };

        fetcher({
          url: `${config.api.order}/orders/${matchingOrder.id}/variants?variantId=${variant.id}`,
          method: "POST",
          payload: payload,
          customerId: variant.customer.id,
          clientId: client?.id,
          headers: {
            "If-Match": etag,
          },
        })
          .then(() => {
            setToasts((currToasts) => [
              ...currToasts,
              {
                id: Date.now(),
                variant: "success",
                text: "Variant is successfully linked to order ",
              },
            ]);
          })
          .catch((err) => {
            setError(err);
          });
      })
      .catch((err) => {
        setError(err);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to link variant to order",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <div className="w-100">
      <Modal
        centered
        size="xl"
        show={showOpen}
        onHide={() => {
          setShowOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Link to Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "450px", overflowY: "auto" }}>
            {Object.keys(orderViewModels).length > 0 ? (
              <Table striped hover>
                <thead>
                  <tr>
                    <th>id</th>
                    <th>Participation date</th>
                    <th>Order date</th>
                    <th>Customer code</th>
                    <th>Customer name</th>
                    <th>Program name</th>
                    <th>Modified</th>
                    <th>Product name</th>
                  </tr>
                </thead>
                <tbody>
                  {orderViewModels?.map((item) => (
                    <tr key={item.id} onClick={() => setSelectedOrder(item)}>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.id}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.participationDate ? (
                          <span>{formatDateTime(item.participationDate)}</span>
                        ) : null}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.orderDate ? (
                          <span>{formatDateTime(item.orderDate)}</span>
                        ) : null}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.customerCode}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.customerName}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.programName}
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        <span>{formatDateTime(item.modified)}</span>
                      </td>
                      <td
                        style={
                          selectedOrder.id === item.id
                            ? { backgroundColor: "rgba(0, 255, 0, 0.4)" }
                            : {}
                        }
                      >
                        {item.productName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <div className="w-100 d-flex alignt-items-center justify-content-center">
                {"No order can be linked to this variant"}
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowOpen(false);
              setSelectedOrder({});
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleLinkToOrder();
              setSelectedOrder({});
              setShowOpen(false);
            }}
            disabled={Object.keys(selectedOrder).length === 0}
            variant="success"
          >
            Link
          </Button>
        </Modal.Footer>
      </Modal>
      <Button variant="secondary" size="sm" onClick={() => setShowOpen(true)}>
        Link to Order
      </Button>
    </div>
  );
};
export default LinkToOrder;
