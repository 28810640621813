import {
  Button,
  Card,
  FloatingLabel,
  Form,
  Modal,
  Table,
} from "react-bootstrap";
import { config } from "../../../../config";
import { BsCheck2, BsPencil, BsPlus, BsTrash, BsX } from "react-icons/bs";
import { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import fetcher from "../../../../utils/fetcher";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import useStorage from "../../../../context/StorageContext";
import { useNotifications } from "../../../../context/NotificationsContext";

const GeneralContactDetails = ({ customer, customerId, contactTypes }) => {
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [showModal, setShowModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [removeItem, setRemoveItem] = useState(null);
  const [newContactTypes, setNewContactTypes] = useState([]);
  const [error, setError] = useState(null);

  const translateContactType = (contactType) => {
    switch (contactType) {
      case "Telefoon nr.":
        return { value: contactType, label: "Phone Number" };
      case "Mobiel nr.":
        return { value: contactType, label: "Mobile Number" };
      case "Overig":
        return { value: contactType, label: "Other" };
      default:
        return { value: contactType, label: contactType };
    }
  };

  useEffect(() => {
    if (!contactTypes) return;
    setNewContactTypes(contactTypes?.map(translateContactType));
  }, [contactTypes]);

  const { control, formState, register, reset, handleSubmit } = useForm({
    defaultValues: {
      contactDetails: customer.contactDetails || [],
    },
  });

  const { isDirty } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contactDetails",
    keyName: "fieldId",
  });

  const onSubmit = (data) => {
    const contactDetails = data?.contactDetails?.map((cd) => {
      if (!cd.id) {
        const { id, ...rest } = cd;
        return rest;
      }
      return cd;
    });

    const payload = {
      ...customer,
      contactDetails: contactDetails,
    };

    fetcher({
      method: "PUT",
      url: `${config.api.phoenix}/customers/${customerId}`,
      payload: payload,
      customerId: customerId,
      clientId: client?.id,
    })
      .then(() => {
        setShowModal(false);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to delete address",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Card className="mb-3 bg-dark bg-opacity-10">
      <Card.Header>
        <Card.Title>Contact Details</Card.Title>
      </Card.Header>
      <Card.Body style={{ padding: 0 }}>
        <div style={{ maxHeight: "450px", overflowY: "auto" }}>
          {customer?.contactDetails && customer?.contactDetails.length > 0 ? (
            <Table striped hover>
              <thead>
                <tr className="sticky-top">
                  <th>Type</th>
                  <th>Content</th>
                  <th>Comment</th>
                </tr>
              </thead>
              <tbody>
                {customer?.contactDetails?.map((item, index) => (
                  <tr key={item.id || index}>
                    <td>{item.type}</td>
                    <td>{item.value}</td>
                    <td>{item.notes}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : null}
        </div>
      </Card.Body>
      <Card.Footer>
        <div className="text-end">
          <Button
            type="button"
            variant="success"
            onClick={() => setShowModal(true)}
          >
            <BsPencil className="btn-icon me-1" /> Edit
          </Button>
        </div>
      </Card.Footer>

      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header
            closeButton
            style={{
              backgroundColor: removeItem !== null ? "black" : "",
              opacity: removeItem !== null ? 0.2 : 1,
            }}
          >
            <Modal.Title>Edit Contact Details</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              maxHeight: "450px",
              overflowY: "auto",
              backgroundColor: removeItem !== null ? "black" : "",
              opacity: removeItem !== null ? 0.2 : 1,
            }}
          >
            {fields?.map((field, index) => (
              <div key={field.fieldId} className="mb-5">
                <div className="d-flex align-items-center mb-3">
                  <div style={{ width: "30%", paddingRight: "15px" }}>
                    <FloatingLabel label="Type">
                      <Form.Select
                        {...register(`contactDetails.${index}.type`)}
                        defaultValue={newContactTypes[0]}
                      >
                        {newContactTypes?.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </FloatingLabel>
                  </div>
                  <div style={{ width: "70%", paddingRight: "15px" }}>
                    <FloatingLabel label="Value">
                      <Form.Control
                        {...register(`contactDetails.${index}.value`, {
                          required: "Value is required",
                          maxLength: {
                            value: 100,
                            message: "Value cannot exceed 100 characters",
                          },
                        })}
                        {...register(`contactDetails.${index}.value`)}
                      />
                    </FloatingLabel>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div style={{ width: "100%", paddingRight: "15px" }}>
                    <FloatingLabel label="Notes">
                      <Form.Control
                        {...register(`contactDetails.${index}.notes`)}
                        as="textarea"
                      />
                    </FloatingLabel>
                  </div>
                  <div style={{ paddingRight: "15px" }}>
                    <Button
                      variant="danger"
                      onClick={() => {
                        setRemoveItem(index);
                        setShowRemoveModal(true);
                      }}
                    >
                      <BsTrash />
                    </Button>
                  </div>
                </div>

                <input
                  type="hidden"
                  {...register(`contactDetails.${index}.id`)}
                />
              </div>
            ))}

            <Button
              type="button"
              variant="secondary"
              onClick={() =>
                append({
                  id: null,
                  type: newContactTypes[0]?.value || "",
                  value: "",
                  notes: "",
                })
              }
            >
              <BsPlus className="btn-icon me-1" /> Add Contact Detail
            </Button>
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: removeItem !== null ? "black" : "",
              opacity: removeItem !== null ? 0.2 : 1,
            }}
          >
            <Button
              variant="secondary"
              onClick={() => {
                setShowModal(false);
                reset();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" variant="success" disabled={!isDirty}>
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        style={{
          marginTop: "150px",
        }}
        show={showRemoveModal}
        onHide={() => {
          setRemoveItem(null);
          setShowRemoveModal(false);
        }}
      >
        <Modal.Header>
          <h4>{`Remove`}</h4>
        </Modal.Header>
        <Modal.Body>
          {`Are you sure you want to remove this contact detail?`}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            type="button"
            onClick={() => {
              setShowRemoveModal(false);
              setRemoveItem(null);
            }}
          >
            <BsX className="btn-icon me-1" /> No
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              remove(removeItem);
              setShowRemoveModal(false);
              setRemoveItem(null);
            }}
          >
            <BsCheck2 className="btn-icon me-1" /> Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default GeneralContactDetails;
