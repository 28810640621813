import { useEffect, useState } from "react";
import { useNotifications } from "../../../context/NotificationsContext";
import useStorage from "../../../context/StorageContext";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import fetcher from "../../../utils/fetcher";
import { config } from "../../../config";
import happy from "../../../assets/il-happy.svg";
import createDetailedErrorMessage from "../../../utils/detailMessageError";

const AddDocumentWrap = ({ initialType, component: Component }) => {
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [show, setShow] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [error, setError] = useState(null);
  const [type, setType] = useState(
    initialType === "DM" ? "dm" : initialType === "EDM" ? "edm" : ""
  );

  const setOpen = () => {
    setShow(true);
  };

  const setClose = () => {
    setShow(false);
    reset();
    setType(initialType === "DM" ? "dm" : initialType === "EDM" ? "edm" : "");
  };
  useEffect(() => {
    setType(initialType === "DM" ? "dm" : initialType === "EDM" ? "edm" : "");
  }, [initialType]);

  const navigate = useNavigate();

  const { register, reset, handleSubmit, formState } = useForm({
    defaultValues: {
      name: null,
      code: null,
      description: "",
    },
  });

  const { isDirty } = formState;

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      code: data.code,
      description: data.description,
      active: true,
      documentType: type === "edm" ? "edm" : "",
    };

    const dataUrl =
      type === "dm"
        ? `${config.api.dm}/documents`
        : type === "edm"
        ? `${config.api.edm}/documents`
        : "";

    fetcher({
      url: dataUrl,
      method: "POST",
      payload: payload,
      clientId: client?.id,
    })
      .then((res) => {
        navigate(`${type}/${res.data.id}`);
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            img: happy,
            heading: "Success!",
            text: (
              <div>
                {"Document "} <strong>{res.data.name}</strong>{" "}
                {"is successfully created."}
              </div>
            ),
          },
        ]);
      })
      .catch((err) => {
        setError(err);
      });
    reset();
    setShow(false);
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to add new Document",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  return (
    <Component
      show={show}
      setClose={setClose}
      setOpen={setOpen}
      type={type}
      setType={setType}
      register={register}
      reset={reset}
      handleSubmit={handleSubmit}
      isDirty={isDirty}
      onSubmit={onSubmit}
      showDropdown={showDropdown}
      setShowDropdown={setShowDropdown}
    />
  );
};
export default AddDocumentWrap;
