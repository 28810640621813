import { Col, ListGroupItem, Row } from "react-bootstrap";

const DocumentParameterListGroupItem = ({
  name,
  type,
  onClick = () => null,
  active = false,
}) => {
  return (
    <ListGroupItem
      className="flex-nowrap list-group-item-action"
      style={{ cursor: "pointer" }}
      onClick={onClick}
      active={active}
    >
      <Row className="flex-nowrap w-100">
        <Col className="overflow-hidden text-truncate w-50">
          <span>{name}</span>
        </Col>
        <Col className="overflow-hidden text-truncate w-50">
          <span>{type}</span>
        </Col>
      </Row>
    </ListGroupItem>
  );
};
export default DocumentParameterListGroupItem;
