import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { config } from "../../../../config";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import MarketingProfileSignatureItem from "./MarketingProfileSignatureItem";
import { BsCheck2, BsX } from "react-icons/bs";
import fetcher from "../../../../utils/fetcher";
import useStorage from "../../../../context/StorageContext";
import { useNotifications } from "../../../../context/NotificationsContext";

const CustomerMarketingProfileSignatures = ({
  mpId,
  refreshMp,
  signatures,
}) => {
  const { customerId } = useParams();
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [isBusy, setIsBusy] = useState(false);

  const [error, setError] = useState(null);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to upload or submit image",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const defaultValues = signatures ? Object.assign({}, signatures) : [];

  const {
    register,
    handleSubmit,
    formState,
    getValues,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues,
  });

  const { dirtyFields, isDirty } = formState;

  const onSubmit = (data) => {
    setIsBusy(true);
    fetcher({
      url: `${config.api.phoenix}/marketingprofiles/${mpId}/signatures`,
      method: "PUT",
      payload: Object.values(data),
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        refreshMp();
        setIsBusy(false);
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            heading: "Done",
            text: "Signatures updated successfully",
          },
        ]);
        reset({ ...data });
      })
      .catch((err) => {
        setError(err);
        setIsBusy(false);
        reset();
      });
  };

  const formSubmit = () => {
    onSubmit(getValues());
  };

  useEffect(() => {
    signatures?.length && reset(Object.assign({}, signatures));
    return () => {};
  }, [signatures, reset]);

  const onCancel = () => {
    reset({ ...defaultValues });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card className="mb-3 bg-dark bg-opacity-10">
        <Card.Header>Signatures</Card.Header>
        <Card.Body>
          <Row className="g-3">
            {!!signatures && signatures.length
              ? signatures.map((item, index) => (
                  <Col key={`${item}-${index}`} xl={6}>
                    <MarketingProfileSignatureItem
                      index={index}
                      item={item}
                      dirtyFields={dirtyFields}
                      setValue={setValue}
                      refreshSignatures={refreshMp}
                      formSubmit={formSubmit}
                      watch={watch}
                      register={register}
                      reset={reset}
                      defaultValues={defaultValues}
                      setError={setError}
                      customerId={customerId}
                      clientId={client?.id}
                      signatures={signatures}
                    />
                  </Col>
                ))
              : null}
          </Row>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
          <Button
            onClick={onCancel}
            disabled={!isDirty || isBusy}
            variant="secondary"
            type="button"
          >
            <BsX className="btn-icon me-1" /> Cancel
          </Button>
          <Button disabled={!isDirty || isBusy} type="submit" variant="success">
            <BsCheck2 className="btn-icon me-1" /> Save changes
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default CustomerMarketingProfileSignatures;
