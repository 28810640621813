import { Button, Card, Col, ListGroupItem, Row } from "react-bootstrap";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import CountBadge from "../../custom/CountBadge";
import TreatmentParameterListGroup from "./TreatmentParameterListGroup";
import AddTreatmentParameter from "./AddTreatmentParameter";

const TreatmentParameterView = ({
  treatment,
  parameters,
  zoomIn,
  setZoomIn,
  refresh,
}) => {
  return (
    <Card
      className=" bg-dark bg-opacity-10 w-100"
      style={zoomIn ? {} : { maxWidth: "1200px" }}
    >
      <Card.Header
        className="d-flex justify-content-between"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 200,
        }}
      >
        <div className="d-flex">
          <Card.Title>Treatment parameters</Card.Title>
          {!!parameters && parameters?.length > 0 && (
            <CountBadge
              count={parameters.length}
              successBg="secondary"
              className="fs-90 h-50 d-flex align-items-center"
            />
          )}
        </div>

        <Button variant="flush" onClick={() => setZoomIn(!zoomIn)}>
          {zoomIn ? <BsZoomOut /> : <BsZoomIn />}
        </Button>
      </Card.Header>

      <Card.Body>
        <ListGroupItem
          className="flex-nowrap"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 200,
            padding: 10,
          }}
        >
          <Row className="flex-nowrap">
            <Col xs="auto" style={{ flex: "1 1 10px" }}>
              <span className="d-none d-md-block">Seq</span>
            </Col>
            <Col
              xs="auto"
              className="overflow-hidden text-truncate"
              style={{ flex: "1 1 200px", minWidth: "150px" }}
            >
              <span>Name</span>
            </Col>
            <Col
              xs="auto"
              className="overflow-hidden text-truncate d-none d-lg-block"
              style={{ flex: "1 1 150px", minWidth: "100px" }}
            >
              <span>Display name</span>
            </Col>
            <Col
              xs="auto"
              className="overflow-hidden text-truncate d-none d-lg-block"
              style={{ flex: "1 1 150px", minWidth: "150px" }}
            >
              <span>Description</span>
            </Col>
            <Col
              xs="auto"
              className="min-w-3 d-flex justify-content-center"
              style={{ flex: "1 1 100px", minWidth: "80px" }}
            >
              <span>Value options</span>
            </Col>
            <Col
              xs="auto"
              className="min-w-3 d-flex justify-content-center"
              style={{ flex: "1 1 100px", minWidth: "80px" }}
            >
              Editable
            </Col>
            <Col
              xs="auto"
              className="overflow-hidden text-truncate d-none d-md-block"
              style={{ flex: "1 1 100px", minWidth: "80px" }}
            >
              <span>Inheritance</span>
            </Col>
          </Row>
        </ListGroupItem>
        <TreatmentParameterListGroup zoomIn={zoomIn} parameters={parameters} />
      </Card.Body>
      <Card.Footer className="justify-content-end d-flex">
        <AddTreatmentParameter treatment={treatment} refresh={refresh} />
      </Card.Footer>
    </Card>
  );
};
export default TreatmentParameterView;
