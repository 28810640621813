import React from "react";
import { Button, Card, CardBody, CardFooter, CardTitle } from "react-bootstrap";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

const ActionCard = ({ title, to, linkTitle, children }) => {
  return (
    <Card className="position-relative hover-border-primary h-100">
      <CardBody className="pb-0">
        <CardTitle as="h2" className="text-primary">
          {title}
        </CardTitle>
        {children}
      </CardBody>
      <CardFooter className="text-right bg-transparent border-0 pt-0">
        <Button
          className="stretched-link"
          as={Link}
          variant="link"
          to={to}
          title={linkTitle}
          size="lg"
        >
          <BsArrowRight />
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ActionCard;
