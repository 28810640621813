import React from "react";
import { Badge } from "react-bootstrap";

const CountBadgeProductions = ({
  total = 0,
  validated = 0,
  label,
  ...rest
}) => {
  const bg =
    !total && !validated
      ? "secondary-tint-50"
      : validated === total && total > 0
      ? "px-hl"
      : "primary-tint-50";

  return (
    <Badge
      bg={bg}
      className="text-center min-w-2 font-monospace"
      pill
      title={label}
      {...rest}
    >
      {validated || 0} / {total || 0}
    </Badge>
  );
};

export default CountBadgeProductions;
