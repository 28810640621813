import { useParams } from "react-router-dom";
import { config } from "../../../config";
import useFetch from "../../../hooks/useFetch";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import fetcher from "../../../utils/fetcher";
import useStorage from "../../../context/StorageContext";
import { useNotifications } from "../../../context/NotificationsContext";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import uploadFile from "../../../utils/fetcherUploadFile";

const DocumentWrap = ({ component: Component }) => {
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const { documentId, documentType } = useParams();
  const [editable, setEditable] = useState(false);
  const [error, setError] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [hover, setHover] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [zoomIn, setZoomIn] = useState(false);

  const dataUrl =
    documentType === "dm"
      ? `${config.api.dm}/documents/${documentId}`
      : documentType === "edm"
      ? `${config.api.edm}/documents/${documentId}`
      : "";

  const {
    data: document,
    fetchError,
    isValidating,
    mutate: refreshDM,
  } = useFetch({
    url: dataUrl,
    method: "GET",
  });

  const defaultValues = {
    active: document?.active,
    name: document?.name,
    code: document?.code,
    documentType:
      documentType === "dm"
        ? "XMPie document"
        : documentType === "edm"
        ? document?.documentType
        : "",
    description: document?.description,
    thumbnailFileId: document?.thumbnailFileId,
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitted, isDirty },
    reset,
    setValue,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (!!document) {
      reset({
        active: document.active,
        name: document.name,
        code: document.code,
        documentType:
          documentType === "dm"
            ? "XMPie document"
            : documentType === "edm"
            ? document?.documentType
            : "",
        description: document.description,
        thumbnailFileId: document.thumbnailFileId || "",
      });
    }
  }, [document, documentType, reset]);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to update Document",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const onCancel = () => {
    setEditable(false);
    reset(defaultValues);
  };

  const onSubmit = (data) => {
    setEditable(false);
    setIsBusy(true);

    fetcher({
      url: dataUrl,
      method: "PUT",
      payload: data,
      clientId: client?.id,
    })
      .then(() => {
        refreshDM();
        reset(data);
        setIsBusy(false);
      })
      .catch((err) => {
        setError(err);
        reset(defaultValues);
        setIsBusy(false);
      });
  };

  const handleOnFileUpload = (file, index) => {
    const format = file.name.split(".").pop();
    const allowedFormats = ["png", "jpg", "jpeg", "psd", "eps"];
    const fileName = file.name;
    const fileCategory = "CustomerUpload";
    const url = `${config.api.files}/Upload`;
    if (!allowedFormats.includes(format)) {
      setError(
        "Unsupported file format. Allowed formats are: PNG, JPG, JPEG, PSD, EPS."
      );
      return;
    }
    uploadFile({
      url: url,
      format: format,
      fileName: fileName,
      fileCategory: fileCategory,
      file: file,
      clientId: client?.id,
      onSuccess: (data) => {
        onSubmit({
          active: document.active,
          name: document.name,
          code: document.code,
          documentType: document.documentType,
          description: document.description,
          thumbnailFileId: data.fileId,
        });
        refreshDM();
      },
      onError: (err) => {
        removeFile();
        setError("Failed to upload image");
      },
    });
  };

  const removeFile = () => {
    onSubmit({
      active: document.active,
      name: document.name,
      code: document.code,
      documentType: document.documentType,
      description: document.description,
      thumbnailFileId: "",
    });
  };

  const removeImage = () => {
    removeFile();
    refreshDM();
  };

  const formProps = {
    register,
    watch,
    reset,
    setValue,
  };

  return (
    <Component
      document={document}
      fetchError={fetchError}
      isValidating={isValidating}
      refreshDM={refreshDM}
      handleSubmit={handleSubmit}
      editable={editable}
      onSubmit={onSubmit}
      errors={errors}
      isSubmitted={isSubmitted}
      setEditable={setEditable}
      onCancel={onCancel}
      isDirty={isDirty}
      isBusy={isBusy}
      documentType={documentType}
      removeImage={removeImage}
      handleOnFileUpload={handleOnFileUpload}
      formProps={formProps}
      defaultValues={defaultValues}
      hover={hover}
      setHover={setHover}
      showRemoveModal={showRemoveModal}
      setShowRemoveModal={setShowRemoveModal}
      zoomIn={zoomIn}
      setZoomIn={setZoomIn}
    />
  );
};

export default DocumentWrap;
