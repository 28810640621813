import React from "react";
import { BsBuilding, BsMegaphoneFill, BsPrinterFill } from "react-icons/bs";
import {
  FaEuroSign,
  FaSpaghettiMonsterFlying,
  FaTruckArrowRight,
} from "react-icons/fa6";

const SupplierTypeIcon = ({
  type = "advertisingAgency",
  className = "",
  iconClassName = "",
  secondaryIconClassName = "",
  ...rest
}) => {
  const types = {
    advertisingagency: BsMegaphoneFill,
    buyinggroup: FaEuroSign,
    distributor: FaTruckArrowRight,
    printer: BsPrinterFill,
    wapper: FaSpaghettiMonsterFlying,
  };
  const Icon = types?.[type.toLowerCase().replace(" ", "")];

  return Icon ? (
    <span
      className={`position-relative d-block ${className}`}
      style={{ width: "1em", height: "1em" }}
      {...rest}
    >
      <BsBuilding
        className={`position-absolute bottom-0 start-0 end-0 ${iconClassName}`}
        style={{ height: "70%" }}
      />
      <Icon
        className={`position-absolute top-0 start-50 translate-middle-x mb-n2 ${secondaryIconClassName}`}
        style={{ height: "28%" }}
      />
    </span>
  ) : (
    <BsBuilding className={className} {...rest} />
  );
};

export const SupplierTypeIconAdvertisingAgency = ({ ...props }) => (
  <SupplierTypeIcon type="advertisingAgency" {...props} />
);
export const SupplierTypeIconBuyingGroup = ({ ...props }) => (
  <SupplierTypeIcon type="buyingGroup" {...props} />
);
export const SupplierTypeIconDistributor = ({ ...props }) => (
  <SupplierTypeIcon type="distributor" {...props} />
);
export const SupplierTypeIconPrinter = ({ ...props }) => (
  <SupplierTypeIcon type="printer" {...props} />
);
export const SupplierTypeIconWapper = ({ ...props }) => (
  <SupplierTypeIcon type="wapper" {...props} />
);

export default SupplierTypeIcon;
