import { Button, Card, Col, ListGroupItem, Row } from "react-bootstrap";
import CountBadge from "../../custom/CountBadge";
import DocumentParameterListGroup from "./DocumentParameterListGroup";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import AddDocumentParameter from "./AddDocumentParameter";

const DocumentParameterView = ({
  parameters,
  zoomIn,
  setZoomIn,
  documentType,
  document,
  refresh,
}) => {
  return (
    <Card
      className="bg-dark bg-opacity-10 w-100"
      style={zoomIn ? {} : { maxWidth: "1200px" }}
    >
      <Card.Header
        className="d-flex justify-content-between"
        style={{ position: "sticky", top: 0, zIndex: 200 }}
      >
        <div className="d-flex">
          <Card.Title>Document parameters</Card.Title>
          {!!parameters && parameters.length > 0 && (
            <CountBadge
              count={parameters.length}
              successBg="secondary"
              className="fs-90 h-50 d-flex align-items-center"
            />
          )}
        </div>
        <Button variant="flush" onClick={() => setZoomIn(!zoomIn)}>
          {zoomIn ? <BsZoomOut /> : <BsZoomIn />}
        </Button>
      </Card.Header>
      <Card.Body>
        <ListGroupItem
          className="flex-nowrap"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 200,
            padding: 10,
          }}
        >
          <Row className="flex-nowrap w-100">
            <Col className="overflow-hidden text-truncate w-50">
              <span>Name</span>
            </Col>
            <Col className="overflow-hidden text-truncate w-50">
              <span>Type</span>
            </Col>
          </Row>
        </ListGroupItem>
        <DocumentParameterListGroup parameters={parameters} zoomIn={zoomIn} />
      </Card.Body>
      <Card.Footer className="justify-content-end d-flex">
        <AddDocumentParameter
          document={document}
          documentType={documentType}
          refresh={refresh}
        />
      </Card.Footer>
    </Card>
  );
};
export default DocumentParameterView;
