import React from "react";
import { typeIcons as productionTypeIcons } from "../../icons/ProductionTypeIcon";
import {
  BsEnvelopeExclamationFill,
  BsFillTicketPerforatedFill,
  BsQuestionDiamond,
  BsTranslate,
} from "react-icons/bs";
import camelCaseToText from "../../../utils/camelCaseToText";
import {
  RiCalendarScheduleLine, // RiCalendarScheduleFill,
} from "react-icons/ri";
import DropdownTextField from "../../custom/DropdownTextField";

// const mockTreatment = {
//   id: 0,
//   name: "string",
//   code: "string",
//   description: "string",

//   TODO: country flag
//   languageCode: "string", // NL-nl, EN-gb or NL, FR

//   Mailpack
//   TODO: icon
//   mailpackCode: "string",
//   mailPackNumber: "string",

//   TODO: icon
//   pruningPolicy: "pruneIfNeeded", // pruneIfNeeded, pruneFirst, pruneLast, doNotPrune

//   Postal
//   TODO: icon
//   postalClass: "string",
//   postalGroup: "string",

//   validTerm: 0,

//   active: true, // show if inactive

//   thumbnailFileId: "string",

//   type: "standard", // standard, uploadNotification, quotation, downloadToken, customerApproval

//   parameters: [
//     {
//       id: 0,
//       name: "string",
//       valueType: "string",
//       sequence: 0,
//       displayName: "string",
//       description: "string",
//       editableByCustomer: true,
//       inheritance: {
//         id: 0,
//         name: "string",
//         description: "string",
//       },
//       active: true,
//       valueOptions: [
//         {
//           sequence: 0,
//           value: "string",
//           isDefault: true,
//           displayName: "string",
//         },
//       ],
//     },
//   ],
//   documents: [
//     {
//       documentId: 0,
//       settingsProfileId: 0,
//       documentType: "email", //  email, directMail, sms, supportEmail, voucher,
//     },
//   ],
// };

const documentTypes = ["email", "directMail", "sms", "supportEmail", "voucher"];

const { dm, edm, sms } = productionTypeIcons;

const documentTypeIcons = {
  directMail: dm,
  email: edm,
  sms: sms,
  supportEmail: BsEnvelopeExclamationFill,
  voucher: BsFillTicketPerforatedFill,
};

const TreatmentDocumentTypeIcon = ({
  type,
  defaultIcon: DefaultIcon = BsQuestionDiamond,
  ...rest
}) => {
  const Icon = documentTypeIcons?.[type] || DefaultIcon;
  return <Icon val={type} {...rest} />;
};

const ExampleTreatment = () => {
  return (
    <main className="p-3 treatment">
      <div className="container-fluid">
        <h1>Treatment</h1>

        <div className="mt-4">
          <span className="icon-link">
            <RiCalendarScheduleLine className="text-module fs-4 opacity-75 me-1" />
            <span className="text-muted">valid term</span>
          </span>
        </div>

        <div>
          <span className="icon-link">
            <BsTranslate className="text-module fs-4 opacity-75 me-1" />
            <span className="text-muted">language code</span>
          </span>
        </div>

        <h5 className="mt-4">Document type</h5>

        {[...documentTypes, "unknown"].map((docType) => (
          <span
            key={docType}
            className={[
              "icon-link",
              "me-4",
              documentTypes?.indexOf(docType) < 0 ? "opacity-50" : "",
            ].join(" ")}
          >
            <TreatmentDocumentTypeIcon
              type={docType}
              className="text-module fs-4 opacity-75 me-1"
            />
            <span className="text-muted">
              {camelCaseToText(docType).toLowerCase()}
            </span>
          </span>
        ))}

        <DropdownTextField
          options={[
            "standard",
            "uploadNotification",
            "quotation",
            "downloadToken",
            "customerApproval",
          ]}
          value={"standard"}
          onChange={(value) => console.log(value)}
          placeholder="Select or type..."
        />
      </div>
    </main>
  );
};

export default ExampleTreatment;
