import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import PhoenixLogo from "../icons/PhoenixLogo";
import { TfiCommentsSmiley } from "react-icons/tfi";
import { useNotifications } from "../../context/NotificationsContext";
import LayoutContext from "../../context/LayoutContext";
import MenuIcon from "./MenuIcon";

const SideMenu = () => {
  const { handleShowOffCanvas } = useNotifications();
  const { showMenu, setShowMenu } = useContext(LayoutContext);
  const handleClick = () => {
    if (window?.innerWidth < 1200) {
      setTimeout(() => {
        setShowMenu(false);
      }, 200);
    }
  };
  const handleToggle = () => {
    setShowMenu((curr) => !curr);
  };

  return (
    <>
      <div
        className={`nav-backdrop ${showMenu ? "isOpen" : ""} `}
        onClick={() => setShowMenu(false)}
      />
      <nav
        className={`side-menu ${showMenu ? "isOpen" : ""} `}
        data-bs-theme="dark"
      >
        <div className="scrollbar-thin overflow-y-auto overflow-x-hidden">
          <span className="logo" onClick={handleToggle}>
            <PhoenixLogo className="hover-icon" />
          </span>
          <div className="nav-inner colored-links" onClick={handleClick}>
            <NavLink to="/" title="Dashboard" className="dashboard">
              {/* <BsSpeedometer size={20} />{" "} */}
              <MenuIcon id="dashboard" size={20} />{" "}
              <span className="link-text">Dashboard</span>
            </NavLink>
            <NavLink to="/customers" title="Customers" className="customer">
              <MenuIcon id="customer" />{" "}
              <span className="link-text">Customers</span>
            </NavLink>
            <NavLink to="/orders" title="Order History" className="order">
              <MenuIcon id="order" /> <span className="link-text">Orders</span>
            </NavLink>
            <NavLink to="/variants" title="Variants" className="variant">
              <MenuIcon id="variant" />{" "}
              <span className="link-text">Variants</span>{" "}
            </NavLink>
            <NavLink to="/programs/campaigns" className="campaign">
              <MenuIcon id="campaign" />{" "}
              <span className="link-text">Campaigns</span>
            </NavLink>
            <NavLink to="/programs/cycles" className="cycle">
              <MenuIcon id="cycle" />{" "}
              <span className="link-text">Loyalty cycles</span>
            </NavLink>
            <NavLink to="/treatments" className="treatment">
              <MenuIcon id="treatment" />{" "}
              <span className="link-text">Treatments</span>
            </NavLink>
            <NavLink to="/products">
              <MenuIcon id="product" className="product" />{" "}
              <span className="link-text">Products</span>
            </NavLink>
            <NavLink to="/packages" className="package">
              <MenuIcon id="package" />{" "}
              <span className="link-text">Packages</span>
            </NavLink>
            <NavLink to="/reports/actionlist" className="actionList">
              <MenuIcon id="actionList" size={19} />{" "}
              <span className="link-text">Action lists</span>
            </NavLink>
            <NavLink to="/reports/quicksight" className="quickSight">
              <MenuIcon id="quicksight" size={20} />{" "}
              <span className="link-text">Reports</span>
            </NavLink>
            <NavLink to="/productions" className="production">
              <MenuIcon id="production" size={18} />{" "}
              <span className="link-text">Productions</span>
            </NavLink>
            <NavLink to="/documents" className="documents">
              <MenuIcon id="documents" size={19} />
              <span className="link-text">Documents</span>
            </NavLink>
          </div>
        </div>
        <div className="nav-inner no-b-t">
          {/* <button className="btn-link no-b-b" onClick={handleShowOffCanvas}>
            <BsFillQuestionCircleFill />{" "}
            <span className="link-text">Help</span>
          </button> */}
          <button className="btn-link no-b-b" onClick={handleShowOffCanvas}>
            <TfiCommentsSmiley /> <span className="link-text">Feedback</span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default SideMenu;
