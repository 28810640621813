import { useState, useEffect } from "react";
import {
  Button,
  FormSelect,
  InputGroup,
  Modal,
  Stack,
  Table,
} from "react-bootstrap";
import WithLoaderAndError from "./WithLoaderAndError";
import fetcher from "../../utils/fetcher";
import SearchBox from "./SearchBox";
import PaginationButtons from "./PaginationButtons";
import { BsArrowDownRight, BsArrowUpRight } from "react-icons/bs";
import NoResults from "../ui/NoResults";
import useStorage from "../../context/StorageContext";
import { formatDateTime } from "../../utils/formatDate";

const SearchModal = ({
  show,
  onHide,
  title,
  fetchUrl,
  directionOptions = [],
  orderOptions = [],
  onSelect,
  type = "default",
  items,
  programName = "program",
  search = true,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { client } = useStorage();
  const [orderBy, setOrderBy] = useState(
    !!orderOptions && orderOptions?.length > 0 ? orderOptions[0] : null
  );
  const [direction, setDirection] = useState(
    !!directionOptions && directionOptions?.length > 0
      ? directionOptions[0]
      : null
  );
  const PAGE_SIZE = 30;

  const [data, setData] = useState(
    !!items ? items : !!fetchUrl ? fetchUrl : null
  );

  useEffect(() => {
    const buildUrl = () => {
      if (!!fetchUrl) {
        const url = new URL(fetchUrl, window.location.origin);

        // Only set query params if they have values:
        if (!!searchQuery) {
          url.searchParams.set("query", searchQuery);
        }
        if (!!orderBy) {
          url.searchParams.set("orderBy", orderBy);
        }
        if (!!direction) {
          url.searchParams.set("direction", direction);
        }

        url.searchParams.set("page", page);
        url.searchParams.set("pageSize", PAGE_SIZE);

        return url.toString();
      }
    };

    if (!fetchUrl && !items) return;
    if (!!items) {
      setData(items);
    } else if (!!fetchUrl) {
      const url = buildUrl();
      setIsLoading(true);
      fetcher({
        url: url,
        method: "GET",
        customerId: client?.customer?.id,
        clientId: client?.id,
      })
        .then((res) => {
          setData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, [
    fetchUrl,
    items,
    client,
    direction,
    orderBy,
    page,
    PAGE_SIZE,
    searchQuery,
  ]);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  const handleRowClick = (item) => {
    onSelect(item);
    onHide();
  };

  const handleCanel = () => {
    onHide();
    setError(null);
  };

  return (
    <Modal centered show={show} onHide={handleCanel} backdrop size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{title || "Search"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: 5 }}>
          {search === true && (
            <SearchBox
              placeholder="Search on name, code, description"
              maxWidth={350}
              className="w-100"
              query={searchQuery}
              setQuery={setSearchQuery}
              size="md"
              clearSearchStyle={{
                position: "absolute",
                zIndex: "999",
                top: "7px",
                left: "320px",
                color: "red",
              }}
            />
          )}
        </div>

        <WithLoaderAndError
          isLoading={!!fetchUrl ? isLoading : false}
          error={!!fetchUrl ? error : null}
        >
          <div style={{ minHeight: 200, maxHeight: 400, overflowY: "auto" }}>
            {Array.isArray(data) && data?.length > 0 ? (
              <Table striped hover>
                <thead className="sticky-top">
                  {type === "mailmoment" ? (
                    <tr>
                      <th>Mail Moment name</th>
                      <th>Mail Moment code</th>
                      <th>Program name</th>{" "}
                    </tr>
                  ) : type === "segment" ? (
                    <tr>
                      <th>Segment name </th>
                    </tr>
                  ) : type === "treatment" ? (
                    <tr>
                      <th>Treatment name</th>
                      <th>Treatment code</th>
                      <th>Description</th>
                      <th>Created</th>
                    </tr>
                  ) : (
                    <tr>
                      <th>name</th>
                      <th>id</th>
                      <th>description</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {Array.isArray(data) && data?.length > 0 ? (
                    data.map((item) => (
                      <tr
                        key={item.id}
                        onClick={() => handleRowClick(item)}
                        style={{ cursor: "pointer" }}
                      >
                        {type === "mailmoment" ? (
                          <>
                            <td>{item.name}</td>
                            <td>{item.code}</td>
                            <td>{programName}</td>
                          </>
                        ) : type === "segment" ? (
                          <td>{item.name}</td>
                        ) : type === "treatment" ? (
                          <>
                            <td style={{ width: "20%" }}>{item.name}</td>
                            <td style={{ width: "20%" }}>{item.code}</td>
                            <td style={{ width: "35%" }}>
                              {item?.description?.length > 30
                                ? `${item.description.slice(0, 30)}...`
                                : item.description}
                            </td>
                            <td>
                              <span>{formatDateTime(item.created)}</span>
                            </td>
                          </>
                        ) : (
                          <>
                            <td>{item.name}</td>
                            <td>{item.id || item.treatmentCode}</td>
                            <td>{item.description}</td>
                          </>
                        )}
                      </tr>
                    ))
                  ) : type === "segment" ? (
                    <tr
                      onClick={() => handleRowClick(data)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{data.name}</td>
                    </tr>
                  ) : (
                    <div className="w-100 d-flex justify-content-center align-items-center no-results-wrap">
                      <NoResults img="tumbleweed" animation="tumbleweed" />
                    </div>
                  )}
                </tbody>
              </Table>
            ) : (
              <div className="w-100 d-flex justify-content-center align-items-center no-results-wrap">
                <NoResults img="tumbleweed" animation="tumbleweed" />
              </div>
            )}
          </div>
        </WithLoaderAndError>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex justify-content-between">
          <div style={{ width: "50%" }}>
            {!!orderOptions &&
            orderOptions?.length > 0 &&
            !!directionOptions &&
            directionOptions?.length > 0 ? (
              <Stack gap={2} direction="horizontal" className="w-auto">
                <span className="small">Sort by</span>
                <InputGroup size="sm" className="w-auto align-items-center">
                  <FormSelect
                    aria-label="Sort by"
                    defaultValue={orderBy}
                    onChange={(e) => setOrderBy(e.target.value)}
                  >
                    {orderOptions.map((item) => (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    ))}
                  </FormSelect>
                  <Button
                    variant="light"
                    onClick={() =>
                      setDirection((curr) =>
                        curr === "ascending" ? "descending" : "ascending"
                      )
                    }
                    title={direction}
                  >
                    {direction === "ascending" ? (
                      <BsArrowUpRight title={"ascending"} />
                    ) : (
                      <BsArrowDownRight title={"descending"} />
                    )}
                  </Button>
                </InputGroup>
              </Stack>
            ) : null}
          </div>
          <PaginationButtons
            page={page}
            setPage={setPage}
            pageSize={30}
            isLoading={isLoading}
          />
          <div style={{ width: "50%" }} className="d-flex justify-content-end">
            <Button
              style={{ width: "50%" }}
              variant="secondary"
              onClick={handleCanel}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SearchModal;
