import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import fetcher from "../../../../utils/fetcher";
import { config } from "../../../../config";
import FormComponent from "../../../ui/FormComponent";
import { BsPencil, BsTrash } from "react-icons/bs";
import { Button, Col, Modal, Row } from "react-bootstrap";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import useStorage from "../../../../context/StorageContext";
import { useNotifications } from "../../../../context/NotificationsContext";

const Birthday = ({ formData }) => {
  const MONTHS = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const formatDoB = (date) => {
    if (!date) {
      return { day: "", month: "", year: "" };
    }
    const [datePart] = date.split("T");
    const [year, month, day] = datePart.split("-");
    return {
      day: day,
      month: month,
      year: year,
    };
  };

  return (
    <Row className="g-3 mb-3 align-items-center">
      <Col style={{ maxWidth: "20%" }}>Birthday</Col>
      <Col>
        <FormComponent.Field
          fieldName="day"
          fieldLabel="Day"
          type="number"
          fieldDefaultValue={formatDoB(formData?.dateOfBirth)?.day}
          fieldMinNumber={1}
          fieldMaxNumber={31}
          fieldMaxLength={2}
        />
      </Col>
      <Col>
        <FormComponent.Field
          fieldName="month"
          fieldLabel="Month"
          type="select"
          fieldOptions={MONTHS}
          fieldDefaultValue={
            MONTHS[formatDoB(formData?.dateOfBirth)?.month - 1]?.value
          }
        />
      </Col>
      <Col>
        <FormComponent.Field
          fieldName="year"
          fieldLabel="Year"
          type="number"
          fieldDefaultValue={formatDoB(formData?.dateOfBirth)?.year}
          fieldMaxLength={4}
          fieldMinNumber={1900}
        />
      </Col>
    </Row>
  );
};

const CustomerContactDetailsForm = ({ formData, onSucces, contactTypes }) => {
  const { customerId } = useParams();
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const method = !!formData ? "PUT" : "POST";
  const id = !!formData ? formData.id : null;
  const dataUrl = `${config.api.phoenix}/persons${!!id ? "/" + id : ""}`;

  const OPTIONGENDER = [
    { value: "unknown", label: "Unknown" },
    { value: "female", label: "Female" },
    { value: "male", label: "Male" },
  ];

  const OPTIONSROLES = [
    "Contactperson campaigns",
    "Manager",
    "Accounting",
    "CampaignList recipient",
    "Contact person",
    "Owner",
    "Second owner",
  ];

  const [contacts, setContacts] = useState(
    !!formData && !!formData.contactDetails && !!formData.contactDetails.length
      ? formData.contactDetails.map((item, index) => index)
      : [0]
  );

  const addContact = () =>
    setContacts([
      ...contacts,
      contacts.length > 0 ? contacts[contacts.length - 1] + 1 : 0,
    ]);

  const deleteContact = (id) =>
    setContacts(contacts.filter((item) => item !== id));

  const [roles, setRoles] = useState(
    !!formData && !!formData.roles && !!formData.roles.length
      ? formData?.roles.map((item, index) => index)
      : [0]
  );
  const addRole = () =>
    setRoles([...roles, roles.length > 0 ? roles[roles.length - 1] + 1 : 0]);
  const deleteRole = (id) => setRoles(roles?.filter((item) => item !== id));

  const onSubmit = (data) => {
    fetcher({
      url: `${dataUrl}`,
      method: method,
      payload: {
        customerId: customerId,
        gender: data.gender.value,
        prefix: data.prefix,
        initials: data.initials,
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth:
          !!data.year && !!data.month && !!data.day
            ? `${data.year}-${data.month}-${String(data.day).padStart(2, "0")}`
            : null,
        contactDetails: contacts.map((id) => ({
          type: data["contactType" + id],
          value: data["contactValue" + id],
          sequence: 0,
          notes: null,
        })),
        roles: roles.length > 0 ? roles?.map((id) => data["role" + id]) : null,
      },
      customerId: customerId,
      clientId: client?.id,
    })
      .then((res) => {
        setShowModal(false);
        onSucces();
      })
      .catch((err) => {
        setError(err);
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to add or edit contact person",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const translateContactType = (contactType) => {
    switch (contactType) {
      case "Telefoon nr.":
        return { value: contactType, label: "Phone Number" };
      case "Mobiel nr.":
        return { value: contactType, label: "Mobile Number" };
      case "Overig":
        return { value: contactType, label: "Other" };
      default:
        return { value: contactType, label: contactType };
    }
  };

  const newContactTypes = contactTypes?.map(translateContactType);
  return (
    <FormComponent
      dataUrl={dataUrl}
      method={method}
      content={formData}
      customOnSubmit={onSubmit}
    >
      {!!formData ? (
        <FormComponent.EditModal
          setShowModal={setShowModal}
          variant="link"
          size="sm"
        >
          <BsPencil className="btn-icon me-1" /> Edit
        </FormComponent.EditModal>
      ) : (
        <FormComponent.EditModal setShowModal={setShowModal} variant="primary">
          Add contact person
        </FormComponent.EditModal>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header>
          <Modal.Title>
            {!!formData ? "Edit contact person" : "Add contact person"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="g-3 mb-3">
            <FormComponent.Field
              fieldName="gender"
              fieldLabel="Gender*"
              type="select"
              fieldOptions={OPTIONGENDER}
            />
          </Row>
          <Row className="g-3 mb-3">
            <Col>
              <FormComponent.Field fieldName="prefix" fieldLabel="Prefix" />
            </Col>
            <Col>
              <FormComponent.Field fieldName="initials" fieldLabel="Initials" />
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <Col>
              <FormComponent.Field
                fieldName="firstName"
                fieldLabel="Firstname"
              />
            </Col>
            <Col>
              <FormComponent.Field fieldName="lastName" fieldLabel="Lastname" />
            </Col>
          </Row>
          <Row className="g-3 mb-3">
            <Birthday formData={formData} />
          </Row>

          {!!contacts && (
            <React.Fragment>
              <Row
                className="g-3 mb-3 d-flex justify-content-center"
                style={{ marginTop: "1.0em" }}
              >
                Contact Details:{" "}
              </Row>
              {contacts.map((id, index) => (
                <Row key={id} className="d-flex align-items-center g-3 mb-3">
                  <Col md={5}>
                    <FormComponent.Field
                      fieldName={"contactType" + id}
                      fieldLabel="Type"
                      fieldDefaultValue={formData?.contactDetails[index]?.type}
                      type="select"
                      fieldOptions={newContactTypes}
                    />
                  </Col>
                  <Col md={6}>
                    <FormComponent.Field
                      fieldName={"contactValue" + id}
                      fieldDefaultValue={formData?.contactDetails[index]?.value}
                      fieldLabel="contact"
                    />
                  </Col>
                  <Col>
                    <Button
                      style={{ padding: "0px" }}
                      size="sm"
                      variant="flush"
                      onClick={() => deleteContact(id)}
                    >
                      {<BsTrash color="red" />}
                    </Button>
                  </Col>
                </Row>
              ))}
              <Button
                variant="link"
                onClick={() => addContact()}
                style={{ marginBottom: "1.5em" }}
              >
                + Add contact
              </Button>
            </React.Fragment>
          )}

          {!!roles && (
            <React.Fragment>
              <Row className="g-3 mb-3 d-flex justify-content-center">
                Roles:{" "}
              </Row>
              {roles?.map((id, index) => (
                <React.Fragment key={id}>
                  <Row className="d-flex align-items-center g-3 mb-3">
                    <Col md={11}>
                      <FormComponent.Field
                        fieldName={"role" + id}
                        fieldDefaultValue={formData?.roles[index]}
                        fieldLabel="Type"
                        type="select"
                        fieldArrayOptions={OPTIONSROLES}
                      />
                    </Col>
                    <Col>
                      <Button
                        style={{ padding: "0px" }}
                        size="sm"
                        variant="flush"
                        onClick={() => deleteRole(id)}
                      >
                        {<BsTrash color="red" />}
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
              <Button variant="link" onClick={() => addRole()}>
                + Add role
              </Button>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <FormComponent.CancelModal
            variant="primary"
            setShowModal={setShowModal}
            formData={formData}
          />
          <FormComponent.SaveModal variant="success" />
        </Modal.Footer>
      </Modal>
    </FormComponent>
  );
};

export default CustomerContactDetailsForm;
