import { Badge, ListGroup, Stack } from "react-bootstrap";
import useClients from "../../../hooks/useClients";
import WithLoaderAndError from "../../ui/WithLoaderAndError";
import PaginationFromHeader from "../../ui/PaginationFromHeader";

const ClientListGroup = ({ selectedClient, setSelectedClient }) => {
  const {
    handleClick,
    clients,
    clientsError,
    headers,
    clientsIsValidating,
    setPage,
    page,
    PAGE_SIZE,
  } = useClients({
    selectedClient: selectedClient,
    setSelectedClient: setSelectedClient,
  });

  return (
    <WithLoaderAndError
      isLoading={!clients && clientsError && clientsIsValidating}
      error={clientsError}
    >
      <ListGroup
        className="scrollbar-thin"
        style={{
          "--bs-list-group-active-bg": "#006891",
          "--bs-list-group-active-border-color": "#006891",
          maxHeight: 425,
          overflowY: "auto",
          cursor: "pointer",
        }}
      >
        {clients?.map((client) => (
          <ListGroup.Item
            as={Stack}
            direction="horizontal"
            className="justify-content-between"
            key={client.id}
            active={client.id === selectedClient?.id}
            onClick={() => handleClick(client)}
          >
            <Badge
              bg={selectedClient?.id === client.id ? "dark" : "primary"}
              className={`${
                selectedClient?.id === client.id ? "" : "hover-opacity-0"
              } bg-opacity-50 text-light text-opacity-75 font-monospace me-2 ms-n1`}
            >
              {client.id}
            </Badge>
            <span className="flex-grow-1 ms-1 small">{client.name}</span>
            <span
              className={`${
                selectedClient?.id === client.id ? "" : "hover-opacity-0"
              } font-monospace small opacity-75`}
            >
              {client.code}
            </span>
          </ListGroup.Item>
        ))}
      </ListGroup>
      {!!headers?.link ? (
        <div
          className="d-flex justify-content-center"
          style={{ paddingTop: 10 }}
        >
          <PaginationFromHeader
            current={page}
            linkStr={headers.link}
            setPage={setPage}
            showButtons
            pageSize={PAGE_SIZE}
          />
        </div>
      ) : null}
    </WithLoaderAndError>
  );
};

export default ClientListGroup;
