// Format date as "1 Mar 2025" or "1 Mar 2025 12:00"

const formatDate = (
  unformattedDate,
  options = {},
  timeOptions = { showTime: false, showSeconds: false }
) => {
  const date = new Date(unformattedDate);

  const defaultOptions = { day: "numeric", month: "short", year: "numeric" };
  const mergedOptions = { ...defaultOptions, ...options };

  const formattedDate = new Date(date).toLocaleDateString(
    "en-GB",
    mergedOptions
  );

  const hours = new Date(date).getHours();
  const minutes = new Date(date).getMinutes();
  const seconds = new Date(date).getSeconds();
  const formattedTime = !timeOptions.showTime
    ? ""
    : ` ${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}${
        timeOptions.showSeconds ? ":" + seconds.toString().padStart(2, "0") : ""
      }`;

  return `${formattedDate}${formattedTime}`;
};

// Shorthand for date-time format: "1 Mar 2025 12:00"
export const formatDateTime = (date) =>
  formatDate(date, {}, { showTime: true });

export default formatDate;
