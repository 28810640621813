import React from "react";
import { Card, Col, Container, Row, Stack } from "react-bootstrap";
import { BsInfoCircleFill } from "react-icons/bs";
import { FaEnvelopesBulk } from "react-icons/fa6";
import StatusBadgeProductions from "../StatusBadgeProductions";
import ProductionTypeIcon from "../../../icons/ProductionTypeIcon";
import ActionCard from "./ActionCard";

const ProductionsHome = () => {
  const statuses = [
    "created",
    "validated",
    "scheduled",
    "sent",
    "finished",
    "aborted",
  ];
  const types = ["DM", "eDM", "SMS"];
  return (
    <Container className="pt-5">
      <FaEnvelopesBulk
        className="text-module h-auto"
        style={{ width: "4em" }}
      />
      <h1 className="display-1 mb-3">Productions</h1>

      <div className="mb-4">
        <Row>
          <Col>
            <ActionCard
              title="Productions"
              to="productions"
              linkTitle="View productions"
            >
              <div>
                <span className="fw-semibold">validate</span>,{" "}
                <span className="fw-semibold">schedule</span>,{" "}
                <span className="fw-semibold">send</span> or{" "}
                <span className="fw-semibold">abort</span> productions
              </div>
            </ActionCard>
          </Col>
          <Col>
            <ActionCard title="Bundles" to="bundles" linkTitle="Manage bundles">
              <div>
                group productions into{" "}
                <span className="fw-semibold">bundles</span>
              </div>
            </ActionCard>
          </Col>

          <Col>
            <ActionCard title="Import" to="new" linkTitle="New production">
              <div>
                manually create a production from a{" "}
                <span className="fw-semibold">csv template</span>
              </div>
            </ActionCard>
          </Col>
        </Row>
      </div>

      <Card body className="mb-4 border-2" style={{ borderStyle: "dashed" }}>
        <BsInfoCircleFill className="fs-1 text-secondary-tint-50 mb-3" />

        <div className="mb-4">
          <h6 className="text-muted fs-80 opacity-75">Production type</h6>
          <Stack gap={4} direction="horizontal" className="flex-wrap px-3">
            {[...types, "undetermined"].map((type) => (
              <span
                key={type}
                className={types?.indexOf(type) < 0 ? "opacity-50" : ""}
              >
                <span key={type} className="icon-link">
                  <ProductionTypeIcon
                    type={type.toLowerCase()}
                    className="text-module fs-4 opacity-75"
                  />{" "}
                  <span className="font-monospace text-module">{type}</span>
                </span>
              </span>
            ))}
          </Stack>
        </div>

        <div>
          <h6 className="text-muted fs-80 opacity-75">Production status</h6>
          <Stack gap={3} direction="horizontal" className="flex-wrap px-3">
            {statuses.map((status) => (
              <StatusBadgeProductions status={status} key={status} />
            ))}
          </Stack>
        </div>
      </Card>
    </Container>
  );
};

export default ProductionsHome;
