import React, { useContext, useMemo } from "react";
import { config } from "../config";

export const AuthContext = React.createContext();
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const authStore = useMemo(() => {
    const login = () => {
      window.location.href = `${config.auth.bffUrl}/auth/login?returnUrl=${config.auth.appUrl}/onlogin`;
    };

    const logout = () => {
      window.location.href = `${config.auth.bffUrl}/auth/logout?returnUrl=${config.auth.appUrl}/onlogout`;
    };

    return {
      login,
      logout,
    };
  }, []);

  return (
    <AuthContext.Provider value={authStore}>{children}</AuthContext.Provider>
  );
};
