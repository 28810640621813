import React, { useState, useEffect } from "react";
import {
  Badge,
  FormSelect,
  InputGroup,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { config } from "../../config";
import {
  BsArrowRight,
  BsCalendarCheckFill,
  BsCalendarWeekFill,
  BsCalendarXFill,
  BsMegaphoneFill,
  BsXCircleFill,
} from "react-icons/bs";
import { IoInfinite } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { FaSyncAlt } from "react-icons/fa";
import ListPage from "../ui/ListPage";
import StatusBadge from "../custom/StatusBadge";
import CycleIcon from "../icons/CycleIcon";
import ImgBox from "../ui/ImgBox";
import ItemCard from "../ui/ItemCard";
import { PROGRAM_TYPE } from "../../constants";
import formatDate from "../../utils/formatDate";

const Programs = () => {
  const { programType: programTypeUrl } = useParams();

  const [programType, setProgramType] = useState(
    programTypeUrl === "campaigns" || programTypeUrl === "campaign"
      ? "campaign"
      : programTypeUrl === "cycles" || programTypeUrl === "cycle"
      ? "cycle"
      : ""
  );

  const programTypeParam = !programType ? "" : `&type=${programType}`;

  useEffect(() => {
    setProgramType(
      programTypeUrl === "campaigns"
        ? "campaign"
        : programTypeUrl === "cycles"
        ? "cycle"
        : ""
    );
  }, [programTypeUrl]);

  const GridItem = ({ item }) => (
    <ItemCard
      {...item}
      itemPath={`programs/${item.programType}`}
      thumbIcon={item?.programType === "cycle" ? CycleIcon : BsMegaphoneFill}
      imgBoxProps={{
        width: 300,
        className: item?.programType === "cycle" ? "text-px-dark-tint-25" : "",
      }}
    />
  );

  const ProgramTypeSelect = () => {
    if (!!programTypeUrl) {
      return null;
    }
    return (
      <InputGroup size="sm" className="w-auto">
        <InputGroup.Text>Type</InputGroup.Text>
        <FormSelect
          aria-label="Type"
          value={programType}
          onChange={(e) => setProgramType(e.target.value)}
        >
          <option value="">any</option>
          {Object.values(PROGRAM_TYPE).map((item) => (
            <option value={item} key={item}>
              {item}
            </option>
          ))}
        </FormSelect>
      </InputGroup>
    );
  };

  const getTableItems = (programType) => {
    const thumbnailFileIdProp =
      programType === "campaign"
        ? {
            thumbnailFileId: {
              cellType: "thumbnail",
              component: ({ value }) => (
                <ImgBox
                  imgId={value}
                  width={38}
                  whiteBox
                  whiteBoxWidth={38}
                  style={{ maxHeight: "38px" }}
                  noStretch
                  icon={
                    programType === "campaign" ? BsMegaphoneFill : CycleIcon
                  }
                  className="fade-in-grow delay m-n1"
                />
              ),
            },
          }
        : {};

    const tableItems = {
      ...thumbnailFileIdProp,
      id: {},
      sequence: {
        displayHeading: "#",
        cellClass: "text-center",
      },
      programType: {
        component: ({ value }) => (
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltipprogramType${value}`}>{value}</Tooltip>
            }
          >
            <span className="cursor-pointer">
              {value === "cycle" ? (
                <FaSyncAlt
                  title="cycle"
                  className="text-primary-tint-25 fade-in delay"
                />
              ) : (
                <BsMegaphoneFill
                  title="campaign"
                  className="text-primary-tint-25 fade-in delay"
                />
              )}
            </span>
          </OverlayTrigger>
        ),
        displayHeading: "Type",
        cellClass: "text-center ",
        cellWidth: 46,
        bringToFront: true,
      },
      name: {},
      theme: {
        component: ({ value }) => (
          <span className="small text-muted">{value}</span>
        ),
        cellClass: "text-start",
      },
      dropDateSchedules: {
        component: ({ value }) => {
          return value?.length ? (
            <OverlayTrigger
              placement="left"
              overlay={
                <Popover id="tooltipdropDateSchedules">
                  <Popover.Body className="p-1">
                    <ListGroup variant="flush">
                      {value.map((item, i) => (
                        <ListGroupItem key={`${item}${i}`}>
                          <div>
                            <BsCalendarCheckFill className="text-success-tint-25 me-1" />
                            <span>{formatDate(item.dropDateStart)}</span>
                            <BsArrowRight className="text-secondary-tint-25 mx-2" />
                            <BsCalendarXFill className="text-primary-tint-50 me-1" />
                            <span>{formatDate(item.dropDateEnd)}</span>
                          </div>
                          <div>
                            <BsXCircleFill className="text-danger-tint-50 me-1" />
                            <span className="text-danger-tint-25">
                              {formatDate(item.orderDeadline)}
                            </span>
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </Popover.Body>
                </Popover>
              }
            >
              <span className="position-relative fade-in delay cursor-pointer">
                <BsCalendarWeekFill className="text-primary-tint-50" />
                <Badge
                  pill
                  className="position-absolute top-0 start-100 translate-middle fs-60 bg-success"
                >
                  {value.length}
                </Badge>
              </span>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltipnodropDateSchedules`}>
                  No drop date schedules
                </Tooltip>
              }
            >
              <span className="fade-in delay cursor-pointer">
                <BsCalendarXFill className="opacity-25" />
              </span>
            </OverlayTrigger>
          );
        },
        cellClass: "text-center",
        bringToFront: true,
      },
      alwaysOrderable: {
        headingComponent: () => <IoInfinite className="opacity-75 fs-5" />,
        cellClass: "text-center",
        component: ({ value }) =>
          !!value ? (
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltipdropDateSchedules`}>
                  Always orderable
                </Tooltip>
              }
            >
              <span className="fade-in delay cursor-pointer">
                <IoInfinite className="text-purple-tint-25 fs-5" />
              </span>
            </OverlayTrigger>
          ) : null,
        bringToFront: true,
      },
      programStatus: {
        component: ({ value }) => <StatusBadge value={value} />,
        displayHeading: "Status",
        cellClass: "text-center",
      },
      title: {
        cellWidth: 100,
      },
      marketingName: {
        displayHeading: "Marketing name",
        cellWidth: 100,
      },
      tooltip: {
        cellWidth: 100,
      },
      description: {},
    };

    return tableItems;
  };

  return (
    <ListPage
      baseUrl={`${config.api.phoenix}/programs`}
      urlParams={`${programTypeParam}`}
      itemLabel="program"
      itemPath={`programs/${programType || "campaign"}s`}
      orderOptions={["id", "sequence", "slider"]}
      gridItem={GridItem}
      tableItems={getTableItems(programType)}
      headerItems={<ProgramTypeSelect />}
      thumbIcon={programType === "campaign" ? BsMegaphoneFill : CycleIcon}
      group="theme"
    />
  );
};

export default Programs;
