import { Button, Card, FloatingLabel, Form, ListGroup } from "react-bootstrap";
import LabeledItem from "../../ui/LabeledItem";
import { BsCheck2, BsPencil, BsX } from "react-icons/bs";

const TreatmentGeneral = ({
  treatment,
  editable,
  setEditable,
  formProps,
  errors,
  isSubmitted,
  isDirty,
  isBusy,
  onCancel,
  postalClassEnum,
  postalGroupEnum,
}) => {
  return (
    <Card className="w-100 mb-3 bg-opacity-10">
      <Card.Body>
        <ListGroup variant="flush">
          <div className={`${editable && "gap-2"} d-flex w-100`}>
            <div className="w-75">
              {editable ? (
                <FloatingLabel controlId="name" label="Name" className="mb-3">
                  <Form.Control
                    {...formProps?.register("name")}
                    isInvalid={errors?.name}
                    isValid={isSubmitted && errors?.name}
                  />
                </FloatingLabel>
              ) : (
                <LabeledItem
                  name={treatment?.name}
                  editable={editable}
                  label="Name"
                />
              )}
            </div>
            <div className="w-25">
              {editable ? (
                <FloatingLabel controlId="code" label="Code" className="mb-3">
                  <Form.Control
                    {...formProps?.register("code")}
                    isInvalid={errors?.code}
                    isValid={isSubmitted && errors?.code}
                  />
                </FloatingLabel>
              ) : (
                <LabeledItem
                  name={treatment?.code}
                  editable={editable}
                  label="Code"
                />
              )}
            </div>
          </div>
          <div className={`${editable && "gap-2"} d-flex w-100`}>
            <div className="w-75">
              {editable ? (
                <FloatingLabel controlId="type" label="Type" className="mb-3">
                  <Form.Control
                    {...formProps?.register("type")}
                    isInvalid={errors?.type}
                    isValid={isSubmitted && errors?.type}
                  />
                </FloatingLabel>
              ) : (
                <LabeledItem
                  name={treatment?.type}
                  editable={editable}
                  label="Type"
                />
              )}
            </div>
            <div className="w-25">
              {editable ? (
                <FloatingLabel
                  controlId="languageCode"
                  label="Language code"
                  className="mb-3"
                >
                  <Form.Control
                    {...formProps?.register("languageCode")}
                    isInvalid={errors?.languageCode}
                    isValid={isSubmitted && errors?.languageCode}
                  />
                </FloatingLabel>
              ) : (
                <LabeledItem
                  name={treatment?.languageCode}
                  editable={editable}
                  label="Language code"
                />
              )}
            </div>
          </div>
          <div className={`${editable && "gap-2"} d-flex w-100`}>
            <div className="w-75">
              {editable ? (
                <FloatingLabel
                  controlId="pruningPolicyType"
                  label="Pruning policy"
                  className="mb-3"
                >
                  <Form.Select
                    {...formProps?.register("pruningPolicyType")}
                    isInvalid={errors?.pruningPolicyType}
                    isValid={isSubmitted && errors?.pruningPolicyType}
                  >
                    <option value="pruneIfNeeded">Prune if needed</option>
                    <option value="pruneFirst">Prune first</option>
                    <option value="pruneLast">Prune last</option>
                    <option value="doNoPrune">Do not prune</option>
                  </Form.Select>
                </FloatingLabel>
              ) : (
                <LabeledItem
                  name={treatment?.pruningPolicy}
                  editable={editable}
                  label="Pruning policy"
                />
              )}
            </div>
            <div className="w-25">
              {editable ? (
                <FloatingLabel
                  controlId="validTerm"
                  label="Valid term"
                  className="mb-3"
                >
                  <Form.Control
                    {...formProps?.register("validTerm")}
                    isInvalid={errors?.validTerm}
                    isValid={isSubmitted && errors?.validTerm}
                  />
                </FloatingLabel>
              ) : (
                <LabeledItem
                  name={treatment?.validTerm}
                  editable={editable}
                  label="Valid term"
                />
              )}
            </div>
          </div>
          <div className={`${editable && "gap-2"} w-100`}>
            {editable ? (
              <FloatingLabel
                controlId="description"
                label="Description"
                className="mb-3"
              >
                <Form.Control
                  {...formProps?.register("description")}
                  isInvalid={errors?.description}
                  isValid={isSubmitted && errors?.description}
                />
              </FloatingLabel>
            ) : (
              <LabeledItem
                name={treatment?.description}
                editable={editable}
                label="Description"
              />
            )}
          </div>
          <div className={`${editable && "gap-2"} w-100`}>
            {editable ? (
              <FloatingLabel
                controlId="postalClass"
                label="Postal class"
                className="mb-3"
              >
                <Form.Select
                  {...formProps?.register("postalClass")}
                  isInvalid={errors?.postalClass}
                  isValid={isSubmitted && errors?.postalClass}
                >
                  {postalClassEnum?.map((postalClass) => (
                    <option key={postalClass.id} value={postalClass.name}>
                      {postalClass.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            ) : (
              !!treatment?.postalClass && (
                <LabeledItem
                  name={treatment?.postalClass}
                  editable={editable}
                  label="Postal class"
                />
              )
            )}
          </div>
          <div className={`${editable && "gap-2"} w-100`}>
            {editable ? (
              <FloatingLabel
                controlId="postalGroup"
                label="Postal group"
                className="mb-3"
              >
                <Form.Select
                  {...formProps?.register("postalGroup")}
                  isInvalid={errors?.postalGroup}
                  isValid={isSubmitted && errors?.postalGroup}
                >
                  {postalGroupEnum?.map((postalGroup) => (
                    <option key={postalGroup.id} value={postalGroup.name}>
                      {postalGroup.name}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            ) : (
              !!treatment?.postalGroup && (
                <LabeledItem
                  name={treatment?.postalGroup}
                  editable={editable}
                  label="Postal group"
                />
              )
            )}
          </div>
        </ListGroup>
      </Card.Body>
      <Card.Footer className="sticky-bottom d-flex justify-content-end align-items-center">
        {editable ? (
          <>
            <Button
              variant="secondary"
              disabled={isBusy}
              onClick={onCancel}
              className="me-2 min-w-8"
            >
              <BsX className="btn-icon me-1" />
              Cancel
            </Button>
            <Button
              variant="success"
              disabled={isBusy || !isDirty}
              type="submit"
              className="min-w-8"
            >
              <BsCheck2 className="btn-icon me-1" /> Save
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="success"
              disabled={isBusy}
              onClick={() => setEditable(true)}
              className="min-w-8"
            >
              <BsPencil className="btn-icon me-1" /> Edit
            </Button>
          </>
        )}
      </Card.Footer>
    </Card>
  );
};
export default TreatmentGeneral;
