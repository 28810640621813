import React from "react";
import { Badge, Button, ListGroup, ListGroupItem } from "react-bootstrap";
import { BsCake, BsGenderFemale, BsGenderMale, BsPencil } from "react-icons/bs";
import ContactDetailsListItem from "./ContactDetailsListItem";
import SpacedItem from "../../SpacedItem";
import { LiaUserTieSolid } from "react-icons/lia";
import formatDate from "../../../utils/formatDate";

const PersonsListItem = ({
  id,
  titlePrefix,
  titlePostfix,
  firstName,
  prefix,
  lastName,
  gender,
  initials,
  dateOfBirth,
  roles = [],
  contactDetails = [],
  className,
  multiline = false,
  to, // as Link
  onClick, // as Button
  ...rest
}) => {
  const linkProps = to ? { to } : {};
  const btnProps = onClick ? { onClick } : {};

  const GenderIcon = ({ ...props }) => {
    const Icon =
      gender === "male"
        ? BsGenderMale
        : gender === "female"
        ? BsGenderFemale
        : null;
    return Icon ? <Icon title={gender} {...props} /> : null;
  };

  const name = (
    <>
      <SpacedItem>{titlePrefix}</SpacedItem>
      <SpacedItem>{firstName}</SpacedItem>
      <SpacedItem>{prefix}</SpacedItem>
      <SpacedItem>{lastName}</SpacedItem>
      <SpacedItem>{titlePostfix}</SpacedItem>
    </>
  );

  return (
    <ListGroupItem
      action={to || onClick}
      className={`hover-parent py-3 d-flex justify-content-start align-items-center${
        className ? ` ${className}` : ""
      }`}
      {...linkProps}
      {...btnProps}
      {...rest}
    >
      <LiaUserTieSolid
        className={`h2 mt-1 mb-0 text-secondary-tint-25${
          multiline ? " ms-1 me-3 my-2 align-self-start" : " me-2 ms-n1"
        } align-self-start`}
      />

      <div className="mb-0 flex-grow-1">
        {name ? (
          <span className={`h3 me-2${multiline ? " d-block" : ""}`}>
            {name}
          </span>
        ) : null}

        {roles.map((role) => (
          <Badge
            bg="px-dark-tint-50"
            className="me-2 align-text-top"
            key={`person${id}role${role}`}
          >
            {role}
          </Badge>
        ))}

        {dateOfBirth || gender ? (
          <div className="mb-2">
            {dateOfBirth ? (
              <span className="me-2">
                <BsCake className="me-2 text-secondary" title="Date of birth" />
                <span className="small text-secondary">
                  <span>{formatDate(dateOfBirth)}</span>
                </span>
              </span>
            ) : null}
            <GenderIcon className="me-2 text-secondary" />
          </div>
        ) : null}

        <div className="">
          {/* <h5 className="mb-2 mt-3 fw-normal">
            Contact details
            <Badge
              pill
              bg={
                contactDetails?.length ? "success-tint-10" : "secondary-tint-50"
              }
              as="sup"
              className="ms-2 fs-45 align-baseline"
            >
              {contactDetails?.length}
            </Badge>
          </h5> */}

          <ListGroup
            variant="flush"
            style={{
              "--bs-list-group-item-padding-y": ".25rem",
              "--bs-list-group-border-width": 0,
            }}
          >
            {contactDetails.map(({ type, value, notes }, i) => (
              <ContactDetailsListItem
                key={`person${id}contact${type}${i}`}
                id={id}
                type={type}
                value={value}
                notes={notes}
                className="ps-1"
              />
            ))}
          </ListGroup>
        </div>
      </div>

      {to ? (
        <Button
          as="span"
          variant="link"
          className={`hover-opacity ${
            multiline ? " align-self-end me-n1" : " my-n2 me-n3"
          }`}
          title="Edit"
        >
          <BsPencil />
        </Button>
      ) : null}
    </ListGroupItem>
  );
};

export default PersonsListItem;
