import React from "react";
import { DropdownButton } from "react-bootstrap";
import { BsCopy, BsDownload } from "react-icons/bs";
import { HiChevronDown } from "react-icons/hi2";
import DropdownItemWithText from "./DropdownItemWithText";
import copyValue from "../../utils/copyValue";
import { useNotifications } from "../../context/NotificationsContext";

// For detail page top button toolbar wrap in Stack with gap 2
const copyJson = ({
  data,
  onSuccess = () => null,
  onError = (error) => console.error(error),
}) => {
  try {
    copyValue(JSON.stringify(data));
    onSuccess();
  } catch (error) {
    onError(error);
  }
};

export const ExportOption = ({
  exportData,
  format = "json",
  title,
  extraText,
  icon,
  successHeading,
  successCode,
  successText,
  fileName = exportData?.name ||
    (exportData?.id && `Item ${exportData?.id}`) ||
    "Export",
  onClick,
  disabled = !exportData,
  ...rest
}) => {
  const { setToasts } = useNotifications();

  const defaults = {
    json: {
      onClick: copyJson,
      title: "Copy data",
      extraText: "JSON object",
      icon: BsCopy,
      successHeading: "JSON object",
      successCode: "JSON",
      successText: "copied to clipboard",
    },
  };

  const optionalIcon =
    typeof icon === "boolean" && !icon
      ? null
      : icon || defaults?.[format]?.icon;

  const optionalSuccessHeading =
    typeof successHeading === "boolean" && !successHeading
      ? null
      : successHeading || defaults?.[format]?.successHeading;
  const optionalSuccessText =
    typeof successText === "boolean" && !successText
      ? null
      : successText || defaults?.[format]?.successText;

  const noExtraText = typeof extraText === "boolean" && !extraText;
  const optionalExtraText = noExtraText
    ? null
    : extraText || defaults?.[format]?.extraText;

  const handleClick = () =>
    onClick ||
    defaults?.[format]?.onClick({
      data: exportData,
      onSuccess: () => {
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            variant: "success",
            heading: optionalSuccessHeading,
            code: successCode || defaults?.[format]?.successCode,
            text: optionalSuccessText,
          },
        ]);
      },
      onError: (error) => console.error(error),
    });

  return (
    <DropdownItemWithText
      as="button"
      disabled={disabled}
      title={title || defaults?.[format]?.title}
      extraText={optionalExtraText}
      icon={optionalIcon}
      onClick={!disabled ? handleClick : () => null}
      {...rest}
    />
  );
};

const ExportDropdownBtn = ({
  disabled = false,
  variant = "outline-secondary",
  size = "sm",
  icon: Icon = BsDownload,
  text = "Export",
  children,
}) => {
  return (
    <DropdownButton
      title={
        <>
          <Icon className="me-1" /> {text}
          <HiChevronDown className="opacity-50 ms-1" />
        </>
      }
      size={size}
      variant={variant}
      disabled={disabled}
    >
      {children}
    </DropdownButton>
  );
};

export default ExportDropdownBtn;
