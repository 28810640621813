import { ListGroupItem, Stack } from "react-bootstrap";

const BundleListItem = ({ name, id }) => {
  return (
    <ListGroupItem action as="div">
      <Stack gap={2} direction="horizontal">
        <span
          className="text-muted font-monospace text-center"
          style={{ minWidth: "1.5em" }}
        >
          {id}
        </span>
        <span className="flex-grow-1">{name}</span>
      </Stack>
    </ListGroupItem>
  );
};

export default BundleListItem;
