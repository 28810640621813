import React from "react";
// import SideMenu from "./SideMenu";
import ToastHandler from "../custom/ToastHandler";
// import FeedbackForm from "../custom/FeedbackForm";
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import PageHelmet from "./PageHelmet";
import { Container } from "react-bootstrap";
import { Navigate, Outlet } from "react-router-dom";
// import LayoutContext from "../../context/LayoutContext";
import { useNotifications } from "../../context/NotificationsContext";
import ProductionsHeader from "../pages/Productions/ProductionsHeader";

const LayoutProductions = () => {
  //   const { showMenu } = useContext(LayoutContext);
  const { toasts, setToasts } = useNotifications();

  const { currentPage, activeModule } = useBreadcrumbs({
    // path,
    // pageTitle,
  });

  const { colorClass = "" } = activeModule;

  // TODO: move to parent layout route
  if (!sessionStorage?.loggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <div
      className={`layout layout-flex with-fixed-header no-side-menu`}
      style={colorClass ? { "--module-color": `var(--${colorClass})` } : {}}
    >
      <PageHelmet
        pageTitle={currentPage?.title || activeModule?.title}
        moduleTitle={activeModule?.title}
      />
      <ProductionsHeader />
      <Container fluid className="g-0 container-main">
        {/* {children} */}
        <Outlet />
      </Container>
      {/* <FeedbackForm /> */}
      <ToastHandler toasts={toasts} setToasts={setToasts} />
    </div>
  );
};

export default LayoutProductions;
