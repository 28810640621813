import React from "react";
import { Form, ListGroupItem, Stack } from "react-bootstrap";
import StatusBadgeProductions from "../StatusBadgeProductions";
import ProductionTypeIcon from "../../../icons/ProductionTypeIcon";
import CountBadgeProductions from "../CountBadgeProductions";

const ProductionsListItem = ({
  id,
  name,
  productionType,
  status,
  productionItemCount,
  validatedProductionItemCount,
  selectedProductionIds,
  handleSelectProductions,
  selectMode,
  handleSelectProduction,
  selectedProductionId,
}) => {
  return (
    <ListGroupItem
      action
      as="div"
      active={
        selectMode
          ? selectedProductionIds.includes(id)
          : selectedProductionId === id
      }
      onClick={
        selectMode
          ? () => handleSelectProductions(id)
          : () => handleSelectProduction(id)
      }
      style={{
        "--bs-list-group-active-bg": "rgb(72, 112, 189)",
        "--bs-list-group-active-border-color": "rgb(72, 112, 189)",
        cursor: "pointer",
      }}
    >
      <Stack gap={2} direction="horizontal">
        <Form.Check
          type="checkbox"
          checked={
            selectMode
              ? selectedProductionIds.includes(id)
              : selectedProductionId === id
          }
          readOnly
        />
        <span
          className="text-muted font-monospace text-center"
          style={{
            minWidth: "1.5em",
            color:
              selectedProductionIds.includes(id) || selectedProductionId === id
                ? "#FFFFFF"
                : "",
          }}
        >
          {id}
        </span>
        <ProductionTypeIcon
          style={
            selectedProductionIds.includes(id) || selectedProductionId === id
              ? { color: "#FFFFFF" }
              : {}
          }
          type={productionType}
          title={productionType}
          className="text-module ms-2"
        />
        <span className="flex-grow-1">{name}</span>
        <CountBadgeProductions
          total={productionItemCount}
          validated={validatedProductionItemCount}
          label="items validated"
        />
        <StatusBadgeProductions status={status} />
      </Stack>
    </ListGroupItem>
  );
};

export default ProductionsListItem;
