import { useEffect, useState } from "react";
import { useNotifications } from "../../../context/NotificationsContext";
import useStorage from "../../../context/StorageContext";
import { useNavigate, useParams } from "react-router-dom";
import createDetailedErrorMessage from "../../../utils/detailMessageError";
import fetcher from "../../../utils/fetcher";
import { config } from "../../../config";
import del from "../../../assets/delete-sign.svg";
import BtnWithDialog from "../../ui/BtnWithDialog";

const DeleteDocumentButton = ({ editable, name }) => {
  const { client } = useStorage();
  const { documentId, documentType } = useParams();
  const { setToasts } = useNotifications();
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const text = (
    <div>
      Delete document <strong> {name} </strong>
    </div>
  );

  const smallText = (
    <div>
      {"This action will remove the document and its parameter settings"}
    </div>
  );

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          heading: "Failed to delete document",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const dataUrl =
    documentType === "dm"
      ? `${config.api.dm}/documents/${documentId}`
      : documentType === "edm"
      ? `${config.api.edm}/documents/${documentId}`
      : "";

  const onDelete = () => {
    fetcher({
      url: dataUrl,
      method: "DELETE",
      clientId: client?.id,
    })
      .then(() => {
        setToasts((currToasts) => [
          ...currToasts,
          {
            id: Date.now(),
            type: "success-delete",
            variant: "success",
            delay: 7500,
            img: del,
            titleText: "Document deleted",
            text: (
              <div>
                {"Document "} <strong>{name}</strong>{" "}
                {
                  " is deleted. It will no longer appear in search results and can not be viewed or edited"
                }
              </div>
            ),
          },
        ]);
        navigate("/documents");
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <BtnWithDialog
      isDisabled={!editable}
      modalSize="md"
      title={""}
      text={text}
      smallText={smallText}
      onConfirm={onDelete}
    />
  );
};

export default DeleteDocumentButton;
