import parseLinkHeader from "../utils/parseLinkHeader";

const useTotals = ({ linkStr, page = 1, pageSize = 30, itemsCount = 0 }) => {
  const links = parseLinkHeader(linkStr);
  const lastPage = links?.last?.page || page;
  const isLast = !links?.last;
  const lastPageCount = isLast ? itemsCount : 0;

  const total = (lastPage - 1) * pageSize * lastPage + lastPageCount;
  const firstItem = (page - 1) * pageSize + 1;
  const lastItem = isLast ? total : page * pageSize;

  return {
    total,
    isExact: isLast,
    firstItem,
    lastItem,
  };
};

export default useTotals;
