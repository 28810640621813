import React from "react";

export const UserContext = React.createContext();
export const useUserContext = () => React.useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [userSettings, setUserSettings] = React.useState(null);

  const userStore = React.useMemo(() => {
    const isFavorite = ({ viewId }) => {
      return (
        userSettings &&
        !!userSettings?.favoriteViews.filter((v) => v.id === +viewId)?.length
      );
    };
    return {
      userSettings,
      setUserSettings,
      isFavorite,
    };
  }, [userSettings]);

  return (
    <UserContext.Provider value={userStore}>{children}</UserContext.Provider>
  );
};
