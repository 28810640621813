import React from "react";
import { menuItems } from "../../content/itemTypes";
import { BsQuestionCircle } from "react-icons/bs";

const MenuIcon = ({ id, ...rest }) => {
  const Icon =
    menuItems.filter(
      ({ id: menuItemId, path, colorClass }) =>
        menuItemId === id || path === id || colorClass === id
    )?.[0]?.icon || BsQuestionCircle;
  return <Icon id={id} {...rest} />;
};

export default MenuIcon;
