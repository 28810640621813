import { useParams } from "react-router-dom";
import useStorage from "../../../../context/StorageContext";
import { config } from "../../../../config";
import useFetch from "../../../../hooks/useFetch";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import createDetailedErrorMessage from "../../../../utils/detailMessageError";
import { useNotifications } from "../../../../context/NotificationsContext";
import fetcher from "../../../../utils/fetcher";
import uploadFile from "../../../../utils/fetcherUploadFile";

const TreatmentDetailWrap = ({ component: Component }) => {
  const { client } = useStorage();
  const { setToasts } = useNotifications();
  const { treatmentId } = useParams();

  const [editable, setEditable] = useState(false);
  const [error, setError] = useState(null);
  const [isBusy, setIsBusy] = useState(false);
  const [hover, setHover] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [zoomIn, setZoomIn] = useState(false);

  const dataUrl = `${config.api.phoenix}/treatments/${treatmentId}`;

  const {
    data: treatment,
    fetchError,
    isValidating,
    mutate: refreshTreatment,
  } = useFetch({
    url: dataUrl,
    method: "GET",
    clientId: client?.id,
  });

  const { data: postalGroupEnum, fetchError: postalGroupError } = useFetch({
    url: `${config.api.phoenix}/enumerations/postalgroups`,
    method: "GET",
    clientId: client?.id,
  });

  const { data: postalClassEnum, fetchError: postalClassError } = useFetch({
    url: `${config.api.phoenix}/enumerations/infopostclasses`,
    method: "GET",
    clientId: client?.id,
  });

  const defaultValues = {
    id: treatment?.id,
    active: treatment?.active,
    name: treatment?.name,
    code: treatment?.code,
    description: treatment?.description,
    thumbnailFileId: treatment?.thumbnailFileId,
    validTerm: treatment?.validTerm,
    postalClass: treatment?.postalClass,
    postalGroup: treatment?.postalGroup,
    type: treatment?.type,
    languageCode: treatment?.languageCode,
    pruningPolicyType: treatment?.pruningPolicy,
    documents: treatment?.documents,
    mailpackCode: treatment?.mailpackCode,
    mailpackNumber: treatment?.mailpackNumber,
    parameters: treatment?.parameters,
  };

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, isDirty },
    reset,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (!!treatment) {
      reset({
        id: treatment?.id,
        active: treatment?.active,
        name: treatment?.name,
        code: treatment?.code,
        description: treatment?.description,
        thumbnailFileId: treatment?.thumbnailFileId,
        validTerm: treatment?.validTerm,
        postalClass: treatment?.postalClass,
        postalGroup: treatment?.postalGroup,
        type: treatment?.type,
        languageCode: treatment?.languageCode,
        pruningPolicyType: treatment?.pruningPolicy,
        documents: treatment?.documents,
        mailpackCode: treatment?.mailpackCode,
        mailpackNumber: treatment?.mailpackNumber,
        parameters: treatment?.parameters,
      });
    }
  }, [treatment, reset]);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      setToasts((currToasts) => [
        ...currToasts,
        {
          id: Date.now(),
          variant: "danger",
          text: createDetailedErrorMessage(error),
        },
      ]);
    }
  }, [error, setToasts]);

  const onCancel = () => {
    setEditable(false);
    reset(defaultValues);
  };

  const onSubmit = (data) => {
    setEditable(false);
    setIsBusy(true);

    fetcher({
      url: dataUrl,
      method: "PUT",
      payload: {
        name: data.name,
        code: data.code,
        description: data.description,
        mailPackNumber: data.mailPackNumber,
        mailpackCode: data.mailpackCode,
        pruningPolicyType: data.pruningPolicyType,
        postalClass: data.postalClass,
        postalGroup: data.postalGroup,
        validterm: data.validterm,
        languageCode: data.languageCode,
        active: data.active,
        thumbnailFileId: data.thumbnailFileId,
        type: data.type,
        documentReferences: data.documents,
        parameters: (data.parameters || []).map((p) => ({
          name: p.name ?? "",
          valueType: p.valueType ?? "string",
          sequence: p.sequence ?? 0,
          displayName: p.displayName ?? "",
          description: p.description ?? "",
          editableByCustomer: p.editableByCustomer ?? false,
          inheritance: typeof p.inheritance === "string" ? p.inheritance : "",
          active: p.active ?? true,
          valueOptions: Array.isArray(p.valueOptions) ? p.valueOptions : [],
        })),
      },
      clientId: client?.id,
    })
      .then(() => {
        refreshTreatment();
        reset(data);
        setIsBusy(false);
      })
      .catch((error) => {
        setError(error);
        reset(defaultValues);
        setIsBusy(false);
      });
  };

  const handleOnFileUpload = (file) => {
    const format = file.name.split(".").pop();
    const allowedFormats = ["png", "jpg", "jpeg", "psd", "eps"];
    const fileName = file.name;
    const fileCategory = "CustomerUpload";
    const url = `${config.api.files}/Upload`;
    if (!allowedFormats.includes(format)) {
      setError(
        "Unsupported file format. Allowed formats are: PNG, JPG, JPEG, PSD, EPS."
      );
      return;
    }
    uploadFile({
      url: url,
      format: format,
      fileName: fileName,
      fileCategory: fileCategory,
      file: file,
      clientId: client?.id,
      onSuccess: (data) => {
        onSubmit({
          id: treatment.id,
          active: treatment.active,
          name: treatment.name,
          code: treatment.code,
          thumbnailFileId: data.fileId,
        });
        refreshTreatment();
      },
      onError: (err) => {
        removeFile();
        setError("Failed to upload image");
      },
    });
  };

  const removeFile = () => {
    onSubmit({
      active: treatment.active,
      name: treatment.name,
      code: treatment.code,
      thumbnailFileId: "",
    });
  };

  const removeImage = () => {
    removeFile();
    refreshTreatment();
  };

  const formProps = {
    register,
    watch,
    reset,
    setValue,
  };

  return (
    <Component
      editable={editable}
      setEditable={setEditable}
      treatment={treatment}
      fetchError={fetchError}
      isValidating={isValidating}
      refreshTreatment={refreshTreatment}
      hover={hover}
      setHover={setHover}
      defaultValues={defaultValues}
      isBusy={isBusy}
      formProps={formProps}
      errors={errors}
      isSubmitted={isSubmitted}
      onCancel={onCancel}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      handleOnFileUpload={handleOnFileUpload}
      removeImage={removeImage}
      isDirty={isDirty}
      showRemoveModal={showRemoveModal}
      setShowRemoveModal={setShowRemoveModal}
      postalGroupEnum={postalGroupEnum}
      postalClassEnum={postalClassEnum}
      postalGroupError={postalGroupError}
      postalClassError={postalClassError}
      zoomIn={zoomIn}
      setZoomIn={setZoomIn}
    />
  );
};

export default TreatmentDetailWrap;
