import React from "react";
import { Card, Col } from "react-bootstrap";

// wraps a Col around Component
// default: Card

const ColCard = ({ col = {}, component: Component = Card, ...rest }) => {
  return (
    <Col {...col}>
      <Component {...rest} />
    </Col>
  );
};

export default ColCard;
