import React from "react";
import "./scss/style.scss";
import routes from "./routes";
import { HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./context/AuthContext";
import { ThemeProvider } from "react-bootstrap";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LayoutProvider } from "./context/LayoutContext";
import { StorageProvider } from "./context/StorageContext";
import { NotificationsProvider } from "./context/NotificationsContext";
import { UserProvider } from "./context/UserContext";

const router = createBrowserRouter(routes);

const App = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <UserProvider>
          <StorageProvider>
            <NotificationsProvider>
              <LayoutProvider>
                <ThemeProvider
                  breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs"]}
                >
                  <RouterProvider router={router} />
                </ThemeProvider>
              </LayoutProvider>
            </NotificationsProvider>
          </StorageProvider>
        </UserProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
