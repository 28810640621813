import React, { useState } from "react";
import { Badge, Button, ListGroup, Offcanvas, Stack } from "react-bootstrap";
import { FaStore } from "react-icons/fa6";
import SearchBox from "../ui/SearchBox";
import PaginationButtons from "../ui/PaginationButtons";
import WithLoaderAndError from "../ui/WithLoaderAndError";
import useFetch from "../../hooks/useFetch";
import { config } from "../../config";
import ImgBox from "../ui/ImgBox";
import useStorage from "../../context/StorageContext";

const SelectCustomerDrawer = ({
  show,
  setShow,
  selectedCustomerId,
  placement = "end",
  onSelect = () => [],
  afterSelect,
  closeButton = false,
  multiSelect = false,
  selectedCustomers,
  setSelectedCustomers = () => [],
}) => {
  const PAGE_SIZE = 30;
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const { client } = useStorage();

  const {
    data: customers,
    isValidating,
    error: customersError,
  } = useFetch({
    url: `${config.api.phoenix}/customers?pageSize=${PAGE_SIZE}&page=${page}&query=${filter}`,
  });

  // set customer
  const handleCustomerClick = (c) => {
    if (c.id !== selectedCustomerId) {
      onSelect((prevClient) => ({
        ...prevClient,
        customer: c,
      }));
      setShow(false);
      if (afterSelect && c !== null) {
        afterSelect(c);
      }
    }
  };

  const handleMultiSelect = (customer) => {
    setSelectedCustomers((prev) =>
      prev.some((c) => c.id === customer.id)
        ? prev.filter((c) => c.id !== customer.id)
        : [...prev, customer]
    );
  };
  const handleApply = () => {
    onSelect((prevClient) => ({
      ...prevClient,
      customer: selectedCustomers[0],
      customers: selectedCustomers,
    }));
    setShow(false);
  };

  return (
    <Offcanvas
      style={{ zIndex: 2000 }}
      show={show}
      onHide={() => {
        (selectedCustomerId != null ||
          closeButton ||
          selectedCustomers?.length > 0) &&
          setShow(false);
        multiSelect && !!client?.customers && client?.customers.length > 0
          ? setSelectedCustomers(client.customers.map((c) => c))
          : setSelectedCustomers([]);
      }}
      placement={placement}
      data-bs-theme="dark"
    >
      <Offcanvas.Header
        className="bg-black bg-opacity-25 header-inner-height p-2"
        closeVariant="white"
        {...(selectedCustomerId != null ||
        closeButton ||
        selectedCustomers?.length > 0
          ? { closeButton: true }
          : {})}
      >
        <div className="w-100 d-flex">
          <SearchBox
            className="w-100"
            maxWidth={"350px"}
            query={filter}
            setQuery={setFilter}
            placeholder={"Search by customer name, code or id"}
          />
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0" style={{ scrollbarWidth: "thin" }}>
        <WithLoaderAndError
          isLoading={!customers && !customersError}
          error={customersError}
          stacked
        >
          {customers && !!customers.length ? (
            <ListGroup
              variant="flush"
              className="w-100 align-self-start"
              style={{
                "--bs-list-group-active-bg": "hsla(45, 100.00%, 40.00%, 0.85)",
                "--bs-list-group-active-border-color":
                  "hsla(45, 100.00%, 40.00%, 0.85)",
                cursor: "pointer",
              }}
            >
              {customers.map((c) => {
                const isSelected = multiSelect
                  ? selectedCustomers?.some((cust) => cust.id === c.id)
                  : selectedCustomerId === c.id;

                return (
                  <ListGroup.Item
                    key={c.id}
                    action
                    onClick={
                      multiSelect
                        ? () => handleMultiSelect(c)
                        : () => handleCustomerClick(c)
                    }
                    active={isSelected}
                    as={Stack}
                    direction="horizontal"
                    className="p-0 "
                    style={{ height: "48px" }}
                  >
                    <div
                      style={{
                        width: "48px",
                        height: "100%",
                      }}
                      className="border-end me-2 overflow-hidden d-flex align-items-center"
                    >
                      <ImgBox
                        className="d-flex align-items-center"
                        imgId={c?.logoFileId}
                        width={48}
                        icon={FaStore}
                        imgBg="secondary"
                        resizeMode="contain"
                        style={{ with: 48, height: "100%" }}
                      />
                    </div>
                    <div
                      className="d-flex justify-content-between w-100"
                      style={{ paddingLeft: 3, paddingRight: 3 }}
                    >
                      <div className="lh-sm">
                        <div className="fw-semibold">{c.name}</div>
                        <small className="text-muted font-monospace">
                          <Badge
                            style={{
                              backgroundColor: isSelected
                                ? "rgba(75, 98, 107, 0.85)"
                                : "hsla(45, 100.00%, 40.00%, 0.85)",
                              color: "white",
                            }}
                            className={`${
                              isSelected ? "" : "hover-opacity-0"
                            }text-light text-opacity-75 font-monospace me-2 ms-n1`}
                          >
                            {c.id}
                          </Badge>
                        </small>
                      </div>
                      <span
                        className={`${
                          isSelected ? "" : "hover-opacity-0"
                        }font-monospace small opacity-75 d-flex align-items-center`}
                      >
                        {c.code}
                      </span>
                    </div>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          ) : (
            <div className="text-muted p-3">No customers found</div>
          )}
        </WithLoaderAndError>
      </Offcanvas.Body>
      <Offcanvas.Header className="bottom-0 p-2 border-top">
        {customers?.length ? (
          <PaginationButtons
            page={page}
            setPage={setPage}
            pageSize={PAGE_SIZE}
            isLoading={isValidating}
          />
        ) : null}
        {multiSelect && (
          <Button variant="secondary" onClick={handleApply}>
            Apply
          </Button>
        )}
      </Offcanvas.Header>
    </Offcanvas>
  );
};

export default SelectCustomerDrawer;
